import { object, string, date, array, boolean } from 'yup';
import { AsigneeListContact } from 'components/Requests/components/SendRequest/components/AssigneeList/AssigneeList';

const validationSchema = object({
  dueDate: date().required('Please enter a Due Date'),
  contacts: array().min(1, 'Please select one or more Contacts as Assignees'),
  requestAccountId: string().when('isBulk', {
    is: false,
    then: (schema) =>
      schema.required('Please select an Account for your Assignee(s)'),
    otherwise: (schema) => schema,
  }),
  isRequestCompleted: boolean()
    .oneOf([true], 'Please select a Signer for all signable items')
    .required('Please select a Signer for all signable items'),
  isBulk: boolean(),
});

export const validateRequest = (values: {
  dueDate: Date | null;
  contacts: AsigneeListContact[] | null;
  requestAccountId?: string;
  isRequestCompleted?: boolean;
  isBulk?: boolean;
}) => {
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return [];
  } catch (err: any) {
    return err.errors;
  }
};
