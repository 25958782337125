import { Upload } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { DropBoxType } from 'components/Requests/requests.types';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

const PDF_FILE_TYPE = 'application/pdf';

type Props = {
  rootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  inputProps: DropzoneInputProps;
  dropBoxType: DropBoxType;
  setRpaType: (type: DropBoxType) => void;
};

export const DropBox = ({
  rootProps,
  inputProps,
  dropBoxType,
  setRpaType,
}: Props) => {
  const theme = useTheme();

  const borders = {
    original: `3px dotted ${theme.palette.grey[100]}`,
    organizer: `3px solid ${theme.palette.info.dark}`,
    tax_delivery: '3px solid #ed85b4',
  };

  const backGroundColor = {
    original: 'inherit',
    organizer: theme.palette.info.light,
    tax_delivery: '#f8cce0',
  };

  return (
    <Box
      {...rootProps({
        onClick: () =>
          setRpaType(dropBoxType === 'original' ? 'organizer' : dropBoxType),
        onDrop: () =>
          setRpaType(dropBoxType === 'original' ? 'organizer' : dropBoxType),
      })}
      sx={{
        border: borders[dropBoxType],
        background: backGroundColor[dropBoxType],
        borderRadius: '3px',
        height: dropBoxType === 'original' ? '200px' : 'inherit',
        minHeight: dropBoxType === 'original' ? 'inherit' : '42vh',
        margin: '20px 20px 0px 20px',
        cursor: 'pointer',
        padding: dropBoxType === 'original' ? 'inherit' : '1rem',
        width: dropBoxType === 'original' ? 'inherit' : 'auto',
      }}
    >
      <input
        {...inputProps}
        accept={PDF_FILE_TYPE}
        data-testid="rpa-file-upload-input"
      />
      {dropBoxType !== 'original' && (
        <Typography
          align="center"
          variant="h1"
          padding="0px"
          sx={{
            width: '100%',
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          {dropBoxType === 'organizer'
            ? 'Smart Tax Organizers'
            : 'Smart Tax Delivery'}
        </Typography>
      )}
      <Typography
        align="center"
        variant="body1"
        color="GrayText"
        sx={{
          marginTop: '65px',
        }}
      >
        <Upload />
      </Typography>
      <Typography align="center" variant="body1">
        Drag & Drop PDF files here or browse to upload.
      </Typography>
      <Typography
        align="center"
        variant="body1"
        sx={{
          textAlign: 'center',
          marginTop: '10px',
          color: theme.palette.primary.main,
        }}
      >
        Browse
      </Typography>
    </Box>
  );
};
