import axios from 'axios';
import {
  clearAuthSession,
  destroyCurrentSessionAndGoToLogin,
  getAuthSession,
} from 'auth/session';
import { websocket } from 'websocket';
import { AlertMessage, systemData } from 'utilities/utils';
import { ACT_AS_KEY, LOGOUT } from 'constants/constants';
import { apiClientV5 } from 'api/apiClientV5';

// Copy the existing interceptor logic to the new axios instance
// This needs to be done here or it wont set until a page refresh
apiClientV5.interceptors.request.use((config) => {
  // Add auth session token to outgoing requests
  const session = getAuthSession();
  if (session?.auth_token && config?.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization =
      config?.headers?.Authorization || session.auth_token;
  }

  // Add act_as user param to outgoing requests
  const actingAsUserId = localStorage.getItem(ACT_AS_KEY);
  if (actingAsUserId) {
    // eslint-disable-next-line no-param-reassign
    config.params = {
      ...(config.params || {}),
      act_as: actingAsUserId,
    };
  }

  return config;
});

axios.interceptors.request.use((config) => {
  // Add auth session token to outgoing requests
  const session = getAuthSession();
  if (session?.auth_token && config?.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization =
      config?.headers?.Authorization || session.auth_token;
  }

  // Add act_as user param to outgoing requests
  const actingAsUserId = localStorage.getItem(ACT_AS_KEY);
  if (actingAsUserId) {
    // eslint-disable-next-line no-param-reassign
    config.params = {
      ...(config.params || {}),
      act_as: actingAsUserId,
    };
  }

  return config;
});

type SysData = { manufacturer?: string; model?: string };
// Hooray for global variables... :thisisfine:
let isLoggingOutUserDueToExpiredSession = false;
axios.interceptors.response.use(
  (response) => {
    if (
      response?.data?.status === 601 &&
      !isLoggingOutUserDueToExpiredSession
    ) {
      AlertMessage('error', response.data.errors.unauthorized[0], 5000);
      if (
        response.data.errors.unauthorized[0] &&
        response.data.errors.unauthorized[0] ===
          'Password Changed Successfully. Please Login in.'
      ) {
        setTimeout(destroyCurrentSessionAndGoToLogin, 2000);
      } else {
        const sysData: SysData = systemData() as SysData;
        const deviceMake = sysData?.manufacturer ? sysData?.manufacturer : '';
        const deviceModel = sysData?.model ? sysData?.model : '';
        const config = {
          headers: {
            DeviceMake: deviceMake,
            DeviceModel: deviceModel,
          },
        };
        // Set global flag to prevent looping session expired errors prior to getting logged out
        isLoggingOutUserDueToExpiredSession = true;
        const request = axios.post(LOGOUT, {}, config);
        request.then((res) => {
          if (res.data.status === 200 || res.data.status === 601) {
            clearAuthSession();

            websocket.consumer.disconnect();
            localStorage.clear();
            window.location.href = '/';
            window.location.reload();
          }
        });
      }
    }

    return response;
  },
  (err) => Promise.reject(err),
);
