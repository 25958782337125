import { Stack, Typography, Box } from '@mui/material';
import { MfaIcon } from 'components/Profile/MfaIcon/MfaIcon';
import MultiFactorAuthenticationSetupButtons from 'components/Profile/MultiFactorAuthenticationSetup/components/MultiFactorAuthenticationSetupButtons';
import { ReactComponent as ProtectImg } from './protect.svg';

type MFAIntroDescriptionProps = {
  headerText?: string;
  isRequired: boolean;
  noBtnText?: string;
  noBtnClick: () => void;
  yesBtnText: string;
  yesBtnClick: () => void;
};

const REQUIRED_DESCRIPTION =
  'Supported authentication apps include: Google Authenticator, Microsoft Authenticator, Authy, and others.';
const NOT_REQUIRED_DESCRIPTION =
  'Enabling multi-factor authentication is a critical step in improving your cybersecurity. Liscio supports Google Authenticator, Microsoft Authenticator, Authy, and others.';

export const MFAIntroDescription = ({
  headerText,
  isRequired,
  noBtnText,
  noBtnClick,
  yesBtnText,
  yesBtnClick,
}: MFAIntroDescriptionProps) => (
  <Stack gap={4}>
    <Typography variant="h4">{headerText}</Typography>
    <Box alignSelf="center">
      <ProtectImg style={{ width: 120, height: 120 }} />
    </Box>
    {isRequired && (
      <Typography>
        Your firm administrator has enabled{' '}
        <strong>multi-factor authentication</strong> to enhance security.
      </Typography>
    )}
    <p className="mb-1">
      {isRequired ? REQUIRED_DESCRIPTION : NOT_REQUIRED_DESCRIPTION}
    </p>
    <Stack width="100%" alignItems="center">
      <Stack direction="row" gap={3}>
        <MfaIcon type="google" />
        <MfaIcon type="microsoft" />
        <MfaIcon type="authy" />
      </Stack>
    </Stack>
    <MultiFactorAuthenticationSetupButtons
      noBtnText={noBtnText}
      noBtnClick={noBtnClick}
      yesBtnText={yesBtnText}
      yesBtnClick={yesBtnClick}
    />
  </Stack>
);

export default MFAIntroDescription;
