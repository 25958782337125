/* eslint-disable no-param-reassign */

type ActivityType = {
  id: number;
  key: string;
  value: string;
  label: string;
};

const activity_Types: ActivityType[] = [
  { id: 0, key: 'message', value: 'message', label: 'Message' },
  { id: 1, key: 'email', value: 'email', label: 'Email' },
  { id: 2, key: 'task', value: 'task', label: 'Task' },
  { id: 3, key: 'file', value: 'file', label: 'File' },
  { id: 4, key: 'invoice', value: 'invoice', label: 'Invoice (beta)' },
  { id: 5, key: 'note', value: 'note', label: 'Note' },
];

const activityTypesWithoutInvoice: ActivityType[] = [
  { id: 0, key: 'message', value: 'message', label: 'Message' },
  { id: 1, key: 'email', value: 'email', label: 'Email' },
  { id: 2, key: 'task', value: 'task', label: 'Task' },
  { id: 3, key: 'file', value: 'file', label: 'File' },
  { id: 4, key: 'note', value: 'note', label: 'Note' },
];

function reflow(arr: ActivityType[]) {
  return arr.map((item, idx) => {
    item.id = idx;
    return item;
  });
}

// used to get activity types based on feature flags
export const getActivityTypes = (
  smsReminderFeature: boolean,
  requestsPageFeature: boolean,
  hideBillingFeatures: boolean,
) => {
  const activityTypes: ActivityType[] = hideBillingFeatures ? [...activityTypesWithoutInvoice] : [...activity_Types];

  if (smsReminderFeature)
    activityTypes.unshift({
      id: 1,
      key: 'text_message',
      value: 'text_message',
      label: 'SMS Text (beta)',
    });

  if (requestsPageFeature)
    activityTypes.unshift({
      id: 0,
      key: 'workflow_request',
      value: 'workflow_request',
      label: 'Request',
    });

  return reflow(activityTypes);
};
