/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import Axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  SAVE_RELATIONSHIP,
  SEARCH_CONTACTS,
  CONTACT,
  ACCOUNT,
  SEARCH_ACCOUNTS,
} from 'constants/constants';
import {
  AlertMessage,
  getCpaPreferences,
  generateFullName,
} from 'utilities/utils';
import { SelectField, CheckboxField } from 'common/CustomFields';
import ShowSelectedRelativeInfo from './ShowSelectedRelativeInfo';

const filteraccounts = (existingrelationships, allcontacts) => {
  const output = [];
  allcontacts.map((e) => {
    let doesnotexits = true;
    if (existingrelationships && existingrelationships !== null) {
      doesnotexits = !existingrelationships.some(
        (each) => each.relation_data && each.relation_data.account_id === e.id,
      );
    }
    if (doesnotexits) {
      output.push({ label: e.name, value: e.id });
    }
    return doesnotexits;
  });
  return output;
};

const filtercontacts = (existingrelationships, allcontacts) => {
  const output = [];
  allcontacts.map((e) => {
    let doesnotexits = true;
    if (existingrelationships && existingrelationships !== null) {
      doesnotexits = !existingrelationships.some(
        (each) => each.id === e.contact.id,
      );
    }
    if (doesnotexits && e.contact) {
      output.push({ label: generateFullName(e.contact), value: e.contact.id });
    }
    return doesnotexits;
  });
  return output;
};

class EditRelationship extends Component {
  constructor(props) {
    super(props);

    const {
      account_id,
      relationshipWith,
      contact_id,
      relationship_type,
      relationship_id,
      is_account_owner,
      with: propsWith,
    } = this.props;
    this.state = {
      account_id: account_id || null,
      labelname: relationshipWith || null,
      contact_id: contact_id || null,
      options: [],
      relationship_type: relationship_type || null,
      relationship_id,
      is_account_owner: is_account_owner ? 1 : 0,
      errors: {},
      preferences: getCpaPreferences(),
      loading: relationship_id === undefined,
      relationshipWith:
        propsWith && propsWith.toLowerCase() === 'account'
          ? 'account'
          : 'contact',
      sortBy: 'first_name',
      sortOrder: 'asc',
      pagination: 1,
      getmoredata: false,
      inputvalue: '',
      disableSaveBtn: false,
      dropdownoptions: [],
      searchtext: '',
      cachedropdownresults: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { with: withaccount, existingrelationships } = this.props;
    const { pagination, sortBy, sortOrder, dropdownoptions, relationship_id } =
      this.state;
    let url = `${CONTACT}?page=${pagination}&field=${sortBy}&sort_by=${sortOrder}`;
    if (!relationship_id) {
      if (withaccount === 'account') {
        url = `${ACCOUNT}?page=${pagination}&field=name&sort_by=asc`;
      }
      Axios.get(url).then((res) => {
        if (
          res.status === 200 &&
          res.data.status &&
          res.data.status === 200 &&
          res.data.data
        ) {
          this.setState({
            dropdownoptions:
              withaccount === 'account'
                ? [
                    ...dropdownoptions,
                    ...filteraccounts(existingrelationships, res.data.data),
                  ]
                : [
                    ...dropdownoptions,
                    ...filtercontacts(existingrelationships, res.data.data),
                  ],
            cachedropdownresults:
              withaccount === 'account'
                ? [
                    ...dropdownoptions,
                    ...filteraccounts(existingrelationships, res.data.data),
                  ]
                : [
                    ...dropdownoptions,
                    ...filtercontacts(existingrelationships, res.data.data),
                  ],
            loading: false,
          });
        }
      });
    }
  };

  getMoreData = () => {
    const { searchtext, pagination } = this.state;
    if (searchtext.length === 0) {
      this.setState(
        {
          pagination: pagination + 1,
          loading: true,
        },
        () => this.getData(),
      );
    }
  };

  handleSelect = (input = {}) => {
    const { value, label } =
      input !== null ? input : { value: null, label: null };
    if (this.props.with && this.props.with.toLowerCase() === 'account') {
      this.setState({
        labelname: label,
        account_id: value,
      });
    } else {
      this.setState({
        labelname: label,
        contact_id: value,
      });
    }
  };

  handleRelationshipChange = (input = {}) => {
    const { value } = input !== null ? input : { value: null, label: null };
    this.setState({ relationship_type: value });
  };

  changeOwnerShip = () => {
    this.setState({ is_account_owner: !this.state.is_account_owner });
  };

  createRelationShip = () => {
    const errors = {};
    const {
      account_id: accountId,
      contact_id: contactId,
      relationship_type: relationshipType,
    } = this.state;
    if (relationshipType === null) {
      errors.relationshipType = 'Select relationship';
    }
    if (contactId === null || accountId === null) {
      errors.relationShipWith =
        this.props.with && this.props.with.toLowerCase() === 'account'
          ? 'Select Account'
          : 'Select Contact';
    }
    this.setState({ errors }, () =>
      !Object.keys(errors).length ? this.sendDatatoBackend() : null,
    );
  };

  sendDatatoBackend = () => {
    const {
      account_id,
      contact_id,
      relationship_type,
      is_account_owner,
      relationship_id,
    } = this.state;
    const relationshipData = {
      account_id: Number(account_id),
      contact_id,
      relationship_type,
      is_account_owner,
    };
    this.setState({ disableSaveBtn: true });
    let response = null;
    if (relationship_id) {
      const formdata = {};
      Object.keys(relationshipData).forEach((key) => {
        if (relationshipData[key] !== this.props[key]) {
          formdata[key] = relationshipData[key];
        }
      });
      response = Axios.put(`${SAVE_RELATIONSHIP}/${relationship_id}`, formdata);
    } else {
      response = Axios.post(SAVE_RELATIONSHIP, relationshipData);
    }
    response
      .then((res) => {
        if (res.data && res.data.status && res.data.status === 200) {
          this.props.handleCloseModal();
          AlertMessage('success', res.data.message, 3000);
        } else if (res.data && res.data.status && res.data.status === 401) {
          AlertMessage('error', 'Relationship already exits', 3000);
          this.setState({ disableSaveBtn: false });
        } else {
          AlertMessage('error', 'Something went wrong', 3000);
          this.setState({ disableSaveBtn: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSearch = (input) => {
    const { with: withaccount, existingrelationships } = this.props;
    const { cachedropdownresults, searchtext } = this.state;
    this.setState({ searchtext: input });
    if (input && input.length > 2) {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        this.setState({ loading: true });
        Axios.get(
          `${
            withaccount === 'account' ? SEARCH_ACCOUNTS : SEARCH_CONTACTS
          }/${input.toLowerCase()}`,
        ).then((res) => {
          if (
            res.status === 200 &&
            res.data.status &&
            res.data.status === 200 &&
            res.data.data &&
            input === this.state.searchtext
          ) {
            this.setState({
              dropdownoptions:
                withaccount === 'account'
                  ? filteraccounts(existingrelationships, res.data.data)
                  : filtercontacts(existingrelationships, res.data.data),
              loading: false,
            });
          } else {
            this.setState({
              dropdownoptions:
                this.state.searchtext.length === 0 ? cachedropdownresults : [],
              loading: false,
            });
          }
        });
      }, 500);
    } else if (
      searchtext &&
      searchtext.length === 3 &&
      input &&
      input.length < 3
    ) {
      this.setState({
        dropdownoptions: cachedropdownresults,
        loading: false,
      });
    }
  };

  render() {
    const {
      preferences,
      relationshipWith,
      disableSaveBtn,
      labelname,
      dropdownoptions,
      loading,
      relationship_id,
      account_id,
      contact_id,
    } = this.state;
    const noResultsText = loading
      ? relationshipWith === 'account'
        ? 'Getting Accounts'
        : 'Getting Contacts'
      : '';
    return (
      <Modal
        isOpen
        className="customModal customModal--w990 customModal--nopadding EditRelationshipModal"
        toggle={this.handleCloseModal}
      >
        <ModalHeader className="ModalHeader">
          <button
            type="button"
            onClick={this.props.handleCloseModal}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </ModalHeader>
        <ModalBody className="EditRelationshipModal__Body">
          <div className="modalContent--wrap">
            <div className="modalContent--inner">
              <div className="row justify-content-between no-gutters">
                {this.state.relationship_id ? (
                  <div className="col section-title">
                    <h4>Update existing Relationship</h4>
                    <p />
                  </div>
                ) : (
                  <div className="col section-title">
                    <h4>
                      Create with Existing{' '}
                      {relationshipWith === 'account' ? ' Account' : ' Contact'}
                    </h4>
                    <p>
                      Select an {this.props.with} and relationship for this{' '}
                      {this.props.with === 'account' ? 'contact' : 'account'}
                    </p>
                  </div>
                )}
              </div>
              <div className="row">
                <SelectField
                  name={relationshipWith === 'account' ? 'Account' : 'Contact'}
                  label={relationshipWith === 'account' ? 'Account' : 'Contact'}
                  options={dropdownoptions}
                  handleChange={this.handleSelect}
                  handleInputChange={this.handleSearch}
                  value={
                    labelname && labelname !== null
                      ? {
                          value:
                            this.props.with &&
                            this.props.with.toLowerCase() === 'account'
                              ? account_id
                              : contact_id,
                          label: labelname,
                        }
                      : { value: '', label: '' }
                  }
                  className="col-4 form-group"
                  error={this.state.errors.relationShipWith}
                  disabled={relationship_id}
                  isLoading={loading}
                  noResultsText={noResultsText}
                  onMenuScrollToBottom={this.getMoreData}
                  clearable
                />
                <SelectField
                  name="relationship_type"
                  label="Relationship"
                  options={preferences.contact_type}
                  handleChange={this.handleRelationshipChange}
                  value={{
                    value: this.state.relationship_type,
                    label: this.state.relationship_type,
                  }}
                  className="col-4 form-group"
                  error={this.state.errors.relationshipType}
                />
                <div className="mt-4 pt-2">
                  <CheckboxField
                    name="is_account_owner"
                    id="is_account_owner"
                    type="checkbox"
                    label="Account owner?"
                    value={this.state.is_account_owner}
                    onChange={this.changeOwnerShip}
                  />
                </div>
              </div>
              <div className="row no-gutters justify-content-between">
                <div className="col-8">
                  <ShowSelectedRelativeInfo
                    show={this.props.with}
                    id={
                      this.props.with === 'account'
                        ? this.state.account_id
                        : this.state.contact_id
                    }
                  />
                </div>
                <div className="align-self-end">
                  <button
                    type="button"
                    className="btn btn-outline-light mr-3"
                    onClick={this.props.handleCloseModal}
                  >
                    Close
                  </button>
                  {this.state.relationship_id ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.createRelationShip}
                      disabled={disableSaveBtn}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.createRelationShip}
                      disabled={disableSaveBtn}
                    >
                      {relationshipWith === 'account'
                        ? 'Add Account'
                        : 'Add Contact'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default EditRelationship;
