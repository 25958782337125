/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import HeaderTabs from 'common/HeaderTabs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { OrganizerTemplates } from 'components/OrganizerTemplates/OrganizerTemplates';
import TemplatesList from './TemplatesList';

const TemplatesTabs = [
  {
    index: 0,
    label: 'Message Templates',
    value: 'message_template',
  },
  {
    index: 2,
    label: 'Task Templates',
    value: 'task_template',
  },
  {
    index: 3,
    label: 'eDoc Templates',
    value: 'edoc_template',
  }
];

const TemplateTabsOrganizer = [
  {
    index: 0,
    label: 'Message Templates',
    value: 'message_template',
  },
  {
    index: 1,
    label: 'Organizer Templates',
    value: 'organizer_template',
  },
  {
    index: 2,
    label: 'Task Templates',
    value: 'task_template',
  },
  {
    index: 3,
    label: 'eDoc Templates',
    value: 'edoc_template',
  }
];

class Templates extends Component {
  templateTabs = TemplatesTabs;

  constructor(props) {
    super(props);
    const parsedUrl = new URL(window.location.href);
    const tab = parsedUrl.searchParams.get('tab');
    let tabIndex = 0;
    if (this.props.flags.enableOrganizerTemplates) {
      this.templateTabs = TemplateTabsOrganizer;
    }
    if (tab && tab !== 'create_message_template') {
      tabIndex = this.templateTabs.findIndex((each) => each.value === tab);
    }
    this.state = {
      activeTabIndex: tabIndex === undefined ? 0 : tabIndex,
      activatedTabs: { [this.templateTabs[tabIndex].value]: true },
    };
  }

  componentDidMount() {
    const { activeTabIndex, createTemplate } = this.state;
    const { navigate } = this.props;

    if (activeTabIndex && this.templateTabs[activeTabIndex]) {
      navigate(`/templates?tab=${this.templateTabs[activeTabIndex].value}`);
    } else if (activeTabIndex && createTemplate) {
      navigate('/templates?tab=create_message_template');
    }
    document.title = this.templateTabs[activeTabIndex].label;
  }

  changeActiveTab = (key) => {
    this.setState({
      activeTabIndex: key,
      activatedTabs: {
        ...this.state.activatedTabs,
        [this.templateTabs[key].value]: true,
      },
      createTemplate: false,
    });
    const { navigate } = this.props;
    document.title = this.templateTabs[key].label;
    navigate(`/templates?tab=${this.templateTabs[key].value}`);
  };

  render() {
    const { activeTabIndex, activatedTabs } = this.state;
    const activeKey = this.templateTabs[activeTabIndex].value;
    const tabs = this.templateTabs;
    return (
      <div id="content">
        <header className="pb-0 pt-0">
          <HeaderTabs
            data={tabs}
            activeTab={activeTabIndex}
            handleClick={this.changeActiveTab}
          />
        </header>
        <div>
          {activatedTabs.message_template ? (
            <div className={activeKey === 'message_template' ? '' : 'hidden'}>
              <TemplatesList type={activeKey} />
            </div>
          ) : null}
          {activatedTabs.task_template ? (
            <div className={activeKey === 'task_template' ? '' : 'hidden'}>
              <TemplatesList type={activeKey} />
            </div>
          ) : null}
          {activatedTabs.edoc_template ? (
            <div className={activeKey === 'edoc_template' ? '' : 'hidden'}>
              <TemplatesList type={activeKey} />
            </div>
          ) : null}

          {activatedTabs.invoice_template ? (
            <div className={activeKey === 'invoice_template' ? '' : 'hidden'}>
              <TemplatesList type={activeKey} />
            </div>
          ) : null}
          {activatedTabs.organizer_template ? (
            <div className={activeKey === 'organizer_template' ? '' : 'hidden'}>
              <OrganizerTemplates />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(Templates);
