export const contactPageTabsLegacy = [
  {
    index: 0,
    value: 'details',
    label: 'Details',
  },
  {
    index: 1,
    value: 'relationships',
    label: 'Accounts',
  },
  {
    index: 2,
    value: 'tasks',
    label: 'Tasks',
  },
  {
    index: 3,
    value: 'messages',
    label: 'Messages',
  },
  {
    index: 4,
    value: 'notes',
    label: 'Notes',
  },
  {
    index: 5,
    value: 'files',
    label: 'Files',
  },
  {
    index: 6,
    value: 'emails',
    label: 'Emails',
  },
  {
    index: 8,
    value: 'billing',
    label: 'Billing',
  },
];

export const contactPageTabs = [
  {
    index: 0,
    value: 'timeline',
    label: 'Timeline',
  },
  {
    index: 1,
    value: 'details',
    label: 'Details',
  },
  {
    index: 2,
    value: 'relationships',
    label: 'Accounts',
  },
  {
    index: 3,
    value: 'tasks',
    label: 'Tasks',
  },
  {
    index: 4,
    value: 'messages',
    label: 'Messages',
  },
  {
    index: 5,
    value: 'notes',
    label: 'Notes',
  },
  {
    index: 6,
    value: 'files',
    label: 'Files',
  },
  {
    index: 7,
    value: 'emails',
    label: 'Emails',
  },
]

export const liteContactTabs = [
  {
    index: 0,
    value: 'details',
    label: 'Details',
  },
  {
    index: 1,
    value: 'relationships',
    label: 'Accounts',
  },
  {
    index: 3,
    value: 'messages',
    label: 'Messages',
  },
  {
    index: 6,
    value: 'files',
    label: 'Files',
  },
];
