/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { Button, Label } from 'reactstrap';
import { REMOVESPECIALCHAR } from 'utilities/regularexpressions';
import TwoFactorAuthentication from 'api/TwoFactorAuthentication';
import UserProfileDetail from 'api/UserProfileDetail';
import MultiFactorAuthenticationModal from './MultiFactorAuthenticationModal';

class TwoFactorAuthenticate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mfaEnabled: false,
      mfaManageNext: false,
      verificationCode: '',
      mfaVerificationDone: false,
      generateRecoveryCodes: false,
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    const component = this;
    UserProfileDetail.getUserProfile(component, 'security');
  }

  mfaManage = (mfaManage) => {
    if (mfaManage) {
      this.setState({ mfaVerificationDone: true });
    } else {
      TwoFactorAuthentication.twoFactor(this);
    }
  };

  mfaCancel = () => {
    this.setState({
      mfaManageNext: false,
    });
  };

  mfaManageNext = (value, closemodal = '') => {
    this.setState({
      mfaManageNext: !value,
      mfaNextVerify: closemodal ? !value : value,
      mfaVerificationDone: false,
      verificationCode: '',
    });
    if (this.state.errors.verificationCode) {
      const { errors: err } = this.state;
      err.verificationCode = '';
      this.setState({ errors: err });
    }
  };

  handleVerificationCode = (e) => {
    const value = REMOVESPECIALCHAR(e.target.value);
    this.setState({ verificationCode: value }, () => {
      this.handleMultiAuthValidation();
    });
  };

  handleMultiAuthValidation = () => {
    const { verificationCode } = this.state;
    const err = {};
    let formIsValid = true;
    if (!verificationCode) {
      formIsValid = false;
      err.verificationCode = 'Please enter verification code';
    }
    this.setState({ errors: err });
    return formIsValid;
  };

  mfaVerify = () => {
    if (this.handleMultiAuthValidation()) {
      TwoFactorAuthentication.twoFactorAuthentication(this);
    }
  };

  mfaHandleDisable = () => {
    TwoFactorAuthentication.disableTwoFactorAuthentication(this);
  };

  generateRecoveryCodes(value) {
    if (value) {
      TwoFactorAuthentication.generateTwoFactorRecoveryCodes(this, value);
    } else {
      this.setState({
        mfaVerificationDone: false,
        generateRecoveryCodes: false,
      });
    }
  }

  handlegenerateRecoveryCode = () => {
    this.setState({
      mfaVerificationDone: true,
      generateRecoveryCodes: false,
    });
  };

  copyCodes = () => {
    if (document.selection) {
      document.selection.empty();
      const range = document.body.createTextRange();
      range.moveToElementText(document.getElementById('copyCodes'));
      range.select().createTextRange();
      document.execCommand('Copy', false, null);
    } else if (window.getSelection) {
      window.getSelection().removeAllRanges();
      const range = document.createRange();
      range.selectNode(document.getElementById('copyCodes'));
      window.getSelection().addRange(range);
      document.execCommand('Copy', false, null);
    }
  };

  render() {
    const {
      mfaEnabled,
      mfaManageNext,
      mfaNextVerify,
      mfaVerificationDone,
      generateRecoveryCodes,
      loading,
    } = this.state;
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };

    return (
      <div className="row pt-5">
        <div id="loading" style={loading ? blockStyle : noneStyle} />
        <h2 className="col-sm-12 col-lg-12 col-md-12 mb-0 text-small pt-4">
          Security Settings
        </h2>
        <div className="col-sm-12 col-lg-12 col-md-12 form-group">
          <div className="form-inline-label">
            <Label for="forContact">Multi-Factor Authentication:</Label>
            <div className="lblValue">
              <span className="fontSize pos-rel" style={{ top: '2px' }}>
                {mfaEnabled ? 'Enabled' : 'Disabled'}
              </span>
              <Button
                className="btn btn-primary ml-3"
                color="primary"
                onClick={() => this.mfaManage(mfaEnabled)}
              >
                Manage
              </Button>
            </div>
          </div>
        </div>
        <MultiFactorAuthenticationModal
          isOpen={mfaManageNext}
          headerText="Liscio Multi-Factor Authentication"
          yesBtnClick={() => this.mfaManageNext(true)}
          noBtnClick={() => this.mfaCancel()}
          yesBtnText="Next"
          noBtnText="Back"
        />
        <MultiFactorAuthenticationModal
          className="VerifyModal"
          isOpen={mfaNextVerify}
          headerText="Verify Your Device"
          data={{
            ...this.state,
            qr_code: this.state.qrCode,
            unconfirmed_otp_secret: this.state.barCodeSecretKey,
          }}
          handleVerificationCode={this.handleVerificationCode}
          yesBtnClick={() => this.mfaVerify()}
          noBtnClick={() => this.mfaManageNext(false)}
          yesBtnText="Verify"
          noBtnText="Back"
        />
        <MultiFactorAuthenticationModal
          className="DoneVerification"
          isOpen={mfaVerificationDone}
          headerText="Multi-Factor Authentication"
          data={this.state}
          mfaHandleDisable={() => this.mfaHandleDisable()}
          generateRecoveryCodes={() => this.generateRecoveryCodes(true)}
          yesBtnClick={() => this.setState({ mfaVerificationDone: false })}
          noBtnClick={() => this.mfaManageNext(true, 'closemodal')}
          yesBtnText="Done"
        />
        <MultiFactorAuthenticationModal
          className="RecoveryModal"
          isOpen={generateRecoveryCodes}
          headerText="Recovery Codes"
          data={this.state}
          yesBtnClick={() => this.handlegenerateRecoveryCode()}
          noBtnClick={() => this.generateRecoveryCodes(false)}
          copyCodes={this.copyCodes}
          noBtnText="Cancel"
          yesBtnText="Ok"
        />
      </div>
    );
  }
}

export default TwoFactorAuthenticate;
