/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from 'react-router-dom-latest';
import { useUnReadSMSCount } from 'components/SMS/hooks/useUnReadSMSCount';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SidebarNotifications from './SidebarNotifications';
import MenuItemCount from './MenuItemCount';
import InboxMenuItemCount from './InboxMenuItemCount';
import BulkActionsMenuList from './BulkActionsMenuList';
import AddNewMenuList from './AddNewMenuList';
import { MenuItemIcon } from './MenuItemIcon';

const INBOX_MENU_ITEM = 'Inbox';

export default function MenuList({
  unread_thread_count,
  billingMenuItemCount,
  getSelectedMenuItem,
  handleSubMenuClick,
  menus,
  isStripeAccountDisabled,
}: {
  unread_thread_count: number;
  billingMenuItemCount: number;
  menus: MenuItem[];
  getSelectedMenuItem: TGetSelectedMenuItem;
  handleSubMenuClick: THandleSubMenuClick;
  isStripeAccountDisabled: boolean;
}) {
  const { unreadSMSCount } = useUnReadSMSCount();
  const { hideCreateNewInvoiceButton } = useFlags();

  return (
    <>
      {menus.map((menuItem: MenuItem) => {
        if (menuItem.label === 'Add New' && menuItem.children.length > 0) {
          return (
            <AddNewMenuList
              menuItem={menuItem}
              // the isStripeAccountDisabled controls whether the Add New -> Invoice is visible
              // which is why we're the hideCreateNewInvoiceButton feature flag here
              isStripeAccountDisabled={
                hideCreateNewInvoiceButton || isStripeAccountDisabled
              }
              getSelectedMenuItem={getSelectedMenuItem}
              handleSubMenuClick={handleSubMenuClick}
            />
          );
        }

        if (menuItem.label === 'Bulk Actions' && menuItem.children.length > 0) {
          return (
            <BulkActionsMenuList
              key={menuItem.url}
              menuItem={menuItem}
              handleSubMenuClick={handleSubMenuClick}
              getSelectedMenuItem={getSelectedMenuItem}
            />
          );
        }

        if (menuItem.label === 'Notifications') {
          return (
            <SidebarNotifications
              key={menuItem.url}
              listItem={menuItem}
              handleClick={handleSubMenuClick}
              getSelectedMenuItem={getSelectedMenuItem}
            />
          );
        }

        if (menuItem.label === 'Billing') {
          if (isStripeAccountDisabled) {
            return null;
          }

          return (
            <MenuItemCount
              key={menuItem.url}
              menuItem={menuItem}
              count={billingMenuItemCount}
              handleSubMenuClick={handleSubMenuClick}
              selected={getSelectedMenuItem(menuItem)}
            />
          );
        }

        if (menuItem.label === 'SMS') {
          const isInSMSBeta = true;

          if (!isInSMSBeta) {
            return null;
          }

          return (
            <MenuItemCount
              key={menuItem.url}
              menuItem={menuItem}
              count={unreadSMSCount}
              handleSubMenuClick={handleSubMenuClick}
              selected={getSelectedMenuItem(menuItem)}
            />
          );
        }

        // we will show start menu after profile
        if (menuItem.label === 'Star Separator') {
          return null;
        }

        const defaultQueryParams =
          '?filter=primary&page=1&sort_by=name&sort_order=desc';

        const url =
          menuItem.label === 'Emails'
            ? `${menuItem.url}${defaultQueryParams}`
            : menuItem.url;

        return (
          <Link to={url} key={url} className="Sidebar__MenuListItem">
            <ListItemButton
              key={menuItem.id}
              aria-label={menuItem.label}
              onClick={(e) => handleSubMenuClick(e, menuItem)}
              data-selected={url}
              selected={getSelectedMenuItem(menuItem)}
              data-testid={`sidebar__${menuItem.label.toLowerCase()}`}
            >
              {menuItem.label === INBOX_MENU_ITEM && unread_thread_count > 0 ? (
                <InboxMenuItemCount
                  menuItem={menuItem}
                  messageCount={unread_thread_count}
                />
              ) : (
                <ListItemIcon>
                  <MenuItemIcon iconClass={menuItem.icon_class} />
                </ListItemIcon>
              )}
              <ListItemText primary={menuItem.label.toUpperCase()} />
            </ListItemButton>
          </Link>
        );
      })}
    </>
  );
}
