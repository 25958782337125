import React from 'react';
import { Stack, Divider, styled, Input, Typography } from '@mui/material';
import MultiFactorAuthenticationSetupButtons from 'components/Profile/MultiFactorAuthenticationSetup/components/MultiFactorAuthenticationSetupButtons';

const StyledOtpInput = styled(Input)({
  width: '100%',
  backgroundColor: '#E1F3FE',
  letterSpacing: '2px',
  fontWeight: 600,
  textAlign: 'center',
});

const StyledVerificationCodeInput = styled(Input)({
  width: '100%',
  letterSpacing: '3px',
});

type MFAQrCodeAndOtpSecretProps = {
  headerText?: string;
  data: {
    qr_code?: string;
    unconfirmed_otp_secret?: string;
    verificationCode?: string;
    errors?: {
      verificationCode?: string;
    };
  };
  error?: string;
  handleVerificationCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  noBtnText?: string;
  noBtnClick: () => void;
  yesBtnText: string;
  yesBtnClick: () => void;
};

const MFAQrCodeAndOtpSecret = ({
  headerText,
  data,
  error,
  handleVerificationCode,
  noBtnText,
  noBtnClick,
  yesBtnText,
  yesBtnClick,
}: MFAQrCodeAndOtpSecretProps) => (
  <Stack gap={3}>
    <Typography variant="h4">{headerText}</Typography>
    <p>
      Open your preferred authenticator app and use your phone camera to scan
      the barcode below.
    </p>
    <div className="d-block text-center">
      <img src={data?.qr_code} alt="barcode" />
    </div>
    <Stack>
      <Divider>Or enter the code manually</Divider>
    </Stack>
    <Stack className="form-group mb-4 p-0" alignItems="center">
      <StyledOtpInput
        type="text"
        className="form-control"
        value={data?.unconfirmed_otp_secret}
        readOnly
      />
    </Stack>
    <p className="mb-1">
      The authenthicator app will generate a <strong>verification code.</strong>{' '}
      Enter the verification code below.
    </p>
    <div className="form-group">
      <label htmlFor="codeverify" className="col-12 p-0">
        Verification Code
        <Stack
          className={
            error || data?.errors?.verificationCode
              ? 'lblValue form-error'
              : 'lblValue'
          }
          sx={{ border: 'none' }}
        >
          <StyledVerificationCodeInput
            className={
              error || data?.errors?.verificationCode
                ? 'form-control error'
                : 'form-control'
            }
            type="text"
            name="verificationCode"
            placeholder="Enter verification code"
            value={data?.verificationCode}
            onChange={handleVerificationCode}
            inputProps={{
              'data-testid': 'verification-code-input',
              sx: {
                // Separating sx from the styled() call seems to be the only way
                // to target the placeholder text letterSpacing separately
                '&::placeholder': {
                  letterSpacing: '0px',
                  fontWeight: 400,
                },
              },
            }}
          />
          {typeof error === 'string' || data?.errors?.verificationCode ? (
            <span className="error_label lowercase">
              {error || data?.errors?.verificationCode}
            </span>
          ) : null}
        </Stack>
      </label>
    </div>
    <MultiFactorAuthenticationSetupButtons
      noBtnText={noBtnText}
      noBtnClick={noBtnClick}
      yesBtnText={yesBtnText}
      yesBtnClick={yesBtnClick}
      buttonType="submit"
    />
  </Stack>
);

export default MFAQrCodeAndOtpSecret;
