/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React, { useCallback } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { MenuItemIcon } from './MenuItemIcon';

export default function HelpButton() {
  const handleClick = useCallback((e: React.MouseEvent) => {
    //@ts-expect-error global scope for userpilot
    const { userpilot } = window;
    if (userpilot) {
      e.preventDefault();
      userpilot.trigger('Sr3G26T');
      return;
    }
    // navigate to 'https://support.liscio.me/portal/en/kb/liscio/client-end-user' in a new tab
    window.open(
      'https://support.liscio.me/portal/en/kb/liscio/client-end-user',
      '_blank',
    );
  }, []);

  return (
    <div>
      <ListItemButton onClick={handleClick} data-testid="sidebar__help">
        <ListItemIcon>
          <MenuItemIcon iconClass="help" />
        </ListItemIcon>
        <ListItemText primary="NEED HELP?" />
      </ListItemButton>
    </div>
  );
}
