import { useFlags } from 'launchdarkly-react-client-sdk';
import { NewDraftWorkflowsView } from './NewDraftWorkflowsView';
import { OldDraftWorkflowsView } from './OldDraftWorkflowsView';

export const DraftWorkflowsView = () => {
  const { newRequestModal } = useFlags();

  if (newRequestModal) {
    return <NewDraftWorkflowsView />;
  }
  return <OldDraftWorkflowsView />;
};
