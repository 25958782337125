import React, { useMemo } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocation, matchPath } from 'react-router-dom-latest';
import { useStorageData } from 'hooks/useStorageData';
import {
  getIsMobileRedirectDevice,
  getMobileSiteRedirect,
} from './mobile-site-redirect-utils';
import { deferredPaths } from './mobile-redirects';

export interface MobileSiteRedirectProps {
  children?: React.ReactNode;
}

export const MobileSiteRedirect = ({ children }: MobileSiteRedirectProps) => {
  const location = useLocation();
  const ldClient = useLDClient();
  const { refactorMigration } = useFlags();
  const { storageData } = useStorageData();
  const currentlyLoggedCpaUrl = (storageData as { company_url: string })
    ?.company_url;
  const [redirectUrl, bypassMobileCheck] = getMobileSiteRedirect(
    window.location,
    currentlyLoggedCpaUrl,
  );
  const isMobileRedirectDevice = getIsMobileRedirectDevice(
    window.navigator.userAgent,
  );
  const shouldDeferRendering = useMemo(
    () =>
      Boolean(
        deferredPaths.some((pattern) => matchPath(pattern, location.pathname)),
      ),
    [location],
  );

  // Handle mobile redirect
  if (
    redirectUrl &&
    (isMobileRedirectDevice || (bypassMobileCheck && refactorMigration))
  ) {
    window.location.replace(redirectUrl);

    // Render nothing to prevent one-time-use tokens from being consumed
    return null;
  }

  // Defer rendering until launch darkly has loaded for configured endpoints
  if (ldClient === undefined && shouldDeferRendering) {
    return null;
  }

  return (children as unknown as React.ReactElement) || null;
};
