import { WorkflowItem2Fragment, WorkflowSectionFragment } from 'gql/graphql';

export enum WorkflowItemType {
  Boolean = 'WfiAnswerBooleanQuestion',
  Text = 'WfiAnswerTextQuestion',
  Upload = 'WfiUploadDocument',
  Reviewable = 'WfiReviewableDocument',
  Payable = 'WfiPayable',
  Signable = 'WfiSignableDocument',
  Dynamic = 'WfiDynamicItem',
}

export interface WorkflowUserQuestion {
  id: number;
  uuid: string;
  workflowId: number;
  text: string;
  answer: {
    id: number;
    question_id: number;
    uuid: string;
    created_at: string;
    updated_at: string;
    content: string;
    cpa_user_id: number;
    user_questionnaire_id: number;
  };
}

export interface BuilderItem extends Omit<WorkflowItem2Fragment, 'section'> {
  displayPosition?: number | null;
}

export interface BuilderSection
  extends Omit<WorkflowSectionFragment, 'items2'> {
  sectionIndex?: number;
  items2: BuilderItem[];
}

export type DropBoxType = 'original' | 'organizer' | 'tax_delivery';

export interface WorkflowRPARequestPayload {
  rpaType: DropBoxType;
  cpaUserId: number;
  signedBlobId: string;
}

// eslint-disable-next-line no-shadow
export const enum RPA_RESPONSE_STATUS {
  pending = 'pending',
  completed = 'completed',
  processing = 'processing',
  failed = 'failed',
}

export interface WorkflowRPARequestResponse {
  id: string;
  cpa_user_id: number;
  created_at: string;
  updated_at: string;
  status: RPA_RESPONSE_STATUS;
  payload?: any;
  presigned_url?: string | null;
  uuid?: string | null;
}
