import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type MfaSetupValidationFormInterface = {
  verificationCode: string;
};

export const mfaSetupFormValidationResolver = yupResolver(
  yup.object().shape({
    verificationCode: yup.string().required('MFA Code is required'),
  }),
);
