/* eslint-disable react-hooks/exhaustive-deps */
// Eslint will be enabled once filter section is completed
/* eslint-disable jsx-a11y/control-has-associated-label */

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Divider } from '@mui/material';
import { ACTIVITY_DETAIL } from 'constants/constants';
import NoRecords from 'common/NoRecords';
import Pagination from 'common/Pagination';
import { useLocation, useNavigate } from 'react-router-dom-latest';
import { getUrlParams } from 'utilities/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RenderActivityList from './RenderActivityList';
import ActivityFilter from './elements/ActivityFilter';
import { getActivityTypes } from './elements/ActivityFilter/FilterElements';

const getRecipientData = (urlData) => {
  const { recipient_label, recipient_id } = urlData;
  return {
    label: recipient_label,
    value: parseInt(recipient_id, 10),
  };
};

const TimelineActivity = ({ id, requestType }) => {
  const { smsReminderFeature, requestsPageFeature, hideBillingFeatures } = useFlags();
  const location = useLocation();
  const navigate = useNavigate();
  const urlData = getUrlParams(location.search);
  const types = urlData.type ? urlData.type.split(',') : [];
  const typeFiltered = getActivityTypes().filter((el) =>
    types.some((f) => f === el.value),
  );
  const recipient = urlData.recipient_id ? getRecipientData(urlData) : null;
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const [selectedType, setSelectedType] = useState(typeFiltered || []);
  const [selectedRecepientData, setSelectedRecepientData] = useState(recipient);
  const [selectedFromDate, setSelectedFromDate] = useState(
    urlData.from_date || null,
  );
  const [selectedToDate, setSelectedToDate] = useState(urlData.to_date || null);
  const [sortBy, setSortBy] = useState(urlData.order || 'desc');
  const [activitySearch, setActivitySearch] = useState(urlData.search || '');
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(urlData.page || 1);

  useEffect(() => {
    getActivityData();
    setLoading(true);
  }, [
    urlData.page,
    urlData.type,
    urlData.recipient_id,
    urlData.order,
    urlData.from_date,
    urlData.to_date,
    urlData.search,
  ]);

  useEffect(() => {
    handleFilters();
    setLoading(true);
  }, [
    selectedType,
    selectedRecepientData,
    selectedFromDate,
    selectedToDate,
    sortBy,
    activitySearch,
    page,
  ]);

  async function getActivityData() {
    const path = `${ACTIVITY_DETAIL}?id=${id}&requestType=${requestType}`;
    const url = getUrl(path, 'forApi');
    try {
      const request = await axios.get(url);
      if (request.status === 200) {
        setActivityData(request.data.data || []);
        setPaginationData(request.data.stats);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function handlePageChange(index) {
    setPage(index);
  }

  function handleFilters() {
    const { pathname } = location;
    const path = pathname;
    const url = getUrl(path);
    navigate(url);
  }

  function getUrl(path, forApi = '') {
    let url = path;
    if (page) {
      url += `${forApi ? '&' : '?'}page=${page}`;
    }
    if (selectedType.length > 0) {
      const type = selectedType.map((arr) => arr.value);
      url += `&type=${type.toString()}`;
    }
    if (selectedRecepientData && selectedRecepientData.value) {
      url += `&recipient_id=${selectedRecepientData.value}`;
    }
    if (selectedRecepientData && selectedRecepientData.label && !forApi) {
      url += `&recipient_label=${selectedRecepientData.label}`;
    }
    if (sortBy) {
      url += `&order=${sortBy}`;
    }
    if (selectedFromDate && selectedToDate) {
      url += `&from_date=${selectedFromDate}&to_date=${selectedToDate}`;
    }
    if (activitySearch.length >= 2) {
      url += `&search=${activitySearch}`;
    }
    return url;
  }

  return (
    <div className="AccountsActivity">
      {loading && <div id="loading" className="AccountsActivity__Loading" />}
      <Container
        sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}
        maxWidth="md"
      >
        <ActivityFilter
          types={getActivityTypes(smsReminderFeature, requestsPageFeature, hideBillingFeatures)}
          values={{
            selectedType,
            selectedRecepientData,
            sortBy,
            selectedFromDate,
            selectedToDate,
            activitySearch,
          }}
          selectedTypeChange={setSelectedType}
          selectedRecepientData={selectedRecepientData}
          setSelectedRecepientData={setSelectedRecepientData}
          setSelectedFromDate={setSelectedFromDate}
          setSelectedToDate={setSelectedToDate}
          setSortBy={setSortBy}
          setActivitySearch={setActivitySearch}
          id={id}
        />
        <Divider className="dividerpadding" variant="fullWidth" />
        {activityData.length ? (
          <>
            <RenderActivityList activityList={activityData} />
            <Pagination
              currentPage={paginationData.page}
              totalPages={paginationData.pages}
              totalRecords={paginationData.count}
              offset={
                paginationData.count === 0
                  ? paginationData.from - 1
                  : paginationData.from
              }
              off={paginationData.to}
              handlePageChange={handlePageChange}
            />
          </>
        ) : null}
        {!loading && activityData.length === 0 && <NoRecords />}
      </Container>
    </div>
  );
};

export default TimelineActivity;
