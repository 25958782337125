import { Box } from '@mui/material';
import {
  WorkflowRequestStatus,
  WorkflowSortOption,
  WorkflowStatus,
} from 'gql/graphql';
import { REQUESTS_PAGE_TITLES } from 'components/Requests/requests.constants';
import { useLayoutEffect } from 'react';
import { useWorkflowsQuery } from 'hooks/workflow-hooks';
import { usePagination } from 'components/Requests/components/RequestsBuilder/hooks/pagination';
import { useRequestsState } from 'components/Requests/state/request-state';
import { DraftWorkflowsTable } from 'components/Requests/components/Tables/Components/DraftWorkflowsTable';

export const OldDraftWorkflowsView = () => {
  useLayoutEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.draftsWorkflows;
  }, []);

  const { paginationModel, setPaginationModel } = usePagination();
  const sortStatus = useRequestsState(
    (state) => state.sortStatus?.[WorkflowRequestStatus.Draft],
  );

  const workflowsDrafts = useWorkflowsQuery({
    status: WorkflowStatus.Draft,
    perPage: paginationModel.pageSize,
    page: paginationModel.page + 1,
    sortDirection: sortStatus?.sortDirection,
    sortField: WorkflowSortOption.LastActivity,
  });

  return (
    <Box>
      <DraftWorkflowsTable
        workflowsQuery={workflowsDrafts}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  );
};
