import {
  faHouse,
  faTasks,
  faEnvelope,
  faBriefcase,
  faAddressBook,
  faFiles,
  faSignature,
  faInbox,
  faUser,
  faSms,
  faMoneyBills,
  faBell,
  faSquarePlus,
  faMessageLines,
  faFileLines,
  faFolder,
  faRightFromBracket,
  faDownload,
  faFileSignature,
  faShareFromSquare,
  faCheckDouble,
  faListCheck,
  faQuestionCircle,
} from '@awesome.me/kit-7c5308637e/icons/classic/regular';
import { faRequest } from '@awesome.me/kit-7c5308637e/icons/kit/custom';

export const iconMap = {
  'icon-home': faHouse,
  'icon-Tasks': faTasks,
  'icon-Requests': faRequest,
  'icon-Email': faEnvelope,
  'icon-Accounts': faBriefcase,
  'icon-Contacts': faAddressBook,
  'icon-file': faFiles,
  'icon-eDoc': faSignature,
  'icon-message': faInbox,
  'icon-switch-account': faUser,
  'icon-sms': faSms,
  'icon-billing': faMoneyBills,
  'icon-bell': faBell,
  'icon-square-plus': faSquarePlus,
  'notifyIcon icon-message': faMessageLines,
  'icon-notes': faFileLines,
  'icon-File': faFolder,
  'icon-logout': faRightFromBracket,
  'icon-download': faDownload,
  'icon-signature': faFileSignature,
  'icon-Multi-invite': faShareFromSquare,
  'icon-bulk_task': faCheckDouble,
  'check-list': faListCheck,
  help: faQuestionCircle,
};
