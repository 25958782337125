/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import MultiFactorAuthenticationSetupButtons from 'components/Profile/MultiFactorAuthenticationSetup/components/MultiFactorAuthenticationSetupButtons';
import { Button } from '@mui/material';

type MFADisableSettingsProps = {
  data: {
    mfaEnabled?: boolean;
  };
  mfaHandleDisable: () => void;
  generateRecoveryCodes: () => void;
  noBtnText?: string;
  noBtnClick: () => void;
  yesBtnText: string;
  yesBtnClick: () => void;
};

export const MFADisableSettings: React.FC<MFADisableSettingsProps> = ({
  data,
  mfaHandleDisable,
  generateRecoveryCodes,
  noBtnText,
  noBtnClick,
  yesBtnText,
  yesBtnClick,
}) => (
  <div className="d-block">
    <div className="formGroup mb-3">
      <label className="text-body text-small mb-0">Status</label>
      <div className="lblValue">
        {data?.mfaEnabled && (
          <div>
            <span className="fontSize">Enabled</span>
            <a
              onClick={mfaHandleDisable}
              className="fontSize font-weight-bold ml-2"
            >
              Disable
            </a>
          </div>
        )}
      </div>
    </div>
    <div className="formGroup mb-3">
      <label className="text-body text-small mb-0">Access</label>
      <div className="lblValue">
        <Button
          variant="text"
          className="fontSize font-weight-bold link px-0"
          onClick={generateRecoveryCodes}
        >
          Generate Recovery Codes… <i className="iconCode">?</i>
        </Button>
      </div>
    </div>
    <MultiFactorAuthenticationSetupButtons
      noBtnText={noBtnText}
      noBtnClick={noBtnClick}
      yesBtnText={yesBtnText}
      yesBtnClick={yesBtnClick}
    />
  </div>
);
