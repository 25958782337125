/* eslint-disable*/
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import MultiFactorAuthenticationSetup from './MultiFactorAuthenticationSetup';

const MultiFactorAuthenticationModal = (props) => {
  let doneVerification = false;
  let recoveryModal = false;
  let verifyModal = false;
  if (props.className === 'DoneVerification') {
    doneVerification = true;
  } else if (props.className === 'RecoveryModal') {
    recoveryModal = true;
  } else if (props.className === 'VerifyModal') {
    verifyModal = true;
  }

  const RecoveryCodes = props.data?.recoveryCodes?.length
    ? props.data.recoveryCodes.map((item, index) => (
        <div key={index}>{item.value}</div>
      ))
    : [];

  return (
    <Modal
      isOpen={props.isOpen}
      className="customModal customModal--alert customModal--w700 customModal--nopadding"
    >
      <div className="ModalHeader">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={props.noBtnClick}
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalBody className="modalContent">
        <div className="modalContent--inner">
          <div className="modalContent--header text-large">
            <h5 className="modalContent--header text-medium mb-2">
              {props.headerText}
            </h5>
          </div>
          <MultiFactorAuthenticationSetup
            className={props.className}
            data={props.data}
            mfaHandleDisable={props.mfaHandleDisable}
            generateRecoveryCodes={props.generateRecoveryCodes}
            copyCodes={props.copyCodes}
            handleVerificationCode={props.handleVerificationCode}
            noBtnText={props.noBtnText}
            noBtnClick={props.noBtnClick}
            yesBtnText={props.yesBtnText}
            yesBtnClick={props.yesBtnClick}
            isOpen={props.isOpen}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MultiFactorAuthenticationModal;
