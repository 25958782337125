/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom-latest';
import { withRouter } from 'utilities/withRouterWrapper';
import TooltipItem from 'common/TooltipItem_updated';
import debounce from 'lodash/debounce';
import {
  ACCOUNT,
  SEARCH_ACCOUNTS_WITH_FILTERS,
  DEACTIVATE_ACCOUNT,
  GET_ALL_EMPLOYEES,
  ACCOUNT_REPORTS,
  ACCOUNTS_ARCHIVE,
  ACCOUNTS_UNARCHIVE,
} from 'constants/constants';
import HeaderTabs from 'common/HeaderTabs_updated';
import {
  AlertMessage,
  sortList,
  sortingIcon,
  getCpaPreferences,
  isUserEmployee,
  getRole,
  generateFullName,
  generatingAccountsCsvFile,
  getOffSetsAndLimit,
  getCpaName,
  totalPages,
  totalRecords,
} from 'utilities/utils';
import Pagination from 'common/Pagination';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';
import RatingComponent from 'common/RatingComponent';
import {
  saveAccountListData,
  updateCheckedAccountListData,
} from 'actions/AccountandContactListActions';
import { CheckboxField } from 'common/CustomFields';
import { withStorageData } from 'utilities/withStorageData';
import ClientAccounts from './ClientAccounts_updated';

const areFiltersSelected = (filtersObject, searchFieldText) => {
  const {
    entity_type,
    account_status,
    account_stage,
    employee_id,
    is_restricted_account,
  } = filtersObject;
  return (
    (entity_type && entity_type.length && Object.keys(entity_type[0]).length) ||
    (account_status &&
      account_status.length &&
      Object.keys(account_status[0]).length) ||
    (account_stage &&
      account_stage.length &&
      Object.keys(account_stage[0]).length) ||
    (employee_id && employee_id.length && Object.keys(employee_id[0]).length) ||
    (is_restricted_account &&
      is_restricted_account.length &&
      Object.keys(is_restricted_account[0]).length) ||
    searchFieldText.length >= 3
  );
};

const accountPageTabs = [
  {
    filterlabel: 'all',
    value: 'all',
    label: 'All',
  },
  {
    filterlabel: 'Prospect',
    value: 'prospects',
    label: 'Prospects',
  },
  {
    filterlabel: 'Client',
    value: 'clients',
    label: 'Clients',
  },
  {
    filterlabel: 'Inactive',
    value: 'inactive',
    label: 'Archived',
  },
];

const accountPageTableHeaders = [
  {
    value: 'name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'external_account_id',
    label: 'External ID',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'entity_type',
    label: 'Entity Type',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'status',
    label: 'Account Status',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'rating',
    label: 'Account Rating',
    isSortable: true,
    className: ' col thCol',
  },
];

const removeItem = (array, element) =>
  array.filter((e) => !(e.id === element.id) && !element.checked);

class AccountList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      tabs: accountPageTabs,
      listData: [],
      sortBy: 'name',
      sortOrder: 'asc',
      pagination: 1,
      filter: 'all',
      totalPages: 1,
      totalRecords: 0,
      offset: [0, 15],
      loading: true,
      searchFieldText: '',
      showDeleteConfirmation: false,
      isEmployee: isUserEmployee(),
      preferences: getCpaPreferences(),
      role: getRole(),
      selectedFilterList: {},
      csTeam: [],
      inputValue: '',
      selectedCheckbox: [],
      selectedAll: false,
      confirmArchive: false,
      componentContext: this.props.componentContext,
      filterInFocus: '',
    };
  }

  setFilterInFocus = (filterName = '', e = {}) => {
    if (e.stopPropagation) e.stopPropagation();
    this.setState({
      filterInFocus: this.state.filterInFocus === filterName ? '' : filterName,
    });
  };

  hideOpenFilters = () => {
    this.setState({ filterInFocus: '' });
  };

  componentDidMount() {
    document.title = 'Accounts';
    let { activeTab, pagination, sortBy, sortOrder, searchFieldText } =
      this.state;
    const { selectedFilterList } = this.state;
    this.getCsts();
    if (this.props.location && this.props.location.search) {
      const searchParams = new URLSearchParams(this.props.location.search);
      searchParams.forEach((value, key) => {
        if (key === 'filter') {
          activeTab = this.state.tabs.findIndex(
            (each) => each.filterlabel.toLowerCase() === value.toLowerCase(),
          );
          if (activeTab < 0) {
            activeTab = 0;
          }
        } else if (key === 'page') {
          // eslint-disable-next-line radix
          pagination = parseInt(value);
        } else if (key === 'sort_by') {
          sortBy = value;
        } else if (key === 'sort_order') {
          sortOrder = value;
        } else if (key === 'employee_id') {
          selectedFilterList[key] = [
            value.split(',').reduce((result, item) => {
              const index = item.lastIndexOf(':');
              const ky = item.substr(0, index);
              const val = item.substring(index + 1);
              result[ky] = val;
              return result;
            }, {}),
          ];
        } else if (
          key === 'entity_type' ||
          key === 'account_status' ||
          key === 'account_stage' ||
          key === 'is_restricted_account'
        ) {
          selectedFilterList[key] = [
            value.split(',').reduce((result, item) => {
              if (key === 'is_restricted_account') {
                if (item === 'true') {
                  result['Restricted Account'] = true;
                }
              } else {
                result[item] = true;
              }
              return result;
            }, {}),
          ];
        } else if (key === 'keyword') {
          searchFieldText = value;
        }
      });
      this.setState(
        {
          activeTab,
          filter: this.state.tabs[activeTab].filterlabel,
          pagination,
          sortBy,
          sortOrder,
          selectedFilterList,
          searchFieldText,
        },
        () => {
          const {
            entity_type,
            account_status,
            account_stage,
            employee_id,
            is_restricted_account,
          } = selectedFilterList;
          if (
            searchFieldText ||
            entity_type ||
            account_status ||
            account_stage ||
            employee_id ||
            is_restricted_account
          ) {
            this.getSearchResults(searchFieldText);
          } else {
            this.getRecords();
          }
        },
      );

      if (this.state.hideFilters) {
        this.setState({ filterInFocus: '' });
      }
    } else {
      this.getRecords();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.search === '' &&
      this.props.location.search.includes('filter')
    ) {
      this.clearFilters();
    }
  }

  getCsts = () => {
    Axios.get(GET_ALL_EMPLOYEES).then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        this.setState({ csTeam: res.data.data });
      }
    });
  };

  getRecords = (fromArchive = '') => {
    const {
      pagination,
      sortBy,
      sortOrder,
      filter,
      isEmployee,
      componentContext,
    } = this.state;
    const { accountslistdata } = this.props;
    const allAccountsButtonUrl = `/accounts?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    const key = allAccountsButtonUrl;
    let url = `${ACCOUNT}?page=${pagination}&field=${sortBy}&sort_by=${sortOrder}`;
    if (filter.toLowerCase() !== 'all') {
      url = `${url}&status=${filter}`;
    }
    if (accountslistdata && accountslistdata[key] && fromArchive !== '') {
      this.setState({ loading: 1 });
    }
    if (accountslistdata && accountslistdata[key]) {
      const stats = accountslistdata[key].data.count_data;
      const tabs = [];
      accountPageTabs.map((each) => {
        const output = { ...each };
        output.label =
          stats[each.value] !== undefined
            ? `${output.label} | ${stats[each.value]}`
            : output.label;
        output.noofrecords = stats[each.value];
        tabs.push(output);
        return each;
      });
      this.setState({
        tabs,
        listData: isEmployee
          ? accountslistdata[key].data.data
          : this.state.listData,
        totalPages: totalPages(filter, tabs, 'filterlabel', 15),
        totalRecords: totalRecords(filter, tabs, 'filterlabel'),
        offset: getOffSetsAndLimit(
          totalRecords(filter, tabs, 'filterlabel'),
          15,
        )[pagination - 1],
        loading: false,
      });
    }
    Axios.get(url).then((res) => {
      if (
        res.status === 200 &&
        res.data.status &&
        res.data.status === 200 &&
        res.data.data
      ) {
        const stats = res.data.count_data;
        const tabs = [];
        accountPageTabs.map((each) => {
          const output = { ...each };
          output.label =
            stats[each.value] !== undefined
              ? `${output.label} | ${stats[each.value]}`
              : output.label;
          output.noofrecords = stats[each.value];
          tabs.push(output);
          return each;
        });
        let listData = res.data.data;
        if (!isUserEmployee()) {
          listData = res.data.new_data.map((e) => {
            e.account.is_account_owner = e.is_account_owner;
            e.account.has_invoices_that_need_attention =
              e.has_invoices_that_need_attention;
            return e.account;
          });
        }
        listData = isEmployee
          ? res.data.data
          : sortList(listData, sortBy, sortBy);
        this.setState({
          tabs,
          listData: listData.length ? listData : null,
          // TODO: Check this code
          totalPages: totalPages(this.state.filter, tabs, 'filterlabel', 15),
          totalRecords: totalRecords(this.state.filter, tabs, 'filterlabel'),
          offset: getOffSetsAndLimit(
            totalRecords(this.state.filter, tabs, 'filterlabel'),
            15,
          )[this.state.pagination - 1],
          loading: false,
        });
        this.props.saveAccountListData({
          key: allAccountsButtonUrl,
          listData,
          data: res.data,
        });
        this.handlepageChangeCheckbox();
        window.scrollTo(0, 0);
      } else {
        this.setState({
          listData: null,
          loading: false,
        });
      }
    });
    if (!componentContext) {
      this.props.navigate(allAccountsButtonUrl, { replace: true });
      localStorage.setItem('allAccountsButtonUrl', allAccountsButtonUrl);
    }
  };

  getSearchResults = (input) => {
    const {
      selectedFilterList: {
        entity_type,
        account_status,
        account_stage,
        employee_id,
        is_restricted_account,
      },
      pagination,
      sortBy,
      sortOrder,
      filter,
      componentContext,
    } = this.state;
    const data = {
      keyword: input.toLowerCase(),
      entity_type:
        entity_type && entity_type.length ? Object.keys(entity_type[0]) : [],
      status:
        account_status && account_status.length
          ? Object.keys(account_status[0])
          : [],
      stage:
        account_stage && account_stage.length
          ? Object.keys(account_stage[0])
          : [],
      employee_id:
        employee_id && employee_id.length
          ? Object.values(employee_id[0])[0]
          : '',
      is_restricted_account:
        is_restricted_account &&
        is_restricted_account.length &&
        Object.keys(is_restricted_account[0]).length
          ? Object.values(is_restricted_account[0])[0]
          : false,
      page: pagination,
      per_page: 15,
      sort_by: sortOrder,
      field: sortBy,
      filter: filter !== 'all' ? filter.toLowerCase() : null,
    };
    const _allAccountsButtonUrl = `/accounts?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;

    Axios.post(SEARCH_ACCOUNTS_WITH_FILTERS, data).then((res) => {
      if (input === this.state.searchFieldText) {
        if (res.data.status === 200) {
          const stats = res.data.count_data;
          const tabs = [];
          accountPageTabs.map((each) => {
            const output = { ...each };
            output.label =
              stats[each.value] !== undefined
                ? `${output.label} | ${stats[each.value]}`
                : output.label;
            output.noofrecords = stats[each.value];
            tabs.push(output);
            return each;
          });
          this.setState({
            tabs,
            listData: res.data.data?.length ? res.data.data : null,
            totalPages: totalPages(this.state.filter, tabs, 'filterlabel', 15),
            totalRecords: totalRecords(this.state.filter, tabs, 'filterlabel'),
            offset: getOffSetsAndLimit(
              totalRecords(this.state.filter, tabs, 'filterlabel'),
              15,
            )[this.state.pagination - 1],
            loading: false,
          });
          this.props.saveAccountListData({
            key: _allAccountsButtonUrl,
            listData: res.data.data,
            data: res.data,
          });
          this.handlepageChangeCheckbox();
        } else {
          const tabs = [];
          accountPageTabs.map((each) => {
            const output = { ...each };
            output.label += ' | 0';
            output.noofrecords = 0;
            tabs.push(output);
            return each;
          });
          this.setState({
            tabs,
            listData: null,
            loading: false,
          });
          this.handlepageChangeCheckbox();
        }
      }
    });
    if (!componentContext) {
      const allAccountsButtonUrl = `/accounts?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}&keyword=${
        data.keyword
      }${
        data.entity_type && data.entity_type.length
          ? `&entity_type=${data.entity_type.join(',')}`
          : ''
      }${
        data.status && data.status.length
          ? `&account_status=${data.status.join(',')}`
          : ''
      }${
        data.stage && data.stage.length
          ? `&account_stage=${data.stage.join(',')}`
          : ''
      }${
        data.employee_id && data.employee_id !== ''
          ? `&employee_id=${Object.keys(employee_id[0])[0]}:${
              Object.values(employee_id[0])[0]
            }`
          : ''
      }&is_restricted_account=${data.is_restricted_account}`;
      this.props.navigate(allAccountsButtonUrl);
      localStorage.setItem('allAccountsButtonUrl', allAccountsButtonUrl);
    }
  };

  getRoleOfCst = (cst) => {
    const {
      preferences: { user_role },
    } = this.state;
    let role = cst.role.replace(/_/g, ' ');
    user_role.map((item) => {
      if (cst.role === item.key) {
        role = item.label;
      }
      return role;
    });
    return role;
  };

  changeActiveTab = (tabIndex, tabDetails) => {
    const checkbox = document.getElementById('accSelectAll');
    const { selectedFilterList, searchFieldText } = this.state;
    const callSearchApi = areFiltersSelected(
      selectedFilterList,
      searchFieldText,
    );
    this.setState(
      {
        selectedCheckbox: [],
        activeTab: tabIndex,
        filter: tabDetails.filterlabel,
        pagination: 1,
        loading: true,
      },
      () =>
        callSearchApi
          ? this.getSearchResults(searchFieldText)
          : this.getRecords(),
    );
    if (checkbox !== null) {
      checkbox.indeterminate = false;
    }
  };

  handlePageChange = (input) => {
    const { selectedFilterList, searchFieldText } = this.state;
    const callSearchApi = areFiltersSelected(
      selectedFilterList,
      searchFieldText,
    );
    this.setState(
      {
        pagination: input,
        loading: true,
      },
      () =>
        callSearchApi
          ? this.getSearchResults(searchFieldText)
          : this.getRecords(),
    );
  };

  handlepageChangeCheckbox = () => {
    const { selectedCheckbox, isEmployee } = this.state;
    const checkbox = document.getElementById('accSelectAll');
    let checkBoxCount = 0;
    const { listData } = this.state;
    if (listData !== null && listData !== undefined && isEmployee) {
      listData.map((item) => {
        const checkedId = item.id;
        selectedCheckbox.map((_item) => {
          const verifyChecked = _item.id;
          if (checkedId === verifyChecked) {
            checkBoxCount += 1;
            item.checked = true;
          }
          return null;
        });
        return listData;
      });
    }
    this.setState({});
    if (listData !== null && listData !== undefined && isEmployee) {
      const checkIndeterminate = document.getElementById('accSelectAll');
      if (
        checkBoxCount === 0 &&
        checkIndeterminate !== null &&
        checkIndeterminate !== undefined
      ) {
        checkbox.indeterminate = false;
        this.setState({ selectedAll: false });
      }
      if (checkBoxCount === listData.length && listData.length !== 0) {
        this.setState({ selectedAll: true });
      }
      if (checkBoxCount > 0 && checkBoxCount < listData.length) {
        checkbox.indeterminate = true;
      }
    }
    if (listData === null && checkBoxCount === 0 && isEmployee) {
      checkbox.indeterminate = false;
      this.setState({ selectedAll: false });
    }
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    this.setState(
      {
        sortOrder,
        sortBy,
        loading: true,
      },
      () => {
        const { selectedFilterList, searchFieldText } = this.state;
        const callSearchApi = areFiltersSelected(
          selectedFilterList,
          searchFieldText,
        );

        // if user is contact then we do sorting in front end
        if (!this.state.isEmployee) {
          this.setState({
            loading: false,
            listData: sortList(
              this.state.listData,
              this.state.sortBy,
              this.state.sortOrder,
            ),
          });
          // if filters are applied
        } else if (callSearchApi) {
          this.getSearchResults(searchFieldText);
        } else {
          this.getRecords();
        }
      },
    );
  };

  showDetails = (id, page) => {
    if (page === 'edit') {
      return this.props.navigate(`/account/edit/${id}`);
    }
    if (process.env.REACT_APP_INFOCUS_FLAG && isUserEmployee()) {
      return `/accountdetails/${id}/activity`;
    }

    return `/accountdetails/${id}/details`;
  };

  handleSearchFieldChange = (input) => {
    const inputvalue =
      input && input.target && input.target.value ? input.target.value : '';
    const { selectedFilterList } = this.state;
    this.setState({
      searchFieldText: inputvalue,
      loading: true,
      totalRecords: 0,
      pagination: 1,
    });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      const callSearchApi = areFiltersSelected(
        selectedFilterList,
        this.state.searchFieldText,
      );
      if (callSearchApi) {
        this.getSearchResults(inputvalue);
      } else {
        this.getRecords();
      }
    }, 500);
  };

  handleSearchRequest = debounce(() => {
    const { selectedFilterList, searchFieldText } = this.state;
    const callSearchApi = areFiltersSelected(
      selectedFilterList,
      searchFieldText,
    );
    if (searchFieldText.length > 2 || callSearchApi) {
      this.setState(
        {
          loading: callSearchApi,
          totalRecords: 0,
          pagination: 1,
        },
        () => (callSearchApi ? this.getSearchResults(searchFieldText) : null),
      );
    }
  }, 300);

  showDeleteConfirmationModal = (input = {}) => {
    this.setState({
      recordToDelete: input,
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
    });
  };

  deleteRecord = () => {
    Axios.put(`${DEACTIVATE_ACCOUNT}/${this.state.recordToDelete.id}`).then(
      (res) => {
        this.getRecords();
        this.setState({
          recordToDelete: {},
          showDeleteConfirmation: false,
          searchFieldText: '',
        });
        if (res.status === 204 || res.status === 200) {
          AlertMessage('success', res.data.message, 3000);
        } else {
          AlertMessage('error', res.data.message, 3000);
        }
      },
    );
  };

  handleFilters = (e, input, filter_type, fro = '') => {
    this.hideOpenFilters();
    let { selectedFilterList } = this.state;
    const { searchFieldText } = this.state;
    let filterName = 'entity_type';
    let { key } = input;
    let { name } = e.target;
    let id = '';
    if (filter_type.toLowerCase() === 'account status') {
      filterName = 'account_status';
    } else if (filter_type.toLowerCase() === 'account stage') {
      filterName = 'account_stage';
    } else if (filter_type.toLowerCase() === 'cs team') {
      filterName = 'employee_id';
      if (fro === 'filter') {
        key = generateFullName(input);
        name = generateFullName(input);
        id = input.id;
      }
    } else if (filter_type.toLowerCase() === 'restricted account') {
      filterName = 'is_restricted_account';
      key = 'Restricted Account';
      name = 'Restricted Account';
    }
    if (
      selectedFilterList[filterName] &&
      selectedFilterList[filterName].length
    ) {
      if (
        !selectedFilterList[filterName][0][key] &&
        filter_type.toLowerCase() === 'cs team' &&
        fro === 'filter'
      ) {
        delete selectedFilterList[filterName][0];
        selectedFilterList = {
          ...selectedFilterList,
          [filterName]: [{ [key]: id }],
        };
      } else if (!selectedFilterList[filterName][0][key]) {
        selectedFilterList[filterName][0][name] = true;
      } else if (
        selectedFilterList[filterName][0][key] &&
        filter_type.toLowerCase() === 'cs team' &&
        fro === 'filter'
      ) {
        // do nothing
      } else {
        delete selectedFilterList[filterName][0][key];
      }
    } else {
      selectedFilterList = {
        ...selectedFilterList,
        [filterName]: [
          {
            [key]:
              filter_type.toLowerCase() === 'cs team' && fro === 'filter'
                ? id
                : true,
          },
        ],
      };
    }
    const callSearchApi = areFiltersSelected(
      selectedFilterList,
      searchFieldText,
    );
    this.setState(
      {
        selectedFilterList,
        pagination: 1,
      },
      () => (callSearchApi ? this.handleSearchRequest() : this.getRecords()),
    );
  };

  clearFilters = () => {
    this.setState(
      {
        selectedFilterList: {},
        sortBy: 'name',
        sortOrder: 'asc',
        pagination: 1,
        searchFieldText: '',
        loading: true,
      },
      () => this.getRecords(),
    );
  };

  clearInput(dropdownName) {
    const component = this;
    component.setState({ inputValue: '' }, () => {
      component.filterSearch('', dropdownName);
    });
  }

  filterSearch = (target, dropdownName) => {
    const component = this;
    component.setState({ inputValue: target });
    setTimeout(() => {
      const value = component.state.inputValue;
      const filter = value.toUpperCase();
      const div = document.getElementById(dropdownName);
      const a = div.getElementsByTagName('a');
      for (let i = 0; i < a.length; i += 1) {
        if (a[i].innerHTML.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = '';
        } else {
          a[i].style.display = 'none';
        }
      }
    }, 100);
  };

  generateCsvFile = async () => {
    const cpaName = getCpaName();
    this.setState({ loading: true });
    try {
      const reports = await Axios.get(ACCOUNT_REPORTS).then(
        (res) => res.data.data,
      );
      if (reports && reports.length > 0) {
        generatingAccountsCsvFile(
          reports,
          `${cpaName}_all_accounts_contacts.csv`,
        );
      }
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      AlertMessage('error', 'Something went wrong', 3000);
    }
  };

  handleSelectAll = () => {
    let { selectedCheckbox } = this.state;
    const { selectedAll, filter, pagination, sortBy, sortOrder } = this.state;
    const allAccountsButtonUrl = `/accounts?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    const key = allAccountsButtonUrl;
    const { accountslistdata } = this.props;
    const { listData } = accountslistdata[key];
    let isAllChecked = 0;
    let checked = false;
    listData.map((item) => {
      if (item.checked) {
        isAllChecked += 1;
      }
      return item;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    listData.map((item) => {
      const eachone = item;
      eachone.checked = checked;
      if (selectedCheckbox.length) {
        if (checked) {
          selectedCheckbox.push(eachone);
        } else {
          selectedCheckbox = removeItem(selectedCheckbox, eachone);
        }
      }
      return eachone;
    });
    if (!selectedCheckbox.length && checked) {
      selectedCheckbox = listData;
    }
    // this.props.updateCheckedAccountListData({ key, accountslistdata })
    this.setState({ selectedCheckbox }, () => {
      this.areAllSelected();
    });
  };

  areAllSelected() {
    let x = 0;
    const { pagination, sortBy, sortOrder, filter, componentContext } =
      this.state;
    const allAccountsButtonUrl = `/accounts?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    const key = allAccountsButtonUrl;
    const { selectedCheckbox } = this.state;
    const { accountslistdata } = this.props;
    const { listData } = accountslistdata[key];
    listData.map((e) => {
      if (e.checked) x += 1;
      return e;
    });
    const checkbox = document.getElementById('accSelectAll');
    if (x === listData.length && (x && listData.length) !== 0) {
      this.setState({ selectedAll: true });
      if (checkbox) checkbox.indeterminate = false;
    } else {
      this.setState({ selectedAll: false });
      if (checkbox) checkbox.indeterminate = true;
    }
    if (x === 0) {
      if (checkbox) checkbox.indeterminate = '';
    }

    // this for add to account functionality in emails page
    if (componentContext) {
      this.props.getSelectedRecords(selectedCheckbox.map((item) => item.id));
    }
  }

  checkboxAction = (item, id, index) => {
    const { pagination, sortBy, sortOrder, filter } = this.state;
    const allAccountsButtonUrl = `/accounts?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    const key = allAccountsButtonUrl;
    let { selectedCheckbox } = this.state;
    const { accountslistdata } = this.props;
    const { listData } = accountslistdata[key];
    if (listData[index].checked) {
      listData[index].checked = false;
    } else {
      listData[index].checked = true;
    }
    if (listData[index].checked) {
      selectedCheckbox.push(listData[index]);
    } else {
      selectedCheckbox = removeItem(selectedCheckbox, listData[index]);
    }
    // this.props.updateCheckedAccountListData({ key ,accountslistdata })
    this.setState({ selectedCheckbox }, () => {
      this.areAllSelected();
    });
  };

  massArchive = (confirmed) => {
    const { selectedCheckbox, filter, selectedFilterList, searchFieldText } =
      this.state;
    if (!confirmed) {
      this.setState({ confirmArchive: true });
    } else {
      // do mass archive
      const array = { ids: [] };
      selectedCheckbox.map((each) => {
        array.ids.push(each.id);
        return array;
      });
      this.setState({
        confirmArchive: false,
        loading: true,
      });
      const archiveData = { ids: array.ids, type: 'account' };
      const archiveAPI =
        filter === 'Inactive' ? ACCOUNTS_UNARCHIVE : ACCOUNTS_ARCHIVE;
      const request = Axios.post(archiveAPI, archiveData);
      this.setState({ loading: true });
      request
        .then((response) => {
          if (response.data.status === 200) {
            AlertMessage('success', response.data.message, 3000);
            this.setState(
              {
                selectedCheckbox: [],
                selectedAll: false,
              },
              () => {
                // this.props.pagination.messagePagination(messagePage);
                // this.props.actions.fetchMessages(this, '', 15, messagePage, this.state.apiUrl, this.state.filter);
                if (
                  (Object.keys(selectedFilterList).length !== 0 &&
                    Object.keys(selectedFilterList).length >= 1) ||
                  searchFieldText.length >= 3
                ) {
                  this.getSearchResults(searchFieldText, 'fromArchive');
                } else {
                  this.getRecords('fromArchive');
                }
              },
            );
          } else {
            AlertMessage('error', response.data.message, 3000);
          }
        })
        .catch(() => {
          AlertMessage('error', 'Some Error Occured', 3000);
        });
    }
  };

  render() {
    const {
      selectedCheckbox,
      filter,
      confirmArchive,
      selectedAll,
      activeTab,
      tabs,
      offset,
      listData,
      searchFieldText,
      csTeam,
      preferences: { entity_type, account_status, account_stage },
      selectedFilterList: {
        entity_type: selectedEntityType,
        account_status: selectedAccountStatus,
        account_stage: selectedAccountStage,
        employee_id: selectedCsTeam,
        is_restricted_account: selectedIsRestrictedAccount,
      },
      componentContext,
      filterInFocus,
    } = this.state;
    if (!this.state.isEmployee) {
      return (
        <div id="content">
          {this.state.loading ? <div id="loading" /> : null}
          <ClientAccounts
            listData={this.state.listData}
            showDetails={this.showDetails}
            handleSortBy={this.handleSortBy}
            state={this.state}
          />
        </div>
      );
    }
    const anyFiltersSelected = areFiltersSelected(
      this.state.selectedFilterList,
      searchFieldText,
    );
    return (
      <div
        className="AccountList"
        onClick={this.hideOpenFilters}
        role="presentation"
      >
        <header className="pb-0 pt-0">
          <HeaderTabs
            data={tabs}
            activeTab={activeTab}
            handleClick={this.changeActiveTab}
            pageName="account"
          />
        </header>
        {this.state.loading ? <div id="loading" /> : null}
        <div className="center-wrapper">
          <div className="filter-wrapper d-flex justify-content-between align-items-center">
            <div
              className={`filterPart d-flex ${
                this.state.role && this.state.role === 'firm_admin'
                  ? 'filterPart--restricted'
                  : ''
              }`}
            >
              <div className="form-group has-search">
                <span className="icon-zoom2 form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  id="account-search"
                  onChange={this.handleSearchFieldChange}
                  value={this.state.searchFieldText}
                  autoComplete="off"
                  data-testid="account__search_input"
                />
              </div>
              <div className="filterGroup d-flex flex-wrap bd-highlight has-search">
                <label
                  htmlFor="filter"
                  className="mt-2 mr-1 m-0"
                  style={{ paddingLeft: '10px' }}
                >
                  Filter by
                </label>
                <FilterComponent
                  filterList={entity_type}
                  handleClick={this.handleFilters}
                  filterName="ENTITY TYPE"
                  selectedFilterList={selectedEntityType}
                  isActive={
                    selectedEntityType &&
                    Object.keys(selectedEntityType[0]).length
                  }
                  setFilterInFocus={this.setFilterInFocus}
                  filterInFocus={filterInFocus}
                />
                <FilterComponent
                  filterList={account_status}
                  handleClick={this.handleFilters}
                  filterName="ACCOUNT STATUS"
                  selectedFilterList={selectedAccountStatus}
                  isActive={
                    selectedAccountStatus &&
                    Object.keys(selectedAccountStatus[0]).length
                  }
                  setFilterInFocus={this.setFilterInFocus}
                  filterInFocus={filterInFocus}
                />
                <FilterComponent
                  filterList={account_stage}
                  handleClick={this.handleFilters}
                  filterName="ACCOUNT STAGE"
                  selectedFilterList={selectedAccountStage}
                  isActive={
                    selectedAccountStage &&
                    Object.keys(selectedAccountStage[0]).length
                  }
                  setFilterInFocus={this.setFilterInFocus}
                  filterInFocus={filterInFocus}
                />
                {this.state.role && this.state.role === 'specialist' ? null : (
                  <div className="dropdown dropdown--small ml-2">
                    <button
                      type="button"
                      onMouseDown={() => this.clearInput('cstdropdown')}
                      onClick={(e) => {
                        this.setFilterInFocus('CS TEAM');
                        e.stopPropagation();
                      }}
                      className={
                        selectedCsTeam && Object.keys(selectedCsTeam[0]).length
                          ? 'btn btn-outline-light btn-sm dropdown-toggle active'
                          : 'btn btn-outline-light btn-sm dropdown-toggle'
                      }
                      data-testid="account__cs_team"
                    >
                      CS TEAM
                    </button>
                    <div
                      id="cstdropdown"
                      className={`dropdown-menu dropdown-menu-right ${
                        filterInFocus === 'CS TEAM' ? 'show' : ''
                      }`}
                    >
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control border-0 pl-2"
                          name="employee"
                          data-testid="csteam__search_input"
                          value={this.state.inputValue}
                          onChange={(e) =>
                            this.filterSearch(e.target.value, 'cstdropdown')
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                        <span className="input-group-addon border-left-0">
                          <i className="icon-zoom2" />
                        </span>
                      </div>
                      {csTeam.length ? (
                        csTeam.map((each) => (
                          <a
                            href="javascript:"
                            onClick={(e) =>
                              this.handleFilters(e, each, 'CS TEAM', 'filter')
                            }
                            className="dropdown-item"
                            key={each.id}
                            data-testid={`csteam__${each?.id}`}
                          >
                            <h5 className="fontSize mb-1">
                              {generateFullName(each)}
                            </h5>
                            <h4 className="subHeadline text-light letterSpace-1 text-uppercase font-weight-bold mb-0 ">
                              {this.getRoleOfCst(each)}
                            </h4>
                          </a>
                        ))
                      ) : (
                        <p className="dropdown-item mb-0 text-body">
                          Getting Employees
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {this.state.role && this.state.role === 'firm_admin' ? (
                  <div className="align-self-center ml-2">
                    <div className="d-flex pt-1">
                      <CheckboxField
                        id="restrictaccount"
                        name="restrictaccount"
                        label="Restricted Account?"
                        value={
                          selectedIsRestrictedAccount &&
                          Object.keys(selectedIsRestrictedAccount[0]).length
                        }
                        onChange={(e) =>
                          this.handleFilters(e, '', 'RESTRICTED ACCOUNT')
                        }
                        testid="account__restricted_account"
                      />
                    </div>
                  </div>
                ) : null}
                {anyFiltersSelected ? (
                  <div className="align-self-center ml-2 clearFilter">
                    <a href="javascript:void(0)" onClick={this.clearFilters}>
                      <i className="icon-close2 label-small" />
                      Clear All
                    </a>
                  </div>
                ) : null}
                <div className="select-filter-list w-100">
                  <ShowFiterTags
                    selectedTags={selectedEntityType}
                    handleClick={this.handleFilters}
                    filterName="ENTITY TYPE"
                  />
                  <ShowFiterTags
                    selectedTags={selectedAccountStatus}
                    handleClick={this.handleFilters}
                    filterName="ACCOUNT STATUS"
                  />
                  <ShowFiterTags
                    selectedTags={selectedAccountStage}
                    handleClick={this.handleFilters}
                    filterName="ACCOUNT STAGE"
                  />
                  {this.state.role &&
                  this.state.role === 'specialist' ? null : (
                    <ShowFiterTags
                      selectedTags={selectedCsTeam}
                      handleClick={this.handleFilters}
                      filterName="CS TEAM"
                    />
                  )}
                  {this.state.role && this.state.role === 'firm_admin' ? (
                    <ShowFiterTags
                      selectedTags={selectedIsRestrictedAccount}
                      handleClick={this.handleFilters}
                      filterName="RESTRICTED ACCOUNT"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            {componentContext ? null : (
              <div className="FilterHeader--Action align-self-start pt-1">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-light mr-2"
                  onClick={this.generateCsvFile}
                  data-testid="account__download_btn"
                >
                  <i className="icon-download2 p-0" aria-hidden="true" />
                </button>
              </div>
            )}
            {componentContext ? null : (
              <div className="FilterHeader--Action align-self-start d-flex ml-2">
                {filter === 'all' ||
                filter === 'Prospect' ||
                filter === 'Client' ||
                filter === 'Inactive' ? (
                  <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={() => this.massArchive(false)}
                    disabled={!selectedCheckbox.length}
                    data-testid="account__archive_btn"
                  >
                    <i className="icon-archive" />{' '}
                    {filter === 'Inactive' ? 'Unarchive' : 'Archive'}
                  </button>
                ) : null}
              </div>
            )}
            <ConfirmationModal
              isOpen={confirmArchive}
              noBtnClick={() => this.setState({ confirmArchive: false })}
              headerText="Attention!"
              messageText={`${
                filter !== 'Inactive'
                  ? 'The account deactivation process will close all related open tasks and remove contact relationships. Proceed?'
                  : `You are about to unarchive ${selectedCheckbox.length} ${
                      selectedCheckbox.length > 1 ? 'accounts.' : 'account.'
                    }`
              }`}
              yesBtnClick={() => this.massArchive(true)}
              yesBtnText="Yes"
              noBtnText="No"
            />
            {(this.state.role && this.state.role === 'specialist') ||
            componentContext ? null : (
              <div className="FilterHeader--Action align-self-start">
                <Link
                  to="/account/new"
                  className="text-right btn btn-primary"
                  data-testid="account__create_account"
                >
                  <i>+</i> Account
                </Link>
              </div>
            )}
          </div>
          {(listData === undefined || listData === null) &&
          !anyFiltersSelected ? (
            <NoRecords />
          ) : (
            <section onClick={this.hideOpenFilters} role="presentation">
              <div className="tab-content">
                <div className="tab-pane active">
                  <div id="accounts-list" className="tableWrap">
                    <div className="tRow tRow--head">
                      <div className="row">
                        <span className="checkbox checkbox-primary check-container mx-2">
                          <Label htmlFor="accSelectAll">
                            <Input
                              name="accSelectAll"
                              id="accSelectAll"
                              type="checkbox"
                              checked={selectedAll}
                              key={this.state.pagination}
                              onChange={this.handleSelectAll}
                              data-testid="account__all_checkbox"
                            />
                            <i className="checkmark" />
                          </Label>
                        </span>
                        {accountPageTableHeaders.map((each) => (
                          <div
                            key={each.value}
                            className={
                              sortingIcon(
                                this.state.sortBy,
                                each.value,
                                this.state.sortOrder,
                                'className',
                              ) + each.className
                            }
                            onClick={() =>
                              each.isSortable
                                ? this.handleSortBy(each.value)
                                : null
                            }
                          >
                            <span className="posRel">
                              {each.label}
                              <i
                                className={sortingIcon(
                                  this.state.sortBy,
                                  each.value,
                                  this.state.sortOrder,
                                )}
                              />
                            </span>
                          </div>
                        ))}
                        <div className="col thCol" />
                      </div>
                    </div>
                    <div className="tRow tRow--body">
                      {(listData === undefined ||
                        listData === null ||
                        listData.length === 0) &&
                      anyFiltersSelected ? (
                        <div className="row emptyTr">
                          <div className="emptyBox">
                            <div className="onlyText">
                              <p>No Records Found</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {listData && listData !== null && listData.length
                        ? listData.map((each, index) => (
                          <div key={each.id} className="tdBtn row">
                            <span
                              className="checkbox checkbox-primary check-container mx-2"
                              style={{ top: '2px' }}
                            >
                              <Label htmlFor={`chck ${each.id}`}>
                                <Input
                                  name="accSelectSingle"
                                  id={`chck ${each.id}`}
                                  key={`chck ${each.id}`}
                                  checked={
                                    each.checked ? each.checked : false
                                  }
                                  type="checkbox"
                                  data-testid={`account__checkbox_${each?.id}`}
                                  onChange={() =>
                                    this.checkboxAction(each, each.id, index)
                                  }
                                />
                                <i className="checkmark" />
                              </Label>
                            </span>
                            {!componentContext ? (
                              <>
                                <Link
                                  className="col-3 tdCol text-body"
                                  to={this.showDetails(each.id)}
                                >
                                  <span>{each.name}</span>
                                </Link>
                                <Link
                                  className="col-2 tdCol text-body"
                                  to={this.showDetails(each.id)}
                                >
                                  <span>
                                    {each.external_account_id !== null
                                      ? each.external_account_id
                                      : '-'}
                                  </span>
                                </Link>
                                <Link
                                  className="col-2 tdCol text-body"
                                  to={this.showDetails(each.id)}
                                >
                                  <span>
                                    {each.entity_type !== null
                                      ? each.entity_type
                                      : '-'}
                                  </span>
                                </Link>
                                <Link
                                  className="col tdCol text-body"
                                  to={this.showDetails(each.id)}
                                >
                                  <span>
                                    {each.status !== null ? each.status : '-'}
                                  </span>
                                  <br />
                                </Link>
                                <Link
                                  className="col tdCol text-body"
                                  to={this.showDetails(each.id)}
                                >
                                  <RatingComponent rating={each.rating} />
                                </Link>
                              </>
                            ) : (
                              <>
                                <div
                                  className="col-3 tdCol text-body"
                                  onClick={() =>
                                    this.checkboxAction(each, each.id, index)
                                  }
                                >
                                  <span>{each.name}</span>
                                </div>
                                <div
                                  className="col-2 tdCol text-body"
                                  onClick={() =>
                                    this.checkboxAction(each, each.id, index)
                                  }
                                >
                                  <span>
                                    {each.external_account_id !== null
                                      ? each.external_account_id
                                      : '-'}
                                  </span>
                                </div>
                                <div
                                  className="col-2 tdCol text-body"
                                  onClick={() =>
                                    this.checkboxAction(each, each.id, index)
                                  }
                                >
                                  <span>
                                    {each.entity_type !== null
                                      ? each.entity_type
                                      : '-'}
                                  </span>
                                </div>
                                <div
                                  className="col tdCol text-body"
                                  onClick={() =>
                                    this.checkboxAction(each, each.id, index)
                                  }
                                >
                                  <span>
                                    {each.status !== null ? each.status : '-'}
                                  </span>
                                  <br />
                                </div>
                                <div
                                  className="col tdCol text-body"
                                  onClick={() =>
                                    this.checkboxAction(each, each.id, index)
                                  }
                                >
                                  <RatingComponent rating={each.rating} />
                                </div>
                              </>
                            )}
                            <div className="col tdCol text-right d-flex justify-content-end">
                              {each.status !== null &&
                                each.status.toLowerCase() !== 'inactive' &&
                                !componentContext ? (
                                <TooltipItem
                                  position="top"
                                  tooltipType="button"
                                  key={`edit${each.id}`}
                                  text="Edit"
                                  btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                                  clickAction={() =>
                                    this.showDetails(each.id, 'edit')
                                  }
                                  id={`edit${each.id}`}
                                  iconn="icon-edit"
                                />
                              ) : null}
                              {each.is_restricted_account ? (
                                <TooltipItem
                                  position="top"
                                  tooltipType="button"
                                  key={`restrict${each.id}`}
                                  text="Restricted"
                                  btnClass="btn-sm btn--onlyicon btn-link cursor-default px-1"
                                  id={`restrict${each.id}`}
                                  iconn="icon-cancel-agreement text-danger"
                                />
                              ) : null}
                            </div>
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                  {this.state.totalRecords ? (
                    <Pagination
                      currentPage={this.state.pagination}
                      totalPages={this.state.totalPages}
                      totalRecords={this.state.totalRecords}
                      handlePageChange={this.handlePageChange}
                      offset={offset[0]}
                      off={offset[1]}
                      pageName="account"
                    />
                  ) : null}
                </div>
              </div>
            </section>
          )}
        </div>
        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            isOpen
            messageText="Attention: The account deactivation process will close all open tasks related. Proceed?"
            yesBtnText="Proceed"
            noBtnText="Cancel"
            yesBtnClick={this.deleteRecord}
            noBtnClick={this.showDeleteConfirmationModal}
          />
        ) : null}
      </div>
    );
  }
}

const FilterComponent = (props) => {
  const {
    filterList,
    filterName,
    handleClick,
    selectedFilterList,
    isActive,
    setFilterInFocus,
    filterInFocus,
  } = props;

  const onFilterNameClick = (e) => {
    e.stopPropagation();
    const isCurrentSelectedFilter = filterName === filterInFocus;
    if (isCurrentSelectedFilter) {
      setFilterInFocus('');
    } else {
      setFilterInFocus(filterName);
    }
  };

  const onOptionClick = (e, item) => {
    handleClick(e, item, filterName);
  };

  const showOptionsClass = filterName === filterInFocus ? 'show' : '';

  return (
    <div
      className={`dropdown ${showOptionsClass}`}
      style={{ marginLeft: '10px' }}
    >
      <button
        type="button"
        className={
          isActive
            ? 'btn btn-outline-light btn-sm dropdown-toggle active'
            : 'btn btn-outline-light btn-sm dropdown-toggle'
        }
        onClick={onFilterNameClick}
        data-testid={`account__${filterName?.toLowerCase()?.replace(' ', '_')}`}
      >
        {filterName}
      </button>
      <div className={`dropdown-menu ${showOptionsClass}`}>
        {filterList.map((item, i) => (
          <span
            key={`accountFilter_${filterName + i}`}
            className="dropdown-item"
          >
            <div className="checkbox checkbox-primary check-container">
              <label htmlFor={item.key} className="check-container">
                <span className="pl-2">{item.label}</span>
                <input
                  checked={
                    selectedFilterList &&
                    selectedFilterList.length &&
                    selectedFilterList[0][item.key]
                      ? selectedFilterList[0][item.key]
                      : false
                  }
                  onChange={(e) => onOptionClick(e, item)}
                  type="checkbox"
                  id={item.key}
                  name={item.key}
                  data-testid={`account__${item?.key
                    ?.toLowerCase()
                    ?.replace(' ', '_')}`}
                />
                <span className="checkmark" />
              </label>
            </div>
          </span>
        ))}
      </div>
    </div>
  );
};

const ShowFiterTags = (props) => {
  const { selectedTags, handleClick, filterName } = props;
  if (selectedTags && selectedTags.length) {
    return Object.keys(selectedTags[0]).map((each) => (
      <div key={each} className="badge-filter">
        {each}
        <a href="javascript:void(0)">
          <i
            tabIndex={0}
            role="button"
            className="icon-close2"
            onMouseDown={(e) => handleClick(e, { key: each }, filterName)}
          />
        </a>
      </div>
    ));
  }
  return null;
};

const mapStateToProps = (state) => ({
  accountslistdata: state.accountReducer.accountslistdata,
});

const mapDispatchToProps = (dispatch) => ({
  saveAccountListData: (data) => dispatch(saveAccountListData(data)),
  updateCheckedAccountListData: (data) =>
    dispatch(updateCheckedAccountListData(data)),
});

export default withStorageData(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountList)),
);
