import { Box, Stack, Typography } from '@mui/material';
import { REQUESTS_PAGE_TITLES } from 'components/Requests/requests.constants';
import { useLayoutEffect } from 'react';

import draftsMoved from 'images/empty-state-drafts-moved.svg';

export const NewDraftWorkflowsView = () => {
  useLayoutEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.draftsWorkflows;
  }, []);

  return (
    <Box height="100%">
      <Stack
        spacing={2}
        direction="column"
        justifyContent="center"
        textAlign="center"
        height="100%"
      >
        <Box>
          <img src={draftsMoved} alt="Drafts in progress" width={180} />
        </Box>
        <Typography variant="h2">Drafts In Progress have moved</Typography>
        <Box>
          <Typography variant="body1">
            Click the “+ Request” button in the upper right,
          </Typography>
          <Typography variant="body1">
            then select “Pick up where you left off.”
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
