import React, { useEffect, useState } from 'react';
import { setAuthSession } from 'auth/session';
import LoaderDefault from 'common/LoaderDefault';
import {
  usePatchMfaV5WithTemporaryAuthToken,
  usePostApiV5AuthCpaAuthorizationWithTemporaryAuthToken,
  usePostMfaV5WithTemporaryAuthToken,
} from 'components/Profile/mfa-fetch-utils';
import MultiFactorAuthenticationSetup from 'components/Profile/MultiFactorAuthenticationSetup';
import { Stack } from '@mui/material';
import { parseErrorMessage } from 'utilities/parseErrorMessage';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom-latest';
import { MFASuccessState } from './components';
import {
  mfaSetupFormValidationResolver,
  MfaSetupValidationFormInterface,
} from './components/validate-mfa-form-resolver';

type MultiFactorAuthenticateLoginSetupProcessProps = {
  authToken: string;
  cpaId: string;
  loginType: string;
  navigateToOnSuccess?: string;
};

export default function MultiFactorAuthenticateLoginSetupProcess({
  authToken,
  cpaId,
  loginType,
  navigateToOnSuccess,
}: MultiFactorAuthenticateLoginSetupProcessProps) {
  const navigate = useNavigate();
  const [showInitialDescription, setShowInitialDescription] = useState(true);
  const [showQrCode, setShowQrCode] = useState(false);
  const [showSuccessState, setShowSuccessState] = useState(false);

  const formCtx = useForm<MfaSetupValidationFormInterface>({
    resolver: mfaSetupFormValidationResolver,
  });

  const {
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors: formErrors },
  } = formCtx;

  const {
    mutateAsync: authorizeCpa,
    isLoading: isMutatingCpaAuth,
    error: isMutatingCpaError,
  } = usePostApiV5AuthCpaAuthorizationWithTemporaryAuthToken();

  const {
    mutateAsync: generateQrCode,
    data: qrCodeResponse,
    isLoading: isGeneratingQrCode,
    error: isGeneratingQrCodeError,
  } = usePostMfaV5WithTemporaryAuthToken();

  const {
    mutateAsync: updateUserMfaSettings,
    isLoading: isSettingUpMfa,
    error: isSettingUpMfaError,
  } = usePatchMfaV5WithTemporaryAuthToken();

  const error =
    isSettingUpMfaError || isGeneratingQrCodeError || isMutatingCpaError;

  useEffect(() => {
    generateQrCode({ temporary_auth_token: authToken });
  }, [generateQrCode, authToken]);

  const handleVerificationCodeSubmit = async (
    data: MfaSetupValidationFormInterface,
  ) => {
    try {
      const mfaSetupRes = await updateUserMfaSettings({
        two_factor_code: data.verificationCode,
        temporary_auth_token: authToken,
      });
      reset();
      const authRes = await authorizeCpa({
        cpa_uuid: cpaId,
        login_type: loginType,
        temporary_auth_token: mfaSetupRes.auth_token!,
      });

      setAuthSession({ auth_token: authRes.auth_token });
      setShowQrCode(false);
      setShowSuccessState(true);
    } catch (e) {
      setError('verificationCode', {
        message: parseErrorMessage((e as AxiosError)?.response?.data),
      });
    }
  };

  const isLoading = isSettingUpMfa || isGeneratingQrCode || isMutatingCpaAuth;

  return (
    <Stack textAlign="left">
      {isLoading && <LoaderDefault />}
      <MultiFactorAuthenticationSetup
        isRequired
        isOpen={showInitialDescription}
        data={{ errors: { verificationCode: '' } }}
        headerText="Protect your account"
        yesBtnClick={() => {
          setShowInitialDescription(false);
          setShowQrCode(true);
        }}
        yesBtnText="Start setup"
      />
      <MultiFactorAuthenticationSetup
        isRequired
        error={
          formErrors.verificationCode?.message ||
          error?.response?.data?.errors?.[0]?.message ||
          ''
        }
        className="VerifyModal"
        isOpen={showQrCode}
        headerText="Set up multi-factor authentication"
        data={{ ...qrCodeResponse, errors: {} }}
        handleVerificationCode={(e) => {
          setValue('verificationCode', e.target.value);
        }}
        yesBtnClick={handleSubmit(handleVerificationCodeSubmit)}
        yesBtnText="Verify"
      />
      {showSuccessState && (
        <MFASuccessState
          onDelayedSuccess={() => {
            navigate(navigateToOnSuccess || '/dashboard');
          }}
        />
      )}
    </Stack>
  );
}
