import { useCallback, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom-latest';
import axios from 'axios';
import { apiClientV5 } from 'api/apiClientV5';
import { containsErrorOfType } from 'utilities/containsErrorOfType/containsErrorOfType';

export const RequestsInterceptors = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handle403Errors = useCallback(
    (error: any) => {
      const { pathname } = location;
      if (
        error.response.status === 403 &&
        containsErrorOfType(error, 'firm_requires_mfa') &&
        !pathname.includes('/enforced-mfa-configuration')
      ) {
        navigate(
          `/enforced-mfa-configuration?callbackUrl=${encodeURIComponent(
            pathname,
          )}`,
        );
      }
      return Promise.reject(error);
    },
    [location, navigate],
  );

  useEffect(() => {
    const responseInterceptorV3 = axios.interceptors.response.use(
      (response) => response,
      handle403Errors,
    );
    const responseInterceptorV5 = apiClientV5.interceptors.response.use(
      (response) => response,
      handle403Errors,
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptorV3);
      apiClientV5.interceptors.response.eject(responseInterceptorV5);
    };
  }, [handle403Errors]);

  return null;
};
