import { Close } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  TableContainer,
  styled,
  tableCellClasses,
  tablePaginationClasses,
  tableRowClasses,
} from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

export const CenterWrapperBox = styled(Box)(() => ({
  padding: '1.5rem 2.3rem',
}));

interface ModalCloseProps {
  onClick: () => void;
}

export const ModalClose = ({ onClick }: ModalCloseProps) => (
  <IconButton
    aria-label="close"
    onClick={onClick}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <Close />
  </IconButton>
);

interface FullSizeDrawerProps {
  open: boolean;
  zIndex?: number;
  children: React.ReactNode;
  onClose?: () => void;
  overflow?: 'auto' | 'hidden';
}

export const FullSizeDrawer = ({
  onClose,
  open,
  children,
  zIndex,
  overflow = 'auto',
}: FullSizeDrawerProps) => (
  <Drawer
    open={open}
    anchor="right"
    onClose={onClose}
    PaperProps={{ sx: { overflow } }}
    sx={{ zIndex }}
  >
    <Box sx={{ width: 'calc(100vw - 200px)' }}>
      {onClose && <ModalClose onClick={onClose} />}
      {children}
    </Box>
  </Drawer>
);

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  [`& .${tableRowClasses.root}`]: {
    height: '72px',
  },
  [`& .${tableCellClasses.root}`]: {
    padding: ' 10px',
    border: 'none',
    [`&.${tableCellClasses.head}`]: {
      ...theme.typography.body2,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontWeight: 400,
      ...theme.typography.body1,
      letterSpacing: 'inherit',
      textTransform: 'none',
      p: {
        maxWidth: '15rem',
      },
    },
  },
}));

export const RequestsDataGridStyled = styled(DataGrid)<{
  hasFilters?: boolean;
}>(({ theme, ...props }) => ({
  textTransform: 'none',
  fontWeight: 400,
  ...theme.typography.body1,
  letterSpacing: 'inherit',
  color: theme.palette.text.primary,
  p: {
    maxWidth: '15rem',
  },
  height: `calc(100vh - ${props.hasFilters ? '162px' : '92px'})`,
  minWidth: '800px',
  [`& .${gridClasses.virtualScroller}`]: {
    overflowX: 'hidden',
  },
  [`& .${gridClasses.overlayWrapper}`]: {
    height: '500px',
  },
  [`& .${gridClasses.columnHeaders}`]: {
    backgroundColor: theme.palette.grey[50],
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    ...theme.typography.body2,
    fontweight: 700,
  },
  [`& .${gridClasses.row}`]: {
    maxHeight: 'none !important',
    cursor: 'pointer',
  },
  [`& .${gridClasses.footerContainer}`]: {
    backgroundColor: theme.palette.grey[50],
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  [`& .${tablePaginationClasses.displayedRows}`]: {
    marginTop: '1rem',
  },
  [`& .${tablePaginationClasses.selectLabel}`]: {
    marginTop: '1rem',
  },
}));
