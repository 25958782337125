import { WorkflowRequestFragment } from 'gql/graphql';
import { Header, HeaderMenuItem } from 'ui/Header/Header';
import { Edit, Save, FileDownload } from '@mui/icons-material';
import { PrintIcon } from 'common/Icons';
import { useChangeRequestDueDate } from 'components/Requests/hooks/change-request-due-date/change-request-due-date';
import { useHeaderMenuHook } from 'components/Requests/hooks/header-menu-hook';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

type WorkflowViewHeaderProps = {
  title?: string;
  workflowRequest?: WorkflowRequestFragment | null;
  isDispatch: boolean;
  promptEdit: boolean;
  disableInputState: boolean;
  setDisableInputState: (disableInputState: boolean) => void;
  onGoBack: () => void;
};

export const WorkflowViewHeader = ({
  workflowRequest,
  isDispatch,
  promptEdit,
  disableInputState,
  setDisableInputState,
  onGoBack,
  title,
}: WorkflowViewHeaderProps) => {
  const isTaxDeliveryWorkflow =
    workflowRequest?.workflow?.type === 'TaxDeliveryWorkflow';

  const canPrintPdf = !isDispatch && !isTaxDeliveryWorkflow;
  const canExportZip = !isTaxDeliveryWorkflow;

  const { handleExportClicked, toggleDisplayPdf, PrintPDF } = useHeaderMenuHook(
    {
      title,
      workflowRequestId: workflowRequest?.id,
    },
  );
  const [copiedText, copy, clipboardSupported] = useCopyToClipboard();
  const { changeDueDateHeaderItem, ChangeDueDateDialog } =
    useChangeRequestDueDate(workflowRequest as WorkflowRequestFragment);

  const buttonItemsList =
    !isDispatch && clipboardSupported!
      ? [
          {
            label: copiedText ? ' COPIED' : 'COPY LINK',
            tooltip: copiedText
              ? 'URL was copied to the clipboard'
              : 'Copy URL to clipboard',
            props: {
              onClick: () => {
                copy(window.location.href);
              },
              variant: 'outlined' as 'outlined',
            },
          },
        ]
      : [];

  const createMenuList = () => {
    const menuList: Array<HeaderMenuItem> = [];

    if (promptEdit) {
      const dueDateHeaderItem = changeDueDateHeaderItem();
      if (dueDateHeaderItem) menuList.push(dueDateHeaderItem);

      // `disableInputState` controls the "Edit Responses" vs "Save" menu items
      // as well as using the callback to enable/disable the input fields
      if (disableInputState) {
        // nested if to prevent the "Save" item from showing up
        if (workflowRequest?.workflow?.type !== 'TaxDeliveryWorkflow') {
          menuList.push({
            icon: <Edit />,
            label: 'Edit Responses',
            'data-print-id': 'editWorkflowButton',
            props: {
              onClick: () => setDisableInputState(!disableInputState),
            },
          });
        }
      } else {
        menuList.push({
          icon: <Save />,
          label: 'Save',
          'data-print-id': 'editWorkflowButton',
          props: {
            onClick: () => setDisableInputState(!disableInputState),
          },
        });
      }

      if (canExportZip)
        menuList.push({
          icon: <FileDownload />,
          label: 'Export Zip',
          'data-print-id': 'exportWorkflowButton',
          props: {
            onClick: handleExportClicked,
          },
        });
    }

    if (canPrintPdf)
      menuList.push({
        icon: <PrintIcon />,
        label: 'Print PDF',
        'data-print-id': 'exportWorkflowButton',
        props: {
          onClick: () => toggleDisplayPdf(),
        },
      });

    return menuList;
  };

  return (
    <>
      <Header
        onBack={onGoBack}
        title={title}
        buttonItems={buttonItemsList}
        menuItems={createMenuList()}
      />
      <ChangeDueDateDialog />
      <PrintPDF />
    </>
  );
};
