import { MFADisableSettings } from './components/MFADisableSettings/MFADisableSettings';
import MFARecoveryCodes from './components/MFARecoveryCodes/MFARecoveryCodes';
import MFAQrCodeAndOtpSecret from './components/MFAQrCodeAndOtpSecret/MFAQrCodeAndOtpSecret';
import MFAIntroDescription from './components/MFAIntroDescription/MFAIntroDescription';

type MultiFactorAuthenticationSetupProps = {
  className?: string;
  data?: {
    qr_code?: string;
    unconfirmed_otp_secret?: string;
    verificationCode?: string;
    errors?: { verificationCode?: string };
    recoveryCodes?: { value: string }[];
    mfaEnabled?: boolean;
  };
  mfaHandleDisable?: () => void;
  generateRecoveryCodes?: () => void;
  copyCodes?: () => void;
  headerText?: string;
  handleVerificationCode?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  noBtnText?: string;
  noBtnClick?: () => void;
  yesBtnText: string;
  yesBtnClick: () => void;
  isOpen: boolean;
  error?: string;
  isRequired: boolean;
};

export default function MultiFactorAuthenticationSetup({
  className,
  data,
  mfaHandleDisable = () => {},
  generateRecoveryCodes = () => {},
  copyCodes = () => {},
  handleVerificationCode = () => {},
  headerText,
  noBtnText,
  noBtnClick = () => {},
  yesBtnText,
  yesBtnClick,
  isOpen,
  error,
  isRequired,
}: MultiFactorAuthenticationSetupProps) {
  if (!isOpen) return null;

  let doneVerification = false;
  let recoveryModal = false;
  let verifyModal = false;
  if (className === 'DoneVerification') {
    doneVerification = true;
  } else if (className === 'RecoveryModal') {
    recoveryModal = true;
  } else if (className === 'VerifyModal') {
    verifyModal = true;
  }

  const recoveryCodes = data?.recoveryCodes?.length
    ? data?.recoveryCodes.map((item) => (
        <div key={item.value}>{item.value}</div>
      ))
    : [];

  const showDisableSettings =
    doneVerification && !recoveryModal && !verifyModal;
  const showRecoveryCodes = recoveryModal && !doneVerification && !verifyModal;
  const showQrCodeAndOtpSecret =
    verifyModal && !recoveryModal && !doneVerification;

  if (showDisableSettings) {
    return (
      <MFADisableSettings
        data={data || {}}
        mfaHandleDisable={mfaHandleDisable}
        generateRecoveryCodes={generateRecoveryCodes}
        noBtnText={noBtnText}
        noBtnClick={noBtnClick}
        yesBtnText={yesBtnText}
        yesBtnClick={yesBtnClick}
      />
    );
  }

  if (showRecoveryCodes) {
    return (
      <MFARecoveryCodes
        copyCodes={copyCodes}
        recoveryCodes={recoveryCodes}
        noBtnText={noBtnText}
        noBtnClick={noBtnClick}
        yesBtnText={yesBtnText}
        yesBtnClick={yesBtnClick}
      />
    );
  }

  if (showQrCodeAndOtpSecret) {
    return (
      <MFAQrCodeAndOtpSecret
        headerText={headerText}
        data={data || {}}
        error={error || ''}
        handleVerificationCode={handleVerificationCode}
        noBtnText={noBtnText}
        noBtnClick={noBtnClick}
        yesBtnText={yesBtnText}
        yesBtnClick={yesBtnClick}
      />
    );
  }

  return (
    <MFAIntroDescription
      headerText={headerText}
      isRequired={isRequired}
      noBtnText={noBtnText}
      noBtnClick={noBtnClick}
      yesBtnText={yesBtnText}
      yesBtnClick={yesBtnClick}
    />
  );
}
