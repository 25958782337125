import { Button } from 'reactstrap';

export default function MultiFactorAuthenticationSetupButtons({
  noBtnClick,
  noBtnText,
  yesBtnClick,
  yesBtnText,
  buttonType = 'button',
}) {
  return (
    <div className="row">
      <div className="col-12 justify-content-end formbtn justify-content-end">
        <div className="btn-wrap btn--leftspace">
          {noBtnText ? (
            <Button className="btn btn-outline-light" onClick={noBtnClick}>
              {noBtnText}
            </Button>
          ) : null}
          {yesBtnText ? (
            <Button
              type={buttonType}
              className="btn btn-primary"
              onClick={yesBtnClick}
            >
              {yesBtnText}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
