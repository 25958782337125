import {
  AUTHORIZE_PAYMENT_FOR_NEW_RECURRING_INVOICES,
  BLUE,
  MAKE_PAYMENT_NOW,
  RED,
  UPDATE_PAYMENT_NOW,
  YOUR_ACCOUNT_IS_OVERDUE,
  YOUR_PAYMENT_HAS_FAILED,
} from 'constants/constants';
import { isClient } from 'utilities/utils';

const BILLING_INVOICES = '/billing/invoices/';
const BILLING_RECURRING = '/billing/recurring/';
const HAS_FAILED_INVOICES = 'has_failed_invoices';
const HAS_OVERDUE_INVOICES = 'has_overdue_invoices';
const HAS_PAYMENT_AUTHORIZATION_REQUESTS = 'has_payment_authorization_requests';

export const pickInvoiceAlert = (alertMessage, isAccountOwner) => {
  const isUserClient = isClient();
  const empty = {};
  if (isUserClient) {
    switch (alertMessage) {
      case HAS_FAILED_INVOICES:
        return {
          text: YOUR_PAYMENT_HAS_FAILED,
          subText: UPDATE_PAYMENT_NOW,
          pageLink: BILLING_INVOICES,
          type: RED,
        };
      case HAS_OVERDUE_INVOICES:
        return {
          text: YOUR_ACCOUNT_IS_OVERDUE,
          subText: MAKE_PAYMENT_NOW,
          pageLink: BILLING_INVOICES,
          type: RED,
        };
      case HAS_PAYMENT_AUTHORIZATION_REQUESTS:
        return isAccountOwner
          ? {
              subText: AUTHORIZE_PAYMENT_FOR_NEW_RECURRING_INVOICES,
              type: BLUE,
              pageLink: BILLING_RECURRING,
            }
          : empty;
      default:
        return empty;
    }
  } else {
    return null;
  }
};

export const alertsSortedByPriority = [
  HAS_FAILED_INVOICES,
  HAS_OVERDUE_INVOICES,
  HAS_PAYMENT_AUTHORIZATION_REQUESTS,
];
