import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import mixpanelBrowser, { Mixpanel } from 'mixpanel-browser';
import { useGetHome } from 'hooks/sms-hooks';
import { getAuthSession } from 'auth/session';
import { APP_VERSION } from 'constants/constants';

export type MixpanelContextType = {
  mixpanel?: Mixpanel;
};

export const MixpanelContext = createContext<MixpanelContextType>({});

export type MixpanelProviderProps = {
  children?: React.ReactNode;
};

/**
 * Mixpanel instance provider
 */
export const MixpanelProvider = ({ children }: MixpanelProviderProps) => {
  const authSession = getAuthSession();
  const isAuthenticated = !!authSession;
  const { data: homeData } = useGetHome(isAuthenticated);

  // Initialize Mixpanel
  const mixpanelProjectToken = process.env.REACT_APP_MIXPANEL_KEY;
  const mixpanel = mixpanelProjectToken
    ? mixpanelBrowser.init(
        mixpanelProjectToken,
        {
          // NOTE: Add mixpanel config options here as needed
        },
        'app-mp-instance',
      )
    : undefined;
  const ctx = useMemo(() => ({ mixpanel }), [mixpanel]);

  /**
   * Handle registering default properties
   */
  const registerSuperProperties = useCallback(() => {
    if (mixpanel) {
      mixpanel.register({
        app: 'liscio-refactor',
        version: APP_VERSION,
        platform: 'web',
      });
    }
  }, [mixpanel]);

  useEffect(() => {
    if (mixpanel) {
      if (isAuthenticated && homeData) {
        registerSuperProperties();
        mixpanel.identify(homeData.data.current_cpa_user_uuid);
        mixpanel.people.set({
          $id: homeData.data.current_cpa_user_uuid,
          cpa_id: homeData.data.cpa_uuid,
          user_role: homeData.data.userpilot_data.user_role,
          firm_name: homeData.data.userpilot_data.firm_name,
          texting_enabled: !!homeData.data.userpilot_data.is_texting_enabled,
          texts_sent: homeData.data.userpilot_data.texts_sent,
        });
      } else if (!isAuthenticated) {
        mixpanel?.reset();
        registerSuperProperties();
      }
    }
  }, [mixpanel, registerSuperProperties, isAuthenticated, homeData]);

  // Attach mixpanel to window for backwards compatibility
  (window as any).mixpanel = mixpanel;

  return (
    <MixpanelContext.Provider value={ctx}>{children}</MixpanelContext.Provider>
  );
};

export const useMixpanel = () => {
  const { mixpanel } = useContext(MixpanelContext);
  return mixpanel;
};
