import React, { useLayoutEffect } from 'react';
import MultiFactorAuthenticateLoginSetupProcess from 'components/Profile/MultiFactorAuthenticateLoginSetupProcess/MultiFactorAuthenticateLoginSetupProcess';
import { useLocation } from 'react-router-dom-latest';
import { getStorageData } from 'utilities/utils';
import {
  destroyCurrentSessionAndGoToLogin,
  getAuthSession,
} from 'auth/session';
import { LoginPageLayout } from './components';

export const EnforcedMfaConfiguration = () => {
  const location = useLocation();
  const homeData = getStorageData();
  const selectedCpaUUID = homeData?.cpa_uuid;
  const authToken = getAuthSession()?.auth_token || '';
  const urlParams = new URLSearchParams(location.search);
  const callbackUrlInUrl = urlParams.get('callbackUrl');

  useLayoutEffect(() => {
    if (!selectedCpaUUID) {
      destroyCurrentSessionAndGoToLogin();
    }
  }, [selectedCpaUUID]);

  return (
    <div id="page-wrapper">
      <LoginPageLayout showLogo={false}>
        <MultiFactorAuthenticateLoginSetupProcess
          cpaId={selectedCpaUUID}
          authToken={authToken}
          loginType="password"
          navigateToOnSuccess={callbackUrlInUrl || ''}
        />
      </LoginPageLayout>
    </div>
  );
};
export default EnforcedMfaConfiguration;
