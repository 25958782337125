import React from 'react';

import {
  DotLottieReact,
  DotLottieReactProps,
} from '@lottiefiles/dotlottie-react';

export type SuccessLottieAnimationProps = Omit<
  DotLottieReactProps,
  'src' | 'data'
>;

export const SuccessLottieAnimation = (props: SuccessLottieAnimationProps) => (
  <DotLottieReact
    {...props}
    src={`${process.env.PUBLIC_URL}/Checkmark-MFA.lottie`}
    autoplay
  />
);

export default SuccessLottieAnimation;
