import React, { ReactNode } from 'react';

import MultiFactorAuthenticationSetupButtons from 'components/Profile/MultiFactorAuthenticationSetup/components/MultiFactorAuthenticationSetupButtons';
import Scrollbars from 'react-custom-scrollbars-2';
import { Button } from 'reactstrap';

interface MFARecoveryCodesProps {
  copyCodes: () => void;
  recoveryCodes: ReactNode[];
  noBtnText?: string;
  noBtnClick: () => void;
  yesBtnText: string;
  yesBtnClick: () => void;
}

const MFARecoveryCodes: React.FC<MFARecoveryCodesProps> = ({
  copyCodes,
  recoveryCodes,
  noBtnText,
  noBtnClick,
  yesBtnText,
  yesBtnClick,
}) => (
  <div className="row">
    <div className="col-12 pb-3">
      <label className="text-body font-weight-normal mb-0 mt-3">
        Make sure to{' '}
        <Button variant="text" onClick={copyCodes} className="text-primary">
          save your codes
        </Button>{' '}
        in a safe place.
      </label>
    </div>
    <div className="col-12">
      <div className="row" id="templateData">
        <div className="labelValue col-12">
          <div className="form-control">
            <Scrollbars
              style={{ width: '100%' }}
              autoHide
              autoHideTimeout={1000}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={300}
            >
              <div id="copyCodes">{recoveryCodes}</div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
    <MultiFactorAuthenticationSetupButtons
      noBtnText={noBtnText}
      noBtnClick={noBtnClick}
      yesBtnText={yesBtnText}
      yesBtnClick={yesBtnClick}
    />
  </div>
);

export default MFARecoveryCodes;
