import { useStorageData } from 'hooks/useStorageData';
import extractHostnameFromUrl from 'utilities/extractHostnameFromUrl/extractHostnameFromUrl';

export const useGenerateNewRequestsListPageUrl = (addCookie = false) => {
  const { storageData } = useStorageData();
  const currentlyLoggedCpaUrl = (storageData as { company_url: string })
    ?.company_url;
  const company_name = extractHostnameFromUrl(currentlyLoggedCpaUrl);

  const params = [];

  if (company_name) {
    params.push(`company_name=${encodeURIComponent(company_name)}`);
  }
  if (addCookie) {
    params.push('new-request-page=true');
  }

  return `${process.env.REACT_APP_MOBILE_URI}/requests?${params.join('&')}`;
};
