type ApiError = {
  message: string;
  path: string;
  type: string;
};

export const containsErrorOfType = (
  unknownError: unknown,
  errorType: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorsArray = ((unknownError as any)?.response?.data?.errors ||
    []) as ApiError[];

  return errorsArray.some((error) => error.type === errorType);
};
