import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom-latest';
import {
  QB_REDIRECT_URL,
  QB_CLIENT_ID,
  QB,
  QB_SYNC,
} from 'constants/constants';
import {
  AlertMessage,
  getCpaId,
  createCookie,
  randomString,
} from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import quickbooksLogo from 'images/quickbooks_logo.png';
import { useWebsocket } from 'websocket';
import LedgerSetupModal from './elements/LedgerSetupModal';

const auth_url =
  'https://appcenter.intuit.com/connect/oauth2?' +
  `redirect_uri=${
    window.location.href.includes('localhost')
      ? 'http://localhost:3000/qb'
      : QB_REDIRECT_URL
  }&response_type=code&client_id=${QB_CLIENT_ID}&scope=com.intuit.quickbooks.accounting` +
  '&state=';

const Quickbooks = (props) => {
  const [loading, setLoading] = useState(true);
  const [connectStatus, setConnectStatus] = useState('');
  const [isSyncInProcessing, setIsSyncInProcessing] = useState(false);
  const [syncStartTime, setSyncStartTime] = useState();
  const [enableViewDetailsBtn, setEnableViewDetailsBtn] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [qbId, setQBId] = useState(undefined);
  const [isModalOpen, showLedgerSetupModal] = useState(false);
  const [bankChargeSelectedValue, setBankChargeSelectedValue] =
    useState(undefined);
  const [bankDepositSelectedValue, setBankDepositSelectedValue] =
    useState(undefined);
  const [isQBSetupDone, setQBSetup] = useState(undefined);
  const websocket = useWebsocket();

  const sendCodeToBackend = async () => {
    const parsedUrl = new URL(window.location.href);
    const app_key = parsedUrl.searchParams.get('app');
    const code = parsedUrl.searchParams.get('code');
    const realmId = parsedUrl.searchParams.get('realmId');
    if (code !== null && app_key !== null && app_key === 'qb') {
      try {
        const response = await Axios.post(QB, {
          code,
          app_key,
          company_id: realmId,
        }).then((res) => res.data);
        const { is_qb_setup_done } = response.data;
        // Check if Ledger Setup is completed
        setQBSetup(is_qb_setup_done);
        if (!isQBSetupDone) {
          showLedgerSetupModal(true);
        }
        if (response.status && response.status.toLowerCase() === 'active') {
          setConnectStatus(response.status.toLowerCase());
        }
        AlertMessage('success', response.message, 3000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  function createLedgerSetupSelectOptions(
    bank_charge_name,
    bank_charge_id,
    bank_deposit_name,
    bank_deposit_id,
  ) {
    const bankChargeValue = {
      id: bank_charge_id,
      value: bank_charge_id,
      label: bank_charge_name,
    };
    const bankDepositValue = {
      id: bank_deposit_id,
      value: bank_deposit_id,
      label: bank_deposit_name,
    };

    if (bankChargeValue.id || bankChargeValue.id) {
      setBankChargeSelectedValue(bankChargeValue);
      setBankDepositSelectedValue(bankDepositValue);
    }
  }

  const getQBStatus = async () => {
    try {
      const response = await Axios.get(QB).then((res) => res.data.data);
      const {
        status,
        is_acct_auto_sync_in_progress,
        acct_auto_sync_started_at,
        acct_auto_sync_ended_at,
        bank_charge_name,
        bank_charge_id,
        bank_deposit_id,
        bank_deposit_name,
        id,
        is_qb_setup_done,
      } = response;
      if (status && status.toLowerCase() === 'active') {
        setConnectStatus(status.toLowerCase());
        setIsSyncInProcessing(is_acct_auto_sync_in_progress);
        setSyncStartTime(acct_auto_sync_started_at);
        setEnableViewDetailsBtn(acct_auto_sync_ended_at !== null);
        setQBSetup(is_qb_setup_done);
        setQBId(id);
        createLedgerSetupSelectOptions(
          bank_charge_name,
          bank_charge_id,
          bank_deposit_name,
          bank_deposit_id,
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    sendCodeToBackend();
    getQBStatus();
    const qbSubscription = websocket.consumer.subscriptions.create(
      {
        channel: 'QBAutoSyncProgressChannel',
        cpa_id: getCpaId(),
      },
      {
        connected: () => {
          console.log('connected to QBAutoSyncProgressChannel');
        },
        received: (dataReceived) => {
          console.log('Data received from quickbooks', dataReceived);
          const { is_acct_auto_sync_in_progress } = dataReceived;
          if (is_acct_auto_sync_in_progress !== undefined) {
            setIsSyncInProcessing(is_acct_auto_sync_in_progress);
          }
          if (!is_acct_auto_sync_in_progress) {
            getQBStatus();
          }
        },
        disconnected: () => {
          console.log('disconnected from QBAutoSyncProgressChannel');
        },
      },
    );
    return () => {
      console.log('unsubscribe from qb channel');
      qbSubscription.unsubscribe();
      // Do unmounting stuff here
    };
  }, []);

  const handleConnect = () => {
    const url = window.location.href.split('.liscio');
    if (url && url.length) {
      const cookieName = randomString(16);
      // if there are query params in the URL, strip them before building new query params
      // otherwise, we can end up with duplicate keys
      const [currentUrl] = window.location.href.split('?');
      const finalUrl = `${currentUrl}?app=qb`;
      createCookie(cookieName, finalUrl, 30);
      const state = cookieName;
      window.location.href = auth_url + state;
    }
  };

  const handleSyncNow = async () => {
    setLoading(true);
    try {
      const response = await Axios.post(QB_SYNC).then((res) => res.data.data);
      const { is_acct_auto_sync_in_progress, acct_auto_sync_started_at } =
        response;
      setIsSyncInProcessing(is_acct_auto_sync_in_progress);
      setSyncStartTime(acct_auto_sync_started_at);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDisconnect = async () => {
    setLoading(true);
    try {
      const response = await Axios.delete(`${QB}/${getCpaId()}`).then(
        (res) => res.data,
      );
      AlertMessage('success', response.message, 3000);
      setConnectStatus('');
      setLoading(false);
      setShowDeleteConfirmationModal(false);
    } catch (e) {
      setLoading(false);
      setShowDeleteConfirmationModal(false);
      console.log(e);
    }
  };

  function closeLedgerSetupModal() {
    showLedgerSetupModal(false);
  }

  return (
    <div className="QuickBooksIntegration col-lg-4 col-sm-12 col-md-6">
      <div
        className="card mb-4 shadow-s"
        style={{ height: 'calc(100% - 16px)' }}
      >
        <div
          className="card-img-top d-flex justify-content-center"
          style={{ height: '150px', alignItems: 'center' }}
        >
          <img
            src={quickbooksLogo}
            alt="quickbook_logo"
            style={{ width: '100%' }}
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">{props.integrationAppsPref.label}</h5>
          <div>
            {connectStatus === 'active' ? (
              <div>
                <button
                  type="button"
                  className="QuickBooksIntegration__SyncNow btn btn-primary col mt-3"
                  disabled={isSyncInProcessing}
                  onClick={handleSyncNow}
                >
                  Sync now{' '}
                  {isSyncInProcessing &&
                    `(started at: ${moment(syncStartTime).format(
                      'MM/DD/YY h:mm A',
                    )})`}
                </button>
                {enableViewDetailsBtn && !isSyncInProcessing ? (
                  <Link
                    to="/liscio_qb_accounts"
                    className="btn btn-primary mt-3 col"
                  >
                    View Details
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="QuickBooksIntegration__ViewDetails btn btn-primary col mt-3"
                    disabled
                  >
                    View Details
                  </button>
                )}
                <button
                  type="button"
                  className="QuickBooksIntegration__Disconnect btn btn-primary col mt-3"
                  disabled={isSyncInProcessing}
                  onClick={() => {
                    setShowDeleteConfirmationModal(true);
                  }}
                >
                  Disconnect
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="QuickBooksIntegration__Connect btn btn-primary col mt-3"
                onClick={handleConnect}
                disabled={loading}
              >
                Connect
              </button>
            )}
          </div>
        </div>
      </div>
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          isOpen
          headerText="Disconnect Quickbooks"
          messageText="Liscio cannot create or update the invoices in quickbooks. Proceed?"
          yesBtnText="Proceed"
          noBtnText="Cancel"
          yesBtnClick={handleDisconnect}
          noBtnClick={() => {
            setShowDeleteConfirmationModal(false);
          }}
        />
      )}

      {isModalOpen && (
        <LedgerSetupModal
          isModalOpen={isModalOpen}
          closeLedgerSetupModal={closeLedgerSetupModal}
          bankChargeSelectedValue={bankChargeSelectedValue}
          bankDepositSelectedValue={bankDepositSelectedValue}
          setBankChargeSelectedValue={setBankChargeSelectedValue}
          setBankDepositSelectedValue={setBankDepositSelectedValue}
          isQBSetupDone={isQBSetupDone}
          setQBSetup={setQBSetup}
          qbId={qbId}
        />
      )}
    </div>
  );
};

export default Quickbooks;
