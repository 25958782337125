import Axios from 'axios';
import { debounce } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Stack,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';

import {
  ACCOUNT,
  SEARCH_ACCOUNTS_WITH_FILTERS,
  DEACTIVATE_ACCOUNT,
  ACCOUNT_REPORTS,
  ACCOUNTS_ARCHIVE,
  ACCOUNTS_UNARCHIVE,
} from 'constants/constants';

import HeaderTabs from 'common/HeaderTabs_updated';
import ConfirmationModal from 'common/ConfirmationModal';
import LoadingIndicator from 'common/LoadingIndicator';
import { ArchiveIcon } from 'common/Icons';

import {
  AlertMessage,
  getCpaPreferences,
  isUserEmployee,
  getRole,
  generatingAccountsCsvFile,
  getOffSetsAndLimit,
  getCpaName,
  totalPages as getTotalPages,
  totalRecords as getTotalRecords,
} from 'utilities/utils';
import {
  saveAccountListData,
  updateCheckedAccountListData,
} from 'actions/AccountandContactListActions';

import ClientAccounts from 'components/Accounts/ClientAccounts_updated';
import {
  TextFilter,
  DropdownFilters,
  UtilityButtons,
  AccountListTableHeader,
  AccountListTableBody,
  AccountListPagination,
} from './Elements';

const accountPageTabs = [
  {
    filterlabel: 'all',
    value: 'all',
    label: 'All',
  },
  {
    filterlabel: 'Prospect',
    value: 'prospects',
    label: 'Prospects',
  },
  {
    filterlabel: 'Client',
    value: 'clients',
    label: 'Clients',
  },
  {
    filterlabel: 'Inactive',
    value: 'inactive',
    label: 'Archived',
  },
];

const AccountListPath = '/accounts_beta';

function AccountList(props) {
  const {
    componentContext,
    saveAccountListData: propsSaveAccountListData,
    // updateCheckedAccountListData: propsUpdateCheckedAccountListData,
    // TODO: Use Selector
    accountsListData,
    navigate,
    location,
  } = props;

  const didMountRef = useRef(false);

  const role = getRole();
  const isEmployee = isUserEmployee();

  const preferences = getCpaPreferences();
  const { entity_type, account_status, account_stage } = preferences;

  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(accountPageTabs);
  const [activeTab, setActiveTab] = useState(0);
  const [filter, setFilter] = useState('all');

  const [searchFieldText, setSearchFieldText] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');

  const [selectedEntityType, setSelectedEntityType] = useState([]);
  const [selectedAccountStatus, setSelectedAccountStatus] = useState([]);
  const [selectedAccountStage, setSelectedAccountStage] = useState([]);
  const [selectedCsTeam, setSelectedCsTeam] = useState(undefined);
  const [selectedRestrictedAccount, setSelectedRestrictedAccount] =
    useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const dense = false;
  const rowsPerPage = 15;
  const [page, setPage] = useState(1);

  const numberOfPages = getTotalPages(filter, tabs, 'filterlabel', 15) || 1;
  const numberOfRecords = getTotalRecords(filter, tabs, 'filterlabel');
  const offset = getOffSetsAndLimit(numberOfRecords, 15)[page - 1] || [0, 0];

  const [listData, setListData] = useState([]);

  const [confirmArchive, setConfirmArchive] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const dataKey = `
    ${AccountListPath}?filter=${filter}&page=${page}&sort_by=${sortBy}&sort_order=${sortOrder}
  `;

  useEffect(() => {
    document.title = 'Accounts';
  }, []);

  useEffect(() => {
    // TODO: Use Selector
    // let listData = []; // Account List
    let newListData = listData;

    if (accountsListData[dataKey]) {
      const { listData: propsListData, data } = accountsListData[dataKey];
      newListData = propsListData; // sortList(propsListData, sortBy, sortOrder);
      if (isEmployee) newListData = data.data;
    }

    setListData(newListData);
  }, [accountsListData]);

  const emptyTabs = accountPageTabs.map((each) => {
    const output = { ...each };
    output.label += ' | 0';
    output.noofrecords = 0;

    return output;
  });

  const generateTabsFromStats = (stats) =>
    accountPageTabs.map((each) => {
      const output = { ...each };
      output.label =
        stats[each.value] !== undefined
          ? `${output.label} | ${stats[each.value]}`
          : output.label;
      output.noofrecords = stats[each.value];

      return output;
    });

  const checkFilterSelection = () => {
    if (selectedEntityType.length > 0) return true;
    if (selectedAccountStatus.length > 0) return true;
    if (selectedAccountStage.length > 0) return true;
    if (selectedCsTeam && selectedCsTeam.id) return true;
    if (selectedRestrictedAccount) return true;

    return false;
  };

  const filterSelected = checkFilterSelection();

  const getRecords = async (fromArchive = '') => {
    let url = `${ACCOUNT}?page=${page}&field=${sortBy}&sort_by=${sortOrder}`;

    if (filter.toLowerCase() !== 'all') {
      url = `${url}&status=${filter}`;
    }

    if (accountsListData && accountsListData[dataKey] && fromArchive !== '') {
      setLoading(true);
    }
    if (accountsListData && accountsListData[dataKey]) {
      const newTabs = generateTabsFromStats(
        accountsListData[dataKey].data.count_data || {},
      );

      setTabs(newTabs);
      setLoading(false);
    }

    try {
      setLoading(true);

      const res = await Axios.get(url);

      const newTabs = generateTabsFromStats(res.data.count_data);

      let newListData = res.data.data;
      if (!isUserEmployee()) {
        newListData = res.data.new_data.map((e) => {
          e.account.is_account_owner = e.is_account_owner;
          e.account.has_invoices_that_need_attention =
            e.has_invoices_that_need_attention;
          return e.account;
        });
      }

      setTabs(newTabs);

      propsSaveAccountListData({
        key: dataKey,
        listData: newListData,
        data: res.data,
      });
      clearPageAndSelections(newTabs);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }

    if (!componentContext) {
      window.history.pushState({ path: dataKey }, '', dataKey);
      localStorage.setItem('allAccountsButtonUrl', dataKey);
    }
  };

  const getSearchResults = async () => {
    const data = {
      keyword: searchFieldText.toLowerCase(),
      entity_type: selectedEntityType.map((value) => value.label),
      status: selectedAccountStatus.map((value) => value.label),
      stage: selectedAccountStage.map((value) => value.label),
      employee_id: selectedCsTeam ? selectedCsTeam.id : '',
      is_restricted_account: selectedRestrictedAccount,
      page,
      per_page: rowsPerPage,
      sort_by: sortOrder,
      field: sortBy,
      filter: filter !== 'all' ? filter.toLowerCase() : null,
    };

    try {
      setLoading(true);

      const res = await Axios.post(SEARCH_ACCOUNTS_WITH_FILTERS, data);

      if (res.data.status === 200) {
        const newTabs = generateTabsFromStats(res.data.count_data);

        setTabs(newTabs);
        clearPageAndSelections(newTabs);

        propsSaveAccountListData({
          key: dataKey,
          listData: res.data.data,
          data: res.data,
        });
      } else {
        setTabs(emptyTabs);
        clearPageAndSelections(emptyTabs);

        propsSaveAccountListData({
          key: dataKey,
          listData: [],
          data: res.data,
        });
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }

    if (!componentContext) {
      const allAccountsButtonUrl = `${AccountListPath}?filter=${filter}&page=${page}&sort_by=${sortBy}&sort_order=${sortOrder}&keyword=${
        data.keyword
      }${
        data.entity_type && data.entity_type.length
          ? `&entity_type=${data.entity_type.join(',')}`
          : ''
      }${
        data.status && data.status.length
          ? `&account_status=${data.status.join(',')}`
          : ''
      }${
        data.stage && data.stage.length
          ? `&account_stage=${data.stage.join(',')}`
          : ''
      }${
        data.employee_id && data.employee_id !== ''
          ? `&employee_id=${selectedCsTeam.headline}:${selectedCsTeam.id}`
          : ''
      }&is_restricted_account=${data.is_restricted_account}
      `;

      window.history.pushState(
        { path: allAccountsButtonUrl },
        '',
        allAccountsButtonUrl,
      );
      localStorage.setItem('allAccountsButtonUrl', allAccountsButtonUrl);
    }
  };

  useEffect(() => {
    if (location && location.search) {
      const searchParams = new URLSearchParams(
        location.search.slice(1, location.search.length),
      );

      searchParams.forEach((value, key) => {
        if (key === 'filter') {
          let newActiveTab = tabs.findIndex(
            (each) => each.filterlabel.toLowerCase() === value.toLowerCase(),
          );
          if (newActiveTab < 0) {
            newActiveTab = 0;
          }

          setFilter(tabs[newActiveTab].filterlabel);
          setActiveTab(newActiveTab);
        } else if (key === 'page') {
          setPage(parseInt(value, 10));
        } else if (key === 'sort_by') {
          setSortBy(value);
        } else if (key === 'sort_order') {
          setSortOrder(value);
        } else if (key === 'employee_id') {
          const employeeObj = value.split(':');
          setSelectedCsTeam({
            id: parseInt(employeeObj[1], 10),
            headline: employeeObj[0],
          });
        } else if (
          key === 'entity_type' ||
          key === 'account_status' ||
          key === 'account_stage' ||
          key === 'is_restricted_account'
        ) {
          switch (key) {
            case 'entity_type': {
              const passedStatus = value.split(',');
              setSelectedEntityType(
                passedStatus.map((s) => entity_type.find((v) => v.label === s)),
              );
              break;
            }
            case 'account_status': {
              const passedStatus = value.split(',');
              setSelectedAccountStatus(
                passedStatus.map((s) =>
                  account_status.find((v) => v.label === s),
                ),
              );
              break;
            }
            case 'account_stage': {
              const passedStatus = value.split(',');
              setSelectedAccountStage(
                passedStatus.map((s) =>
                  account_stage.find((v) => v.label === s),
                ),
              );
              break;
            }
            case 'is_restricted_account': {
              setSelectedRestrictedAccount(value === 'true');
              break;
            }
            default:
              break;
          }
        } else if (key === 'keyword') {
          setSearchFieldText(value);
        }
      });
    } else {
      getRecords();
    }
  }, []);

  useEffect(() => {
    if (filterSelected || searchFieldText.length > 0) {
      getSearchResults();
    } else if (
      didMountRef.current ||
      location.search === '?filter=all&page=1&sort_by=name&sort_order=asc'
    ) {
      getRecords();
    }

    didMountRef.current = true;
  }, [
    filter,
    searchFieldText,
    selectedEntityType,
    selectedAccountStatus,
    selectedAccountStage,
    selectedCsTeam,
    selectedRestrictedAccount,
    sortBy,
    sortOrder,
    page,
  ]);

  const showDetails = (id, mode) => {
    if (mode === 'edit') {
      return navigate(`/account/edit/${id}`);
    }
    return navigate(`/accountdetails/${id}`);
  };

  const generateCsvFile = async () => {
    const cpaName = getCpaName();

    try {
      setLoading(true);
      const reports = await Axios.get(ACCOUNT_REPORTS).then(
        (res) => res.data.data,
      );

      if (reports && reports.length > 0) {
        generatingAccountsCsvFile(
          reports,
          `${cpaName}_all_accounts_contacts.csv`,
        );
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      AlertMessage('error', 'Something went wrong', 3000);
    }
  };

  const clearPageAndSelections = (newTabs) => {
    const newNumberOfPages = getTotalPages(filter, newTabs, 'filterlabel', 15);
    if (page > newNumberOfPages) {
      setPage(1);
    }
    setSelectedItems([]);
  };

  const handleActiveTabChange = (tabIndex, tabDetails) => {
    setFilter(tabDetails.filterlabel);
    setActiveTab(tabIndex);
    setPage(1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';

    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = [...listData];
      setSelectedItems(newSelected);
      return;
    }

    setSelectedItems([]);
  };

  const massArchive = async (confirmed) => {
    if (!confirmed) {
      setConfirmArchive(true);
    } else {
      // do mass archive
      const array = { ids: [] };
      selectedItems.map((each) => {
        array.ids.push(each.id);
        return array;
      });

      setLoading(true);
      setConfirmArchive(false);

      try {
        const archiveData = { ids: array.ids, type: 'account' };
        const archiveAPI =
          filter === 'Inactive' ? ACCOUNTS_UNARCHIVE : ACCOUNTS_ARCHIVE;
        const response = await Axios.post(archiveAPI, archiveData);

        if (response.data.status === 200) {
          AlertMessage('success', response.data.message, 3000);

          setSelectedItems([]);

          // this.props.pagination.messagePagination(messagePage);
          // this.props.actions.fetchMessages(this, '', 15, messagePage, this.state.apiUrl, this.state.filter);
          if (filterSelected || searchFieldText.length > 0) {
            getSearchResults();
          } else {
            getRecords();
          }
        } else {
          AlertMessage('error', response.data.message, 3000);
        }
      } catch (err) {
        AlertMessage('error', 'Some Error Occured', 3000);
      }
    }
  };

  const showDeleteConfirmationModal = (input = {}) => {
    setRecordToDelete(input);
    setShowDeleteConfirmation(!showDeleteConfirmation);
  };

  const deleteRecord = async () => {
    try {
      const res = await Axios.put(`${DEACTIVATE_ACCOUNT}/${recordToDelete.id}`);

      setRecordToDelete({});
      setShowDeleteConfirmation(false);
      setSearchFieldText('');

      if (res.status === 204 || res.status === 200) {
        AlertMessage('success', res.data.message, 3000);
      } else {
        AlertMessage('error', res.data.message, 3000);
      }
    } catch (err) {
      AlertMessage('error', 'Some Error Occured', 3000);
    }
  };

  if (!isEmployee) {
    return (
      <div id="content">
        {loading ? <div id="loading" /> : null}
        <ClientAccounts
          listData={listData}
          showDetails={showDetails}
          handleSortBy={(property) => handleRequestSort(null, property)}
          state={{
            sortBy,
            sortOrder,
          }}
        />
      </div>
    );
  }

  return (
    <Stack>
      <Stack direction="column" sx={{ padding: '0 2.3rem' }}>
        <HeaderTabs
          data={tabs}
          activeTab={activeTab}
          handleClick={handleActiveTabChange}
        />

        <Stack direction="column" spacing={3} paddingTop={10} paddingBottom={5}>
          <Stack
            direction={{ sm: 'column', md: 'column', lg: 'row' }}
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={2}
          >
            <Stack direction="row" flexWrap="wrap" gap={3}>
              <TextFilter
                value={searchFieldText}
                onChange={debounce((event) => {
                  setSearchFieldText(event.target.value);
                }, 300)}
              />

              <DropdownFilters
                filterSelected={filterSelected}
                values={{
                  selectedEntityType,
                  selectedAccountStatus,
                  selectedAccountStage,
                  selectedCsTeam,
                  selectedRestrictedAccount,
                }}
                selectedEntityTypeChange={setSelectedEntityType}
                selectedAccountStatusChange={setSelectedAccountStatus}
                selectedAccountStageChange={setSelectedAccountStage}
                selectedCsTeamChange={setSelectedCsTeam}
                selectedRestrictedAccountChange={setSelectedRestrictedAccount}
              />
            </Stack>

            <UtilityButtons
              componentContext={componentContext}
              numberOfSelected={selectedItems.length}
              onDownloadButtonClick={generateCsvFile}
            />
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              margin="auto"
              marginRight={0}
            >
              {!componentContext &&
                (filter === 'all' ||
                  filter === 'Prospect' ||
                  filter === 'Client' ||
                  filter === 'Inactive') && (
                  <Button
                    variant="contained"
                    startIcon={<ArchiveIcon />}
                    sx={{ height: 46 }}
                    onClick={() => massArchive(false)}
                    disabled={!selectedItems.length}
                  >
                    {filter === 'Inactive' ? 'Unarchive' : 'Archive'}
                  </Button>
                )}

              {(role && role === 'specialist') || componentContext ? null : (
                <Button
                  variant="contained"
                  startIcon={
                    <Typography color="white" variant="body1">
                      +
                    </Typography>
                  }
                  sx={{ height: 46 }}
                  onClick={() => navigate('/account/new')}
                >
                  Account
                </Button>
              )}
            </Stack>
          </Stack>

          <Stack paddingTop={4}>
            <Box sx={{ width: '100%' }}>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <AccountListTableHeader
                    order={sortOrder}
                    orderBy={sortBy}
                    rowCount={listData ? listData.length : 0}
                    numSelected={selectedItems.length}
                    onSelectAll={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <AccountListTableBody
                    componentContext={componentContext}
                    filterSelected={filterSelected}
                    dense={dense}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    items={listData}
                    selectedItems={selectedItems}
                    onSelectedItemsChange={setSelectedItems}
                    onItemClick={showDetails}
                  />
                </Table>
              </TableContainer>

              <AccountListPagination
                page={page}
                startOffset={offset[0]}
                endOffset={offset[1]}
                numberOfRecords={numberOfRecords}
                numberOfPages={numberOfPages}
                onPageNumberChange={debounce(setPage, 300)}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <ConfirmationModal
        isOpen={confirmArchive}
        noBtnClick={() => setConfirmArchive(false)}
        headerText="Attention!"
        messageText={`${
          filter !== 'Inactive'
            ? 'The account deactivation process will close all related open tasks and remove contact relationships. Proceed?'
            : `You are about to unarchive ${selectedItems.length} ${
                selectedItems.length > 1 ? 'accounts.' : 'account.'
              }`
        }`}
        yesBtnClick={() => massArchive(true)}
        yesBtnText="Yes"
        noBtnText="No"
      />

      {showDeleteConfirmation ? (
        <ConfirmationModal
          isOpen
          messageText="Attention: The account deactivation process will close all open tasks related. Proceed?"
          yesBtnText="Proceed"
          noBtnText="Cancel"
          yesBtnClick={deleteRecord}
          noBtnClick={showDeleteConfirmationModal}
        />
      ) : null}
      <LoadingIndicator loading={loading} />
    </Stack>
  );
}

const mapStateToProps = (state) => ({
  accountsListData: state.accountReducer.accountslistdata,
});

const mapDispatchToProps = (dispatch) => ({
  saveAccountListData: (data) => dispatch(saveAccountListData(data)),
  updateCheckedAccountListData: (data) =>
    dispatch(updateCheckedAccountListData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
