/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Link } from '@mui/material';
import { useGenerateNewRequestsListPageUrl } from 'components/Requests/hooks/useGenerateNewRequestsListPageUrl/useGenerateNewRequestsListPageUrl';

import cookies from 'js-cookie';

export type BooleanType = 'true' | 'false';

const NEW_REQUESTS_PAGE = 'NEW_REQUESTS_PAGE';

/**
 * Use this method to enable/disable routing for the
 * new requests page. 'true' indicates that the new Requests
 * Page should be used. 'false' indicates that the old
 * Requests Page should be used.
 */

// We cant use localStorage because it gets cleared when the user logs out
// so we will use a cookie instead and all of this code gets deleted when the
// new request page is fully launched.

export function setNewRequestsPageLS(value: BooleanType) {
  // set cookie for 300 days. I hope we've fully cut over by then ;)
  cookies.set(NEW_REQUESTS_PAGE, value, { expires: 300 });
}

/**
 * This method is used to retrieve the stored New Requests
 * Page value from local storage.
 */
export function getNewRequestsPageLS(): BooleanType {
  const cookieValue = cookies.get(NEW_REQUESTS_PAGE);
  return cookieValue as BooleanType;
}

/**
 * This component is used to render an Alert at the top of the Request
 * Page which allows users to toggle to the new Request Page.
 */
export function NewRequestPageAlert() {
  function updateLocalCookie() {
    setNewRequestsPageLS('true');
  }
  const newRequestListPageUrl = useGenerateNewRequestsListPageUrl(true);

  return (
    <Alert
      variant="filled"
      severity="info"
      sx={{
        backgroundColor: '#eaf8fa',
        color: '#49a7b7',
        paddingY: '0px',
        alignItems: 'center',
      }}
    >
      Explore the new and improved Request page.{' '}
      <Link
        onClick={updateLocalCookie}
        href={newRequestListPageUrl}
        style={{ fontWeight: 'bold' }}
      >
        Try it out.
      </Link>
    </Alert>
  );
}
