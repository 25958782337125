import { useMutation } from '@tanstack/react-query';
import {
  PatchApiV5AuthMfaSetupsMutationResult,
  PostApiV5AuthCpaAuthorizationMutationResult,
  PostApiV5AuthMfaSetupsMutationResult,
} from 'api/v5/auth/auth';
import { ErrorObjectArray } from 'api/v5/schemas';
import axios, { AxiosError } from 'axios';
import { API_V5_URL } from 'constants/constants';

type MfaPostV5WithTemporaryAuthTokenRequest = {
  temporary_auth_token: string;
};

type MfaPatchV5WithTemporaryAuthTokenRequest = {
  two_factor_code: string;
  temporary_auth_token: string;
};

type PostApiV5AuthCpaAuthorizationwithTemporaryAuthTokenRequest = {
  cpa_uuid: Uuid;
  login_type: string;
  temporary_auth_token: string;
};

const postMfaV5WithTemporaryAuthToken: (
  body: MfaPostV5WithTemporaryAuthTokenRequest,
) => Promise<PostApiV5AuthMfaSetupsMutationResult> = async (body) => {
  const { data } = await axios.post<PostApiV5AuthMfaSetupsMutationResult>(
    `${API_V5_URL}/auth/mfa_setups`,
    {},
    {
      headers: {
        Authorization: body.temporary_auth_token,
      },
    },
  );

  return data;
};

const postApiV5AuthCpaAuthorizationWithTemporaryAuthToken: (
  body: PostApiV5AuthCpaAuthorizationwithTemporaryAuthTokenRequest,
) => Promise<PostApiV5AuthCpaAuthorizationMutationResult> = async (body) => {
  const { data } =
    await axios.post<PostApiV5AuthCpaAuthorizationMutationResult>(
      `${API_V5_URL}/auth/cpa_authorization`,
      {
        cpa_uuid: body.cpa_uuid,
        login_type: body.login_type,
      },
      {
        headers: {
          Authorization: body.temporary_auth_token,
        },
      },
    );

  return data;
};

const patchMfaV5WithTemporaryAuthToken: (
  body: MfaPatchV5WithTemporaryAuthTokenRequest,
) => Promise<PatchApiV5AuthMfaSetupsMutationResult> = async (body) => {
  const { data } = await axios.patch<PatchApiV5AuthMfaSetupsMutationResult>(
    `${API_V5_URL}/auth/mfa_setups`,
    {
      two_factor_code: body.two_factor_code,
    },
    {
      headers: {
        Authorization: body.temporary_auth_token,
      },
    },
  );

  return data;
};

export const usePostApiV5AuthCpaAuthorizationWithTemporaryAuthToken = (props?: {
  onSuccess: (response: PostApiV5AuthCpaAuthorizationMutationResult) => void;
}) =>
  useMutation<
    PostApiV5AuthCpaAuthorizationMutationResult,
    AxiosError<ErrorObjectArray>,
    PostApiV5AuthCpaAuthorizationwithTemporaryAuthTokenRequest
  >({
    mutationFn: postApiV5AuthCpaAuthorizationWithTemporaryAuthToken,
    onSuccess: (data: PostApiV5AuthCpaAuthorizationMutationResult) => {
      if (props?.onSuccess) props.onSuccess(data);
    },
  });

export const usePostMfaV5WithTemporaryAuthToken = (props?: {
  onSuccess: (response: PostApiV5AuthMfaSetupsMutationResult) => void;
}) =>
  useMutation<
    PostApiV5AuthMfaSetupsMutationResult,
    AxiosError<ErrorObjectArray>,
    MfaPostV5WithTemporaryAuthTokenRequest
  >({
    mutationFn: postMfaV5WithTemporaryAuthToken,
    onSuccess: (data) => {
      if (props?.onSuccess) props.onSuccess(data);
    },
  });

export const usePatchMfaV5WithTemporaryAuthToken = (props?: {
  onSuccess: (response: PatchApiV5AuthMfaSetupsMutationResult) => void;
}) =>
  useMutation<
    PatchApiV5AuthMfaSetupsMutationResult,
    AxiosError<ErrorObjectArray>,
    MfaPatchV5WithTemporaryAuthTokenRequest
  >({
    mutationFn: patchMfaV5WithTemporaryAuthToken,
    onSuccess: (data) => {
      if (props?.onSuccess) props.onSuccess(data);
    },
  });
