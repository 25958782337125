import { Box, Button, Stack } from '@mui/material';
import { WorkflowRequestFragment, WorkflowRequestStatus } from 'gql/graphql';
import { useContext, useState } from 'react';
import { Footer } from 'ui/Footer/Footer';
import { WorkflowViewContext } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';
import { MarkCompleteDialog } from 'components/Requests/components/WorkflowView/components/MarkCompleteDialog/MarkCompleteDialog';
import { useParams } from 'react-router-dom-latest';
import { RequestsReminder } from 'components/Requests/components/RequestsReminder/RequestsReminder';

type WorkflowViewFooterWrapperProps = {
  onSubmit?: () => void;
  onSaveAndExit?: () => void;
  onComplete?: () => void;
  status?: WorkflowRequestStatus;
  assignedContacts?: WorkflowRequestFragment['assignedContacts'];
};

export const WorkflowViewFooterWrapper = ({
  onSubmit,
  onSaveAndExit,
  onComplete,
  status,
  assignedContacts = [],
}: WorkflowViewFooterWrapperProps) => {
  const { id } = useParams();
  const { valid } = useContext(WorkflowViewContext);
  const [showMakeCompleteDialog, setShowMakeCompleteDialog] = useState(false);

  if (!status) {
    return null;
  }

  const saveAndExitButton =
    onSaveAndExit &&
    (status === WorkflowRequestStatus.Open ||
      status === WorkflowRequestStatus.Requested ||
      status === WorkflowRequestStatus.Returned) ? (
      <Button
        variant="outlined"
        sx={{ marginLeft: 'auto' }}
        onClick={onSaveAndExit}
      >
        Save and Exit
      </Button>
    ) : null;

  const submitButton =
    onSubmit &&
    (status === WorkflowRequestStatus.Open ||
      status === WorkflowRequestStatus.Requested ||
      status === WorkflowRequestStatus.Returned) ? (
      <Button
        variant="contained"
        sx={{ marginLeft: 'auto' }}
        onClick={() => onSubmit}
        disabled={!valid}
      >
        Submit
      </Button>
    ) : null;

  const completeButton =
    onComplete &&
    status !== WorkflowRequestStatus.Recalled &&
    status !== WorkflowRequestStatus.Closed ? (
      <>
        <RequestsReminder
          variant="button"
          contacts={assignedContacts || []}
          requestId={id || ''}
        />
        <Button
          variant="contained"
          onClick={() => setShowMakeCompleteDialog(true)}
        >
          Mark Complete
        </Button>
        <MarkCompleteDialog
          open={showMakeCompleteDialog}
          hideDialog={() => setShowMakeCompleteDialog(false)}
          markComplete={onComplete}
        />
      </>
    ) : null;

  if (!saveAndExitButton && !submitButton && !completeButton) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '0px',
        right: '0',
        backgroundColor: 'white',
        width: 'calc(100vw - 190px)',
      }}
    >
      <Footer>
        <Stack direction="row" gap={1}>
          {saveAndExitButton}
          {submitButton}
          {completeButton}
        </Stack>
      </Footer>
    </Box>
  );
};
