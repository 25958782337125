import React, { Component } from 'react';
import Axios from 'axios';
import { withRouter } from 'utilities/withRouterWrapper';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TooltipItem from 'common/TooltipItem';
import {
  GET_CONTACTS_OF_ACCOUNT_API_PATH,
  SAVE_RELATIONSHIP,
  SEND_FORGOT_LINK,
  SEND_INVITE_LINK,
  INVITE_RELATED_CONTACT_TO_LISCIO,
  DEACTIVATE_CONTACT,
} from 'constants/constants';
import {
  FORMATPHONENUMBER,
  isUserEmployee,
  sortList,
  AlertMessage,
  sortingIcon,
} from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import ContactCardParent from 'common/ContactCardParent';
import NoRecords from 'common/NoRecords';
import CreateContact from '../../Contacts/ContactCreation/CreateContact';
import EditRealtionShip from '../../Contacts/ContactRelationship/EditRelationship';

const accountRelationPageTableHeaders = [
  {
    value: 'label',
    label: 'Contact Name',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'relationship_type',
    label: 'Relation',
    isSortable: true,
    className: ' col-1 thCol',
  },
  {
    value: 'preferences',
    label: 'Preference',
    isSortable: true,
    className: ' col-1 thCol',
  },
  {
    value: 'email',
    label: 'Email',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'phone',
    label: 'Mobile #',
    isSortable: true,
    className: ' col-1 thCol',
  },
  {
    value: 'is_account_owner',
    label: 'Account Owner',
    isSortable: false,
    className: ' col-1 thCol text-center ',
  }
];

const isAccountOwner = (input, accountId) =>
  input.some((each) => each.value === accountId && each.is_account_owner);

class AccountRelationshipPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      relationships: [],
      showModal: false,
      sortBy: 'label',
      sortOrder: 'asc',
      loading: true,
      showDeleteConfirmation: false,
      relationToDelete: {},
      isEmployee: isUserEmployee(),
    };
  }

  componentDidMount() {
    this.getRelationShips();
  }

  getRelationShips = () => {
    Axios.get(`${GET_CONTACTS_OF_ACCOUNT_API_PATH}/${this.props.id}`)
      .then((res) => {
        if (
          res.status === 200 &&
          res.data.status === 200 &&
          res.data.data &&
          res.data.data.length
        ) {
          const { data } = res.data;
          const relationships = data.map((each) => ({
            ...each,
            is_account_owner: isAccountOwner(
              each.accounts,
              Number(this.props.id),
            )
          }));
          const { sortBy, sortOrder } = this.state;
          this.setState({
            relationships: sortList(relationships, sortBy, sortOrder),
            loading: false,
          });
        } else {
          this.setState({
            relationships: null,
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          relationships: null,
          loading: false,
        });
      });
  };

  handleOpenModal = (newRelationShip) => {
    const {
      contact_id,
      relationship_type,
      id: relationship_id,
      is_account_owner,
      relationshipWith,
    } = {};
    this.setState({
      contact_id,
      relationship_type,
      relationship_id,
      is_account_owner,
      showModal: true,
      relationshipWith,
      newRelationShip,
    });
  };

  editRelationShip = (input) => {
    if (input && this.state.isEmployee) {
      const {
        relationship_type,
        relationship_id,
        id: contact_id,
        is_account_owner,
        label: relationshipWith,
      } = input;
      this.setState({
        contact_id,
        relationship_type,
        relationship_id,
        is_account_owner,
        showModal: true,
        relationshipWith,
        newRelationShip: false,
      });
    }
  };

  handleCloseModal = (newRelationShip = false) => {
    this.setState({
      showModal: false,
      relationToDelete: {},
      showDeleteConfirmation: false,
      newRelationShip,
    });
    this.getRelationShips();
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { relationships } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    this.setState({
      sortOrder,
      sortBy,
      relationships: sortList(relationships, sortBy, sortOrder),
    });
  };

  updateRelationship = (input) => {
    this.setState({
      relationToDelete: input,
      showDeleteConfirmation: true,
    });
  };

  deleteRelationship = () => {
    let response = null;
    if (!this.state.isEmployee) {
      response = Axios.put(
        `${DEACTIVATE_CONTACT}/${this.state.relationToDelete.id}?account_id=${this.props.id}`,
      );
    } else {
      response = Axios.delete(
        `${SAVE_RELATIONSHIP}/${this.state.relationToDelete.relationship_id}`,
      );
    }
    response
      .then((res) => {
        this.getRelationShips();
        this.setState({
          relationToDelete: {},
          showDeleteConfirmation: false,
        });
        AlertMessage('success', res.data.message, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  sendInvite = (contact, index, sendForgotPassword) => {
    const { relationships: listData } = this.state;
    if (
      listData[index].inviterequestinprogress === undefined ||
      !listData[index].inviterequestinprogress
    ) {
      this.updateInviteRequestInprogress(true, index);
      let response = null;
      if (sendForgotPassword) {
        response = Axios.get(`${SEND_FORGOT_LINK}/${contact.value}`);
      } else {
        response = Axios.get(`${SEND_INVITE_LINK}/${contact.id}`);
      }
      response
        .then((res) => {
          this.updateInviteRequestInprogress(false, index);
          if (res.status === 200 && res.data.status === 200) {
            AlertMessage('success', res.data.message, 3000);
            if (sendForgotPassword) {
              this.getRelationShips();
            }
          } else {
            AlertMessage('error', res.data.message, 3000);
          }
        })
        .catch(() => {
          AlertMessage('error', 'Something went wrong', 3000);
          this.updateInviteRequestInprogress(false, index);
        });
    }
  };

  showContactDetails = (contactDetails, page) => {
    const { id, status } = contactDetails;
    const { navigate } = this.props;
    if (page === 'edit' && status.toLowerCase() !== 'inactive') {
      navigate(`/contact/edit/${id}`, {
        state: {
          editFrom: 'clientSide',
          accountId: this.props.id,
          contactStatus: status.toLowerCase(),
        },
      });
    }
    if (page === 'invite' && status.toLowerCase() !== 'inactive') {
      navigate({
        pathname: '/invite_contact',
        state: { contact: contactDetails },
      });
    }
    if (page === 'reinvite' && status.toLowerCase() !== 'inactive') {
      const reinviteId = [id];
      const data = { ids: reinviteId };
      this.setState({ loading: true });
      const request = Axios.post(INVITE_RELATED_CONTACT_TO_LISCIO, data);
      request
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.status === 200) {
            AlertMessage('success', res.data.message);
          } else {
            AlertMessage('error', res.data.message);
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log('error', error);
        });
    }
  };

  updateInviteRequestInprogress(value, index) {
    const { relationships: listData } = this.state;
    listData[index].inviterequestinprogress = value;
    this.setState({ relationships: listData });
  }

  render() {
    const { relationships, isEmployee } = this.state;
    const { accountData } = this.props;
    return (
      <div className="center-wrapper">
        {isEmployee &&
          accountData &&
          accountData.status &&
          accountData.status.toLowerCase() !== 'inactive' && (
            <div className="mb-3 d-flex flex-nowrap btn--leftspace justify-content-end">
              <div className="dropdown dropupWrap dropupWrap--right">
                <button
                  type="button"
                  className="btn dropdown-toggle btn-primary"
                  data-toggle="dropdown"
                >
                  <i>+</i> Contact
                  <span className="dropupWrap--caret" />
                </button>
                <div className="dropdown-menu">
                  <button
                    type="button"
                    onClick={() => this.handleOpenModal(false)}
                    className="dropdown-item"
                  >
                    Existing Contact
                  </button>
                  <button
                    type="button"
                    onClick={() => this.handleOpenModal(true)}
                    className="dropdown-item"
                  >
                    New Contact
                  </button>
                </div>
              </div>
            </div>
          )}
        {this.state.loading && <div id="loading" />}
        {relationships === undefined || relationships === null ? (
          <NoRecords />
        ) : null}
        {relationships && relationships !== null && relationships.length
          ? [
            <section key="contact_relationship">
              <div id="inbox-list" className="tableWrap table-responsive">
                <table className="table">
                  <div className="tRow tRow--head">
                    <div className="row">
                      {accountRelationPageTableHeaders.map((each) => (
                        <div
                          key={each.value}
                          width={each.width}
                          className={
                            sortingIcon(
                              this.state.sortBy,
                              each.value,
                              this.state.sortOrder,
                              'className',
                            ) +
                            (each.className ? each.className : '') +
                            (!each.isSortable ? ' cursor-default' : '')
                          }
                          onClick={() =>
                            each.isSortable
                              ? this.handleSortBy(each.value)
                              : null
                          }
                        >
                          <span className="posRel">
                            {each.label}
                            <i
                              className={sortingIcon(
                                this.state.sortBy,
                                each.value,
                                this.state.sortOrder,
                              )}
                            />
                          </span>
                        </div>
                      ))}
                      <div className="col-4 thCol text-right" />
                    </div>
                  </div>
                  <div className="tRow tRow--body">
                    {relationships.map((each, key) => (
                      <div key={each.id} className="row tdBtn">
                        <div
                          className="col-2 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <ContactCardParent data={each} />
                        </div>
                        <div
                          className="col-1 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>{each.relationship_type}</span>
                        </div>
                        <div
                          className="col-1 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.preferences !== null
                              ? each.preferences
                              : '-'}
                          </span>
                          <br />
                        </div>
                        <div
                          className="col-2 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.email === 'n/a' ? '-' : each.email}
                          </span>{' '}
                        </div>
                        <div
                          className="col-1 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.phone === 'n/a'
                              ? '-'
                              : FORMATPHONENUMBER(each.phone)}
                          </span>{' '}
                        </div>
                        <div
                          className="col-1 tdCol text-center"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.is_account_owner ? (
                              <i className="icon-tickmark" />
                            ) : null}
                          </span>{' '}
                        </div>
                        {isEmployee ? (
                          <div className="col-4 tdCol text-right">
                            <TooltipItem
                              position="top"
                              tooltipType="button"
                              id={`edit${each.id}`}
                              text="Edit"
                              btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                              clickAction={() => this.editRelationShip(each)}
                              iconn="icon-edit"
                            />
                            <TooltipItem
                              position="top"
                              tooltipType="button"
                              id={`delete${each.id}`}
                              text="Delete"
                              btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                              clickAction={() =>
                                this.updateRelationship(each)
                              }
                              iconn="icon-delete-small"
                            />
                            {each.status &&
                              (each.status.toLowerCase() === 'new' ||
                                each.status.toLowerCase() === 'invited') &&
                              each.email !== null &&
                              each.email !== 'n/a' &&
                              each.email.length ? (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                id={`sendinvite${each.id}`}
                                text={
                                  each.status.toLowerCase() === 'new'
                                    ? 'Send Invite'
                                    : 'Resend Invite'
                                }
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                clickAction={() =>
                                  this.sendInvite(each, key, false)
                                }
                                iconn="icon-reinvite"
                              />
                            ) : null}
                            {each.status &&
                              each.status.toLowerCase() === 'active' &&
                              each.email !== null &&
                              each.email !== 'n/a' &&
                              each.email.length &&
                              each.value !== each.id ? (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                id={`resetpassword${each.id}`}
                                text="Reset Password"
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                clickAction={() =>
                                  this.sendInvite(each, key, true)
                                }
                                iconn="icon-reset-password"
                              />
                            ) : null}
                            {each.is_invitation_expired ? (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                id={`Inviteexpired${each.id}`}
                                text="Invite Expired"
                                btnClass="btn-sm btn--onlyicon btn-link cursor-default"
                                clickAction={() => { }}
                                iconn="icon-expired text-danger"
                              />
                            ) : null}
                          </div>
                        ) : (
                          <div className="col-2 tdCol text-right">
                            {each.creator &&
                              each.creator === 'client' &&
                              each.status &&
                              (each.status.toLowerCase() === 'new' ||
                                each.status.toLowerCase() === 'active' ||
                                each.status.toLowerCase() === 'invited') ? (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                id={`editcontact${each.id}`}
                                text="Edit"
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover pl-0"
                                clickAction={() =>
                                  this.showContactDetails(each, 'edit')
                                }
                                iconn="icon-edit"
                              />
                            ) : null}
                            {each.creator &&
                              each.creator === 'client' &&
                              each.status &&
                              (each.status.toLowerCase() === 'new' ||
                                each.status.toLowerCase() === 'invited') ? (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                id={`archive${each.id}`}
                                text="Archive"
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                clickAction={() =>
                                  this.updateRelationship(each)
                                }
                                iconn="icon-delete-small"
                              />
                            ) : null}
                            {each.creator &&
                              each.creator === 'client' &&
                              each.status &&
                              each.status.toLowerCase() === 'invited' ? (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                id={`sendinvite${each.id}`}
                                text="Re-invite"
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                clickAction={() =>
                                  this.showContactDetails(each, 'reinvite')
                                }
                                iconn="icon-reinvite"
                              />
                            ) : null}
                            {each.creator &&
                              each.creator === 'client' &&
                              each.status &&
                              each.status.toLowerCase() === 'new' ? (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                id={`invite${each.id}`}
                                text="Invite"
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                clickAction={() =>
                                  this.showContactDetails(each, 'invite')
                                }
                                iconn="icon-reinvite"
                              />
                            ) : null}
                            {each.status &&
                              each.status.toLowerCase() === 'invited' &&
                              each.is_invitation_expired ? (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                id={`Inviteexpired${each.id}`}
                                text="Invitation Expired"
                                btnClass="btn-sm btn--onlyicon btn-link cursor-default"
                                clickAction={() => { }}
                                iconn="icon-expired text-danger"
                              />
                            ) : null}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </table>
              </div>
              {/* <Pagination
                currentPage={this.state.pagination}
                totalPages={this.state.totalPages}
                totalRecords={this.state.totalRecords}
                handlePageChange={this.handlePageChange}
                offsets={offset[0] + ' to ' + offset[1]}
              /> */}
            </section>,
          ]
          : null}
        {this.state.showModal && !this.state.newRelationShip ? (
          <EditRealtionShip
            with="contact"
            handleCloseModal={this.handleCloseModal}
            contact_id={this.state.contact_id}
            account_id={this.props.id}
            relationship_type={this.state.relationship_type}
            relationship_id={this.state.relationship_id}
            is_account_owner={this.state.is_account_owner}
            relationshipWith={this.state.relationshipWith}
            existingrelationships={relationships}
          />
        ) : null}
        {this.state.showModal && this.state.newRelationShip ? (
          <Modal
            isOpen
            className="customModal customModal--w990 customModal--nopadding"
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={this.handleCloseModal}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody style={{ maxHeight: 'none' }}>
              <div className="modalContent--wrap">
                <div className="modalContent--inner">
                  <CreateContact
                    createRelationship
                    accountId={this.props.id}
                    handleCloseModal={this.handleCloseModal}
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            isOpen
            headerText={
              !this.state.isEmployee
                ? 'Attention: This contact will get archived.'
                : ''
            }
            messageText={
              !this.state.isEmployee
                ? 'This action cannot be undone. Proceed?'
                : 'Attention: The relationship will be deleted. Proceed?'
            }
            yesBtnText="Proceed"
            noBtnText="Cancel"
            yesBtnClick={this.deleteRelationship}
            noBtnClick={this.handleCloseModal}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(AccountRelationshipPage);
