import React from 'react';
import { CheckboxField, SelectField } from 'common/CustomFields';

const NewRelationShipHeader = (props) => {
  const { preferences, formdata, errors, relationFor } = props.data;
  return [
    <div key="relationship-title" className="row no-gutters">
      <div className="col section-title">
        <h4>
          Create New {relationFor === 'contact' ? ' Contact' : ' Account'}
        </h4>
        <p>
          Create an {relationFor === 'contact' ? ' Contact' : ' Account'} and
          setup a relationship to it that will be added to list of{' '}
          {relationFor === 'contact' ? ' contacts' : ' accounts'} associated
          with you
        </p>
      </div>
    </div>,
    <div key="relationship-select" className="row">
      <SelectField
        name="relationship_type"
        id="relationship_type"
        label="Relationship"
        options={preferences.contact_type}
        handleChange={(input) => props.handleSelect('relationship_type', input)}
        value={{
          value: formdata.relationship_type,
          label: formdata.relationship_type,
        }}
        className="col-4 form-group"
        error={errors.relationship_type}
      />
      <CheckboxField
        id="is_account_owner"
        name="is_account_owner"
        label="Account owner?"
        value={formdata.is_account_owner}
        className="col-3 form-group mt-3 pt-3 text-capitalize"
        onChange={() => props.handleCheckbox('is_account_owner')}
      />
    </div>,
  ];
};

export default NewRelationShipHeader;
