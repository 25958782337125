/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import { Component } from 'react';
import Axios from 'axios';
import { withRouter } from 'utilities/withRouterWrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import UploadedFilesList from 'components/UploadedFiles/UploadedFilesList';
import HeaderTabs from 'common/HeaderTabs';
import { CONTACT } from 'constants/constants';
import {
  generateFullName,
  isUserEmployee,
  getFirmType,
  getCurrentPath,
} from 'utilities/utils';
import EmailsSection from 'components/Email/components/EmailsSection';
import NoteList from 'components/Notes/NoteList';
import TaskList from 'components/Tasks/TaskList';
import ContactMessageList from 'components/Messages/Inbox/UserMessageList';
import TimelineActivity from 'components/TimelineActivity';
import ContactRelationshipPage from './ContactRelationship/ContactRelationShipPage';
import ContactInfo from './ContactInfo';
import { contactPageTabs, liteContactTabs } from './elements/contactPageTabs';

class ContactPage extends Component {
  constructor(props) {
    super(props);

    const { match, params } = props;

    const pageTabs = contactPageTabs;

    const activatedTabs = { timeline: true };

    this.state = {
      activeTabIndex: 0,
      tabs: getFirmType() === 'liscio-lite' ? liteContactTabs : pageTabs,
      activatedTabs,
      loading: true,
      contact: {},
      contactId: match && params ? params.id.split('#')[0] : undefined,
    };
  }

  UNSAFE_componentWillMount() {
    document.title = 'Contact';

    const { navigate, location } = this.props;
    const { tabs } = this.state;

    if (!isUserEmployee()) {
      navigate('/');
    }
    if (location && location.hash) {
      let { hash } = location;
      if (location.hash.includes('#notes')) {
        hash = '#notes';
      } else if (location.hash.includes('#emails')) {
        hash = '#emails';
      }
      const tabIndex =
        location.hash.indexOf('#multiple') > -1
          ? tabs.findIndex((each) => `#${each.value}` === '#files')
          : tabs.findIndex((each) => `#${each.value}` === hash);
      this.changeActiveTab(tabIndex, tabs[tabIndex]);
    }
  }

  componentDidMount() {
    this.getContactData(this.props);
  }

  getContactData = (propData) => {
    const contactId =
      propData.match && propData.params ? propData.params.id : undefined;
    const path = getCurrentPath(propData.location);
    if (contactId && isUserEmployee()) {
      const urlParam =
        propData.match &&
        propData.match.pathname &&
        path === '/inactivecontactdetails/:id'
          ? contactId
          : contactId;
      Axios.get(`${CONTACT}/${urlParam}`).then((res) => {
        this.setState({ loading: false });
        if (
          res.status === 200 &&
          res.data.status === 200 &&
          res.data.data &&
          res.data.data.length
        ) {
          const contactDetails = res.data.data[0];
          this.setState({
            contact: contactDetails.contact,
            data: res.data.data[0],
          });
          document.title = `Contact - ${generateFullName(
            contactDetails.contact,
          )}`;
        } else if (isUserEmployee()) {
          this.props.navigate('/contacts');
        } else {
          this.props.navigate('/');
        }
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match, params } = this.props;
    const { tabs } = this.state;
    const contactId = match && params ? params.id : undefined;

    if (nextProps?.params?.id !== contactId) {
      this.setState(
        {
          contactId: nextProps.params.id,
        },
        () => {
          this.changeActiveTab(0, tabs[0]);
          this.getContactData(nextProps);
        },
      );
    }
  }

  changeActiveTab = (tabIndex, tabDetails) => {
    this.setState({
      activeTabIndex: tabDetails.index,
      activatedTabs: { [tabDetails.value]: true },
    });
    let val = `#${tabDetails.value}`;
    if (
      this.props.location.hash &&
      (this.props.location.hash.indexOf('#multiple') > -1 ||
        this.props.location.hash.includes('#notes?id'))
    ) {
      val = this.props.location.hash;
    } else if (
      this.props.location.hash.includes('#emails?id') &&
      this.props.location.hash.includes(tabDetails.value)
    ) {
      val = this.props.location.hash;
    }
    this.props.navigate('/redirect');
    setTimeout(() => {
      this.props.navigate(`/contactdetails/${this.state.contactId}${val}`, {
        replace: true,
      });
    }, 50);
  };

  handleAllContactsBtn = () => {
    let url = localStorage.getItem('allContactsButtonUrl');
    if (!url && url === null) {
      url = '/contacts';
    }
    this.props.UploadFilesActions.handleFilesData({});
    this.props.navigate(url);
  };

  render() {
    const {
      activeTabIndex,
      tabs,
      contact,
      loading,
      activatedTabs,
      data,
      contactId,
    } = this.state;
    const { location, navigate } = this.props;

    return (
      <div id="content">
        {loading ? <div id="loading" /> : null}
        <header>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={this.handleAllContactsBtn}>
                All Contacts
              </a>
            </li>
            <li className="breadcrumb-item">{generateFullName(contact)}</li>
          </ul>
        </header>
        <div>
          <HeaderTabs
            data={tabs}
            activeTab={tabs.findIndex((each) => each.index === activeTabIndex)}
            handleClick={(index, tabDetails) =>
              this.changeActiveTab(index, tabDetails)
            }
            customClasses="navtabs-bgColor navtabs-small justify-content-center"
          />
        </div>
        <div>
          <div className={activatedTabs.timeline ? '' : 'hidden'}>
            <TimelineActivity id={contactId} requestType="contact" />
          </div>

          {activatedTabs.details && (
            <div className={activatedTabs.details ? '' : 'hidden'}>
              {data ? <ContactInfo data={data} contactId={contactId} /> : null}
            </div>
          )}

          {activatedTabs.relationships && (
            <div className={activatedTabs.relationships ? '' : 'hidden'}>
              <ContactRelationshipPage data={data} contactId={contactId} />
            </div>
          )}

          {activatedTabs.tasks && (
            <div className={activatedTabs.tasks ? '' : 'hidden'}>
              <TaskList contactId={contactId} contactDetail={contact} />
            </div>
          )}

          {activatedTabs.messages && (
            <div className={activatedTabs.messages ? '' : 'hidden'}>
              <ContactMessageList contactId={contactId} contactData={contact} />
            </div>
          )}

          {activatedTabs.notes && (
            <div className={activatedTabs.notes ? '' : 'hidden'}>
              <NoteList
                notesof="contact"
                id={contactId}
                contactData={contact}
              />
            </div>
          )}

          {activatedTabs.files && (
            <div className={activatedTabs.files ? '' : 'hidden'}>
              <UploadedFilesList contactId={contactId} contactData={contact} />
            </div>
          )}

          {activatedTabs.emails && (
            <div className={activatedTabs.emails ? '' : 'hidden'}>
              <EmailsSection
                contactId={contactId}
                viewContext="contact"
                props={this.props}
                location={location}
                navigate={navigate}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  UploadFilesActions: bindActionCreators(UploadFilesActions, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(ContactPage));