import { useEffect, useRef } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { SuccessLottieAnimation } from 'common/LottieAnimations';

const DELAY = 2500;

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(4),
  textAlign: 'left',
}));

export interface MFASuccessStateProps {
  onDelayedSuccess: () => void;
}

export const MFASuccessState = ({ onDelayedSuccess }: MFASuccessStateProps) => {
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!timeoutIdRef.current) {
      timeoutIdRef.current = setTimeout(onDelayedSuccess, DELAY);
    }

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = undefined;
      }
    };
  }, [onDelayedSuccess]);

  return (
    <Wrapper>
      <Typography variant="h5">Multi-factor Authentication Enabled</Typography>
      <Stack alignItems="center" m={5}>
        <SuccessLottieAnimation />
      </Stack>
      <Typography>
        You have successfully enabled Multi-factor Authentication for your
        account.
      </Typography>
      <Typography>
        You&apos;ll be redirected to your homepage in a few seconds.
      </Typography>
    </Wrapper>
  );
};

export default MFASuccessState;
