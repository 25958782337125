import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  styled,
  tableCellClasses,
  tableRowClasses,
  ThemeProvider,
  Button,
  Box,
  Stack,
  Dialog,
} from '@mui/material';
import { PaymentEstimateObject } from 'api/tax-delivery';
import { MinusIcon, PlusIcon } from 'common/Icons';
import {
  useDeletePaymentEstimates as useDeletePaymentEstimate,
  useGetPaymentEstimates,
} from 'hooks/payment-hooks';
import { SyntheticEvent, useState } from 'react';
import liscioLightTheme from 'theme/liscioLight';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Add } from '@mui/icons-material';
import { PaymentEstimates } from 'components/PaymentEstimates/PaymentEstimates';
import TaxDetailsDialog from './components/TaxDetailsDialog/TaxDetailsDialog';

interface Props {
  accountId: string;
  isExpanded: (panel: string) => boolean;
  handleChange: (
    panel: string,
  ) => (event: SyntheticEvent, expanded: boolean) => void;
}

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  [`& .${tableRowClasses.root}`]: {},
  [`& .${tableCellClasses.root}`]: {
    [`&.${tableCellClasses.head}`]: {
      ...theme.typography.body2,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontWeight: 400,
      ...theme.typography.body1,
      letterSpacing: 'inherit',
      textTransform: 'none',
      p: {
        maxWidth: '15rem',
      },
    },
  },
}));

export default function PaymentDetails({
  isExpanded,
  handleChange,
  accountId,
}: Props) {
  const { taxDetails } = useFlags();
  const [selectedData, setSelectedData] = useState<PaymentEstimateObject>();
  const {
    data: paymentEstimates,
    refetch,
    isInitialLoading,
  } = useGetPaymentEstimates(accountId);
  const { mutate: deletePaymentMutation } = useDeletePaymentEstimate();
  const [DialogOpen, setDialogOpen] = useState(false);

  const handleAdd = () => {
    setSelectedData(undefined);
    setDialogOpen(true);
  };

  const handleSuccess = () => {
    refetch();
    setDialogOpen(false);
  };

  const handleDelete = (id?: string) => {
    if (!id) {
      return;
    }
    deletePaymentMutation(id, {
      onSettled: () => {
        refetch();
      },
    });
  };

  const handleEdit = (data: PaymentEstimateObject) => {
    setSelectedData(data);
    setDialogOpen(true);
  };

  if (!taxDetails) {
    return null;
  }

  if (isInitialLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Accordion
      disableGutters
      square
      elevation={0}
      expanded={isExpanded('tax_details')}
      onChange={handleChange('tax_details')}
    >
      <AccordionSummary
        id="tax_details-header"
        expandIcon={isExpanded('tax_details') ? <MinusIcon /> : <PlusIcon />}
        aria-controls="tax_details-content"
      >
        <Typography fontWeight="bolder" variant="h6">
          Tax Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ThemeProvider theme={liscioLightTheme}>
          <Stack>
            <Box id="datepicker-portal" sx={{ zIndex: 9999 }} />
            <Box marginLeft="auto">
              <Button
                startIcon={<Add />}
                onClick={handleAdd}
                variant="contained"
              >
                Tax Jurisdiction
              </Button>
            </Box>
            <PaymentEstimates
              paymentEstimates={paymentEstimates}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
            <Dialog open={DialogOpen} maxWidth="lg">
              <Box>
                <TaxDetailsDialog
                  accountId={accountId}
                  estimate={selectedData}
                  paymentEstimates={paymentEstimates}
                  onSuccess={handleSuccess}
                  onCancel={() => setDialogOpen(false)}
                />
              </Box>
            </Dialog>
          </Stack>
        </ThemeProvider>
      </AccordionDetails>
    </Accordion>
  );
}
