import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom-latest';
import Axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from '@emotion/styled';
import {
  DEFAULT,
  INVOICE_RETRIEVAL_AND_CREATION,
  RECURRING,
  STATUS,
  GET_CPA_INVOICES_SETTINGS,
  ASC,
  DESC,
} from 'constants/constants';
import Pagination from 'common/Pagination';
import * as paginationActions from 'actions/PaginationActions';
import { isUserEmployee, isClient } from 'utilities/utils';
import Menu from 'common/Menu';
import {
  TabPanel,
  defaultBillingTabs,
  generateFilterOptions,
  buildURLFilterParams,
  getInitialFiltersSelected,
  // messageObj,
} from './billingUtils';
import Invoices from './elements/Invoices';
import Recurring from './elements/Recurring';
import BillingFilter from './elements/BillingFilter/BillingFilter';
import InvoiceModal from './elements/InvoiceModal/InvoiceModal';
import BillingLoadingScreen from './BillingLoadingScreen';

const StyledDiv = styled.div`
  width: 100%;
  padding: 16px 16px 38px;
  display: flex;
  flex-direction: column;

  .MuiTab-root {
    text-transform: none;
    font-size: 16px;
    min-width: 130px;
    color: #c4cdd5;
  }

  .MuiTabs-indicator {
    background-color: #212b36;
    width: 130px;
  }

  .Mui-selected {
    color: #212b36;
  }

  .BillingFilter {
    margin: 16px 0;
  }

  .Billing__AddNew {
    align-self: flex-end;

    .MuiButtonBase-root {
      color: #fff;
      background-color: #00aecc;
      box-shadow: none;
      border-color: #00aecc;
      text-transform: none;
      font-size: 16px;
    }
  }

  .Billing__AddNewDropdown {
    width: 170px;
    transform: translate3d(-162px, 37px, 0px);
  }
`;

const Billing = ({
  message_page,
  pagination,
  accountId,
  accountName,
  qbCustomerId,
  contactId,
  contactName,
  isContactView,
  billingTabs,
}) => {
  document.title = 'Billing';
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(onLoadChangeTab());
  const [isOpen, setModalOpen] = useState(false);
  const [allInvoices, setAllInvoices] = useState(undefined);
  const [paginationDetails, setPaginationDetails] = useState(undefined);
  const [modalMode, setModalMode] = useState(DEFAULT);
  const [cpaInvoiceSettings, setCPAInvoiceSettings] = useState(undefined);
  const [filterOptions, setFilterOptions] = useState({
    oneTime: {},
    recurring: {},
  });
  const [textSearch, setTextSearch] = useState({
    text: ['', ''],
    cleared: [true, true],
  });
  const { initialOneTimeFilters, initialRecurringFilters } =
    getInitialFiltersSelected();
  const [filtersSelected, setFiltersSelected] = useState({
    oneTime: [...initialOneTimeFilters],
    recurring: [...initialRecurringFilters],
  });
  const [activeFiltersCount, setActiveFiltersCount] = useState([0, 0]);
  const [filterBGClicked, setFilterBGClicked] = useState(0);
  const [sortBy, setSortBy] = useState('last_activity');
  const [sortOrder, setSortOrder] = useState(DESC);
  const { hideCreateNewInvoiceButton } = useFlags();

  let totalpages;
  let totalrecords;
  let offset;
  let off;
  if (paginationDetails) {
    totalpages = paginationDetails.pages;
    totalrecords = paginationDetails.count;
    offset = paginationDetails.from;
    off = paginationDetails.to;
  }

  useEffect(() => {
    if (!isClient()) {
      getCPAInvoiceSettings();
    }
  }, []);

  useEffect(() => {
    if (
      location.state &&
      (location.state.accountID || location.state.shouldOpenModal)
    ) {
      const { billingModalMode } = location.state;
      const selectedTab = billingModalMode === DEFAULT ? 0 : 1;
      setActiveTab(selectedTab);
      handleModalOpen(billingModalMode);
    } else if (location.state?.filterSelected) {
      getFiltersFromInfocus();
    }
  }, [location]);

  useEffect(() => {
    getAllInvoices(message_page);
  }, [
    activeTab,
    ...activeFiltersCount,
    ...textSearch.text,
    sortOrder,
    sortBy,
    filtersSelected,
  ]);

  function getFiltersFromInfocus() {
    const { filterSelected } = location.state;
    const { recurring, oneTime } = filtersSelected;
    const newFilter = oneTime.map((own) =>
      own.type === 'STATUS'
        ? {
            ...own,
            type: 'STATUS',
            value: filterSelected.split(','),
          }
        : own,
    );
    pagination.messagePagination(1);
    setFiltersSelected({
      oneTime: newFilter,
      recurring,
    });
    setActiveFiltersCount([1, 0]);
  }

  // changes the tab based on url on refresh
  function onLoadChangeTab() {
    const { pathname } = location;
    const tabs = billingTabs || defaultBillingTabs;
    const currentTab =
      pathname === tabs[0].url || pathname === `/contactdetails/${contactId}`
        ? 0
        : 1;
    return currentTab;
  }

  async function getAllInvoices(currentPage = 1) {
    const { state } = location;
    let oneTimeInvoiceUrl;
    let recurringInvoiceUrl;

    if (isContactView) {
      // Add contactId as param for Billing module under contacts page
      oneTimeInvoiceUrl = `${INVOICE_RETRIEVAL_AND_CREATION}?contact_id=${contactId}&page=${currentPage}&sort_by=${sortBy}&sort_order=${sortOrder}`;
      recurringInvoiceUrl = `${INVOICE_RETRIEVAL_AND_CREATION}?contact_id=${contactId}&page=${currentPage}&sort_by=${sortBy}&sort_order=${sortOrder}&is_parent_task=true`;
    } else {
      // Billing module default API call
      oneTimeInvoiceUrl = `${INVOICE_RETRIEVAL_AND_CREATION}?page=${currentPage}&sort_by=${sortBy}&sort_order=${sortOrder}`;
      recurringInvoiceUrl = `${INVOICE_RETRIEVAL_AND_CREATION}?page=${currentPage}&sort_by=${sortBy}&sort_order=${sortOrder}&is_parent_task=true`;
    }

    try {
      let url = activeTab === 0 ? oneTimeInvoiceUrl : recurringInvoiceUrl;

      if (accountId || state?.filterSelected) {
        url += `&account_id=${accountId}`;
      }

      url += buildURLFilterParams(filtersSelected, activeTab, textSearch);

      const response = await Axios.get(url);

      if (response.status === 200) {
        const { data, stats } = response.data;
        setAllInvoices(data);
        setPaginationDetails(stats);
        setFilterOptions(generateFilterOptions(data));
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getCPAInvoiceSettings() {
    try {
      const response = await Axios.get(GET_CPA_INVOICES_SETTINGS);
      if (response.status === 200) {
        setCPAInvoiceSettings(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  function handleTabChange(event, newValue) {
    setAllInvoices(undefined);
    setActiveTab(newValue);
    // reset to default sort on tab change
    setSortBy('last_activity');
  }

  function handleModalOpen(mode) {
    setModalMode(mode);
    setModalOpen(true);
  }

  function handleModalClose() {
    if (location) {
      const { state } = location;
      if (state && state.accountID) {
        navigate(`/accountdetails/${state.accountID}/inFocus`);
      }
    }
    setModalMode(DEFAULT);
    setModalOpen(false);
  }

  function handlePageChange(index) {
    if (index === message_page) {
      return;
    }
    getAllInvoices(index);
    pagination.messagePagination(index);
  }

  function handleColumnSorting(columnName) {
    let sortDirection = ASC;
    if (sortBy === columnName) {
      if (sortOrder === ASC) {
        sortDirection = DESC;
      } else {
        sortDirection = ASC;
      }
    } else {
      sortDirection = ASC;
    }

    setSortBy(columnName);
    setSortOrder(sortDirection);
  }

  const resetCurrentTabFilters = () => {
    const { oneTime, recurring } = filtersSelected;
    const activeCount = [...activeFiltersCount];
    activeCount[activeTab] = 0;
    if (activeTab === 0) {
      setFiltersSelected({ oneTime: initialOneTimeFilters, recurring });
    } else {
      setFiltersSelected({ recurring: initialRecurringFilters, oneTime });
    }
    setActiveFiltersCount(activeCount);
  };

  const onClearFilter = (selectedFilter) => {
    if (!selectedFilter || !selectedFilter.type) {
      resetCurrentTabFilters();
    } else {
      const { type } = selectedFilter;
      const tabSelected = activeTab === 0 ? 'oneTime' : 'recurring';
      const allInitialValues = [...initialOneTimeFilters];
      const clearedFilterObj = {
        ...allInitialValues.find((filterValue) => filterValue.type === type),
      };
      const updatedSelection = [...filtersSelected[tabSelected]];
      const currentFilterAt = updatedSelection.findIndex(
        (filter) => filter.type === type,
      );
      if (type === STATUS) {
        const currentStatusValues =
          updatedSelection[currentFilterAt].value || [];
        if (currentStatusValues.includes(selectedFilter.value)) {
          const selectedStatusAt = currentStatusValues.findIndex(
            (statusValue) => statusValue === selectedFilter.value,
          );
          currentStatusValues.splice(selectedStatusAt, 1);
        }
        clearedFilterObj.value = currentStatusValues;
      }
      updatedSelection[currentFilterAt] = clearedFilterObj;
      const updatedFilters = {
        ...filtersSelected,
        [tabSelected]: updatedSelection,
      };
      const updatedActiveFiltersCount = [...activeFiltersCount];
      updatedActiveFiltersCount[activeTab] -= 1;
      setFiltersSelected(updatedFilters);
      setActiveFiltersCount(updatedActiveFiltersCount);
    }
  };

  const onSelectFilter = (selectedFilter) => {
    if (selectedFilter) {
      const { type, value } = selectedFilter;
      const tabSelected = activeTab === 0 ? 'oneTime' : 'recurring';
      const updatedSelection = [...filtersSelected[tabSelected]];
      const currentFilterAt = updatedSelection.findIndex(
        (filter) => filter.type === type,
      );
      const updatedFilter = { ...updatedSelection[currentFilterAt] };

      if (type === STATUS) {
        updatedFilter.value = [...updatedFilter.value, value];
      } else {
        updatedFilter.value = value;
      }
      updatedSelection[currentFilterAt] = updatedFilter;
      const updatedActiveFiltersCount = [...activeFiltersCount];
      updatedActiveFiltersCount[activeTab] += 1;
      setFiltersSelected({
        ...filtersSelected,
        [tabSelected]: updatedSelection,
      });
      setActiveFiltersCount(updatedActiveFiltersCount);
    }
  };

  const onTextSearch = (val) => {
    const newSearchObj = { ...textSearch };
    if (!val) {
      setTextSearch({
        text: ['', ''],
        cleared: [true, true],
      });
    } else {
      newSearchObj.text[activeTab] = val;
      newSearchObj.cleared[activeTab] = val.length < 1;
      setTextSearch(newSearchObj);
    }
  };

  /** TODO: temporary fix for filter dropdowns, can be removed once filters are
   * refactored as a reuasable component */
  const onClickFilterBG = () => {
    setFilterBGClicked(filterBGClicked + 1);
  };

  return (
    <>
      {!allInvoices && <BillingLoadingScreen />}
      {allInvoices && (
        <StyledDiv
          className="Billing"
          onClick={onClickFilterBG}
          role="presentation"
        >
          {(isClient() ||
            (cpaInvoiceSettings &&
              cpaInvoiceSettings.stripe_account_status === 'active')) && (
              <>
                <Tabs value={activeTab} onChange={handleTabChange}>
                  {(billingTabs || defaultBillingTabs).map((tab) => (
                    <Tab
                      key={tab.label}
                      label={tab.label}
                      component={Link}
                      to={
                        isContactView
                          ? `/contactdetails/${contactId}#billing`
                          : tab.url
                      }
                    />
                  ))}
                </Tabs>
                {isUserEmployee() && !hideCreateNewInvoiceButton && (
                  <div
                    className="Billing__AddNew"
                    aria-labelledby="billingAddNew"
                  >
                    <Menu
                      menuComponent={
                        <Button
                          className="btn btn-secondary"
                          type="button"
                          id="billingAddNew"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          variant="contained"
                          startIcon={<AddIcon />}
                        >
                          New
                        </Button>
                      }
                      menuItems={[
                        {
                          label: 'New Invoice',
                          value: DEFAULT,
                        },
                        {
                          label: 'Recurring Charge',
                          value: RECURRING,
                        },
                      ]}
                      handleMenuItemClick={(input) => {
                        handleModalOpen(input.value);
                      }}
                    />
                  </div>
                )}
                <BillingFilter
                  options={
                    activeTab === 0
                      ? filterOptions.oneTime
                      : filterOptions.recurring
                  }
                  selected={
                    activeTab === 0
                      ? filtersSelected.oneTime
                      : filtersSelected.recurring
                  }
                  contactId={contactId}
                  onSelectFilter={onSelectFilter}
                  onClearFilter={onClearFilter}
                  activeFiltersCount={activeFiltersCount[activeTab]}
                  textSearch={textSearch.text[activeTab]}
                  clearSearch={textSearch.cleared[activeTab]}
                  onTextSearch={onTextSearch}
                  activeTab={activeTab}
                  filterBGClicked={filterBGClicked}
                  isContactView={isContactView}
                />
                <TabPanel value={activeTab} index={0}>
                  <Invoices
                    allInvoices={allInvoices}
                    handleColumnSorting={handleColumnSorting}
                    sortOrder={sortOrder}
                    sortBy={sortBy}
                  />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <Recurring
                    allInvoices={allInvoices}
                    handleColumnSorting={handleColumnSorting}
                    sortOrder={sortOrder}
                    sortBy={sortBy}
                  />
                </TabPanel>
                {isOpen && (
                  <InvoiceModal
                    isOpen={isOpen}
                    handleModalClose={handleModalClose}
                    getAllInvoices={getAllInvoices}
                    mode={modalMode}
                    initialData={{
                      /* To autofill account field and contact field in the form
                       when user clicks on new in account or contact billing tab */
                      account: {
                        value: accountId,
                        label: accountName,
                        qb_customer_id: qbCustomerId,
                      },
                      contact: {
                        value: contactId,
                        label: contactName,
                      },
                    }}
                  />
                )}
                {allInvoices && allInvoices.length > 0 && (
                  <Pagination
                    currentPage={allInvoices.length >= 1 ? message_page : 0}
                    totalPages={totalpages}
                    totalRecords={totalrecords}
                    offset={totalrecords === 0 ? offset - 1 : offset}
                    off={off}
                    handlePageChange={handlePageChange}
                  />
                )}
              </>
            )}
          {cpaInvoiceSettings &&
            cpaInvoiceSettings.stripe_account_status !== 'active' && (
              <div
                className="alert alert-danger alert-dismissible"
                role="alert"
              >
                <p className="p-0 m-0">
                  {/* TODO: Uncomment for external billing phase 2  */}
                  {/* {messageObj[cpaInvoiceSettings.stripe_account_status]}.
                  <Link to="/integrations">Go to Integrations</Link> */}
                  <div>
                    Upgrade in progress. We will inform you once it’s available!
                  </div>
                </p>
              </div>
            )}
        </StyledDiv>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  message_page: state.paginationReducer.message_page,
});

const mapDispatch = (dispatch) => ({
  pagination: bindActionCreators(paginationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(Billing);
